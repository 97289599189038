import { Typography } from '@mui/material'
import { useTourTitleQuery } from '@typings/graphql'
import React from 'react'
import { useParams } from 'react-router'

export const PaymentTourBreadcrumb: React.FC = () => {
  const { id: tourId } = useParams()

  const { data } = useTourTitleQuery({ variables: { id: tourId as string } })

  return <Typography>
    {data?.tour.title}
  </Typography>
}
