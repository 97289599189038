import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Box, Button, Card, Dialog, DialogActions, DialogContent } from '@mui/material'
import { useImportTourMutation, useToursQuery } from '@typings/graphql'
import { TourCard } from '@shared/components/ui/TourCard'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useNavigate } from 'react-router'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'

const TourList: React.FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const { data, refetch } = useToursQuery()
  const tours = data?.tours

  const [importTour, { loading }] = useImportTourMutation()
  const [tourItemOpenState, setTourItemOpenState] = useState<Record<string, boolean>>({})
  const [showImportDialog, setShowImportDialog] = useState(false)
  const [importFile, setImportFile] = useState<File | null>(null)

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    tours?.forEach((tour) => { setTourItemOpenState({ ...tourItemOpenState, [tour.id]: false }) })
  }, [tours])

  const performImport = useCallback(async () => {
    if (!importFile) return

    await importTour({ variables: { file: importFile } })
  }, [importFile])

  const tourCards = useMemo(() => (tours || []).map((tour) => (
    <TourCard key={tour.id}
      tour={{
        keyvisual: tour.keyvisual?.url,
        title: tour.title,
        subtitle: tour.subtitle,
        rating: tour.rating
      }}
      onTourSelect={() => navigate(`/tour/${tour.id}`)}
    />
  )), [tours])

  const createTourCard = useMemo(() => (
    <Card
      key='create-tour'
      sx={{
        position: 'relative',
        height: '430px',
        aspectRatio: '2/3',
        cursor: 'pointer',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={() => navigate('/tour/create')}
    >
      <AddCircleOutlineIcon></AddCircleOutlineIcon>
    </Card>
  ), [])

  const importTourCard = useMemo(() => (
    <Card
      key='import-tour'
      sx={{
        position: 'relative',
        height: '430px',
        aspectRatio: '2/3',
        cursor: 'pointer',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={() => setShowImportDialog(true)}
    >
      <UploadFileIcon />
    </Card>
  ), [])

  return (
    <Box
      height="100%"
      padding={6}
      display="flex"
      flexWrap="wrap"
      gap="20px"
      sx={{ overflowY: 'auto' }}
    >
      {tourCards.concat(createTourCard, importTourCard)}

      <Dialog open={showImportDialog} PaperProps={{ sx: { maxWidth: '800px', width: '60vw' } }}>
        <DialogContent sx={{ width: '100%' }}>
          <Box>
            <Box width="100%" mt={2}>
              <FileDragDrop
                accept={{ 'application/zip': ['.zip'] }}
                onFileChanged={setImportFile}
                maxFileSize={2000000000}
              />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 4, pt: 0 }}>
          <Button
            variant="outlined"
            type="button"
            disabled={loading}
            onClick={() => setShowImportDialog(false)}
          >
            {t('common.cancel')}
          </Button>

          <LoadingButton
            variant="contained"
            type="button"
            disabled={!importFile || loading}
            loading={loading}
            onClick={performImport}
          >
            {t('common.save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TourList
