import { Box, Button, Chip, IconButton, Stack, TextField } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { UpdateContentInput } from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { deleteTexts } from '../mutation-helper/text'
import { updateContent } from '../mutation-helper/content'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { pollConfigSchema } from '../config-schemas/default/poll'

import { ContentEditorComponentProps } from '.'

type UpdatePollContentFormInput = {
  texts: {
    key: string,
    value: string,
  }[],
  blockedById: string | null,
  style: string,
  config: string
}

export const PollContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [deletedTextKeys, setDeletedTextKeys] = useState<string[]>([])

  const texts = useMemo(() => {
    return Object.keys(content.texts).length
      ? Object.keys(content.texts).map((key) => ({
        key,
        value: content.texts[key]
      }))
      : [{
          key: '',
          value: ''
        }]
  }, [content.texts])

  const getDefaultValues = () => {
    return {
      texts,
      blockedById: content.blockedById || null,
      config: JSON.stringify(content.config, null, '\t'),
      style: JSON.stringify(content.style, null, '\t') || ''
    }
  }

  const methods = useForm<UpdatePollContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields: textFields, append: appendText, remove: removeText } = useFieldArray({
    control: methods.control,
    name: 'texts'
  })

  const deleteText = (index: number, key: string) => {
    removeText(index)
    setDeletedTextKeys((prev) => [...prev, key])
  }

  const textInputs = useMemo(() => {
    return textFields.map((textField, index) => (<Box key={index}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        <TextField
          disabled={content.texts[textField.key] !== undefined}
          sx={{ flex: 1 }}
          label={t('common.key')}
          {...methods.register(`texts.${index}.key`)}
        />
        <TextField
          sx={{ flex: 1 }}
          label={t('common.text')}
          {...methods.register(`texts.${index}.value`)}
        />
        <IconButton sx={{ minWidth: 'none' }} onClick={() => deleteText(index, textField.key)}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Box>
    </Box>))
  }, [textFields])

  const onSubmit = async (submittedData: UpdatePollContentFormInput) => {
    setLoading(true)

    const emptyTextsKeys = submittedData.texts.filter(text => !text.value).map((text) => text.key)

    if (deletedTextKeys.length || emptyTextsKeys.length) {
      const deletedTextsVariables = deletedTextKeys.concat(emptyTextsKeys).map((key) => ({
        key,
        model: 'Content',
        modelId: content.id
      }))

      try {
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      blockedById: submittedData.blockedById || null,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style),
      texts: submittedData.texts.map(text => convertToTextObject(text.key, text.value)).filter(text => !!text.value)
    }

    try {
      await updateContent(content.id, data)
      methods.reset()
    } catch (e) {
      setError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch && refetch()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setError(undefined)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box>
          <Chip
            label={t('edit.content.answerOptions') as string}
            size="small"
            sx={{ marginBottom: '12px' }}
          />
          <Stack spacing={2}>
            {textInputs}
            <Box width="600xpx">
              <Button
                variant="outlined"
                onClick={() => appendText({ key: '', value: '' })}
              >
                <AddCircleOutlineIcon></AddCircleOutlineIcon>
              </Button>
            </Box>
          </Stack>
        </Box>

        <ConfigAndStyleEditor content={content} schema={pollConfigSchema} />
        <ContentBlockEditor content={content} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty && methods.formState.isValid}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
