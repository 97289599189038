import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CustomerGroupsTourList } from '../components/CustomerGroupsTourList'

export const PaymentHistoryTourListView: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      p: 6,
      height: '100%',
      overflow: 'auto'
    }}
    >
      <Box>
        <Typography variant="h4">{t('payment.paymentHistory')}</Typography>
        <Typography >{t('payment.allowedTours')}</Typography>
      </Box>
      <Box mt={4}>
        <CustomerGroupsTourList></CustomerGroupsTourList>
      </Box>
    </Box>
  )
}
