import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { VoucherProvider } from '../provider/VoucherProvider'
import { VoucherTourList } from '../components/tourList/VoucherTourList'
import { VoucherHistoryList } from '../components/voucherList/VoucherHistoryList'

const ViewContent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}
    >
      <Box sx={{ flex: 0 }}>
        <Typography variant="h4">{t('voucher.voucherCreation')}</Typography>
      </Box>

      <Box sx={{
        flex: 0,
        mt: 2
      }}
      >
        <Box sx={{ width: '300px' }}>
          <Typography variant="h5">
            {t('voucher.yourTours')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        mt: 2,
        overflow: 'hidden'
      }}
      >
        <Box sx={{
          width: '300px',
          height: '100%'
        }}
        >
          <VoucherTourList />
        </Box>
        <Box sx={{
          flex: 1,
          height: '100%',
          pl: 4
        }}
        >
          <VoucherHistoryList />
        </Box>
      </Box>
    </Box>
  )
}

export const VoucherGenerationView: React.FC = () => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    p: 6,
    height: '100%',
    overflow: 'auto'
  }}
  >
    <VoucherProvider>
      <ViewContent />
    </VoucherProvider>
  </Box>
)
