import { Box, CircularProgress, Typography } from '@mui/material'
import { TourCard } from '@shared/components/ui/TourCard'
import { useCustomerGroupsQuery } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

export const CustomerGroupsTourList: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: customerData, loading } = useCustomerGroupsQuery({
    fetchPolicy: 'network-only'
  })

  if (loading) {
    return <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }}
    >
      <CircularProgress />
    </Box>
  }

  return (
    <Box>
      {!customerData
        ? <Typography>{t('errors.hasNoGroup')}</Typography>
        : customerData.customerGroups.map((customerGroup) => (
          <Box mb={4} key={customerGroup.id}>
            <Typography
              variant="h5"
              sx={{ mb: 2 }}
            >{customerGroup.name}</Typography>
            {customerGroup.tours.length === 0
              ? <Typography>{t('errors.noToursFound')}</Typography>
              : <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {customerGroup.tours.map((tour, index) => (
                  <Box
                    key={tour.id}
                    sx={{ mr: index === customerGroup.tours.length - 1 ? 0 : 2 }}
                  >
                    <TourCard
                      tour={{
                        keyvisual: tour.keyvisual?.url,
                        title: tour.title,
                        subtitle: tour.subtitle,
                        rating: tour.rating
                      }}
                      onTourSelect={() => navigate(`${tour.id}`)}
                    />
                  </Box>
                ))}
              </Box>
            }
          </Box>
        ))}
    </Box>
  )
}
