import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Chip, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UpdateContentInput } from '@typings/graphql'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { syncContentTexts } from '../mutation-helper/text'
import { updateContent } from '../mutation-helper/content'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { EditableFile } from '../components/InteractionCardEditor'
import { ContentFile, uploadFile } from '../mutation-helper/file'
import FileDragDrop from '../../FileDragDrop'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { quickTipConfigSchema } from '../config-schemas/default/quickTip'

import { ContentEditorComponentProps } from '.'

type UpdateQuickTipContentFormInput = {
  title: string,
  text: string,
  iconName: string,
  iconBackgroundColor: string,
  voiceover: EditableFile,
  config: string,
  style: string,
  blockedById: string | null,
};

const textKeys = ['title', 'text'] as const

export const QuickTipContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [error, setError] = useState<any>(null)

  const getDefaultValues = () => ({
    title: content.texts.title,
    text: content.texts.text,
    voiceover: {
      key: 'voiceover',
      file: content.files.find((file: ContentFile) => file.key === 'voiceover') || null,
      replaceId: null
    },
    config: JSON.stringify(content.config, null, '\t') || '',
    style: JSON.stringify(content.style, null, '\t') || ''
  })

  const methods = useForm<UpdateQuickTipContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const voiceover = useWatch({
    control: methods.control,
    name: 'voiceover'
  })

  const onSubmit = methods.handleSubmit(async (submittedData) => {
    console.log('on submit')
    setLoading(true)

    const translatableTexts = textKeys.reduce((acc, key) => {
      if (submittedData[key]) {
        acc[key] = submittedData[key]
      }

      return acc
    }, {} as Record<string, string>)

    const texts = await syncContentTexts(content, translatableTexts)

    if (voiceover.file && !(voiceover.file as any).id) {
      try {
        await uploadFile({
          file: voiceover.file as File,
          data: {
            key: 'voiceover',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setError(e)
      }
    }

    if (methods.formState.isDirty) {
      setLoading(true)

      const data: UpdateContentInput = {
        nuggetId,
        texts,
        order: content.order,
        type: content.type,
        config: JSON.parse(submittedData.config),
        style: JSON.parse(submittedData.style),
        blockedById: submittedData.blockedById || null
      }

      try {
        await updateContent(content.id, data)
      } catch (e) {
        setError(e)
      }
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch?.()
  })

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const addVoiceover = (file: File, key: 'voiceover', oldFile?: ContentFile) => {
    methods.setValue(`${key}.file`, file, { shouldDirty: true })

    if (oldFile) methods.setValue(`${key}.replaceId`, oldFile.id)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={onSubmit}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box pb={6}>
          <Chip label={t('common.title')} size="small" sx={{ marginBottom: '8px' }} />
          <MarkdownEditor name="title" />
        </Box>
        <Box pb={6}>
          <Chip label={t('common.text')} size="small" sx={{ marginBottom: '8px' }} />
          <MarkdownEditor name="text" />
        </Box>
        <Box mt={4}>
          <Chip label="Voiceover" size="small" sx={{ marginBottom: '8px' }} />
          <FileDragDrop
            accept={{ 'audio/*': ['.mp3', '.mp4', '.acc'] }}
            preview
            onFileChanged={(file) => addVoiceover(file, 'voiceover', voiceover.file as ContentFile)}
          >
            <Typography>{
              !voiceover.file
                ? t('edit.poi.dragAudio')
                : (voiceover.file as ContentFile).fileName}</Typography>
          </FileDragDrop>
        </Box>
        <ConfigAndStyleEditor content={content} schema={quickTipConfigSchema} />
        <ContentBlockEditor content={content} />
      </Stack>

      <FormFooterBar
        disabled={!methods.formState.isDirty || !methods.formState.isValid}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
