import {
  useTourPoiQuery,
  useUpdateContentSectionMutation,
  useUploadFileMutation,
  useCreateContentSectionMutation,
  useDeleteContentSectionMutation
} from '@typings/graphql'
import React, { useEffect, useState } from 'react'
import { useForm, FormProvider, useWatch, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { Box, TextField, Typography, Badge, InputAdornment, Button, FormControlLabel, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { convertToTextObject } from '@utils/texts'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import DeleteIcon from '@mui/icons-material/Delete'
import { ConfirmDialog } from '@features/cms/components/dialogs/ConfirmDialog'
import { FileList } from '@features/cms/components/FileList'
import { ContentFile, isGraphQlFile } from '@features/cms/components/content-type-editors/mutation-helper/file'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

type UpdateSectionFormInput = {
  title: string,
  bgColor: string,
  cardColor: string,
  keyvisual: File | ContentFile | null | undefined,
  inDemo: boolean
}

const PoiEditSection: React.FC = () => {
  const { id, modeId, poiId, sectionId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const [createSection, { loading: isCreating, error: errorInCreate }] = useCreateContentSectionMutation()
  const [updateSection, { loading: isUpdating, error: errorInUpdate }] = useUpdateContentSectionMutation()
  const [uploadKeyvisual, { loading: isUploading, error: uploadError }] = useUploadFileMutation()
  const [deleteSection] = useDeleteContentSectionMutation()

  const { data, refetch } = useTourPoiQuery({ variables: { id: poiId as string } })
  const poi = data?.tourPoi

  const section = poi?.sections.find((s) => s.id === sectionId)

  const methods = useForm<UpdateSectionFormInput>({
    defaultValues: {
      title: section?.title ?? t('edit.poi.newSection?'),
      bgColor: section?.config.bgColor ?? '#000',
      cardColor: section?.config.cardColor ?? '#fff',
      keyvisual: section?.files.find((f) => f.key === 'keyvisual'),
      inDemo: !!section?.inDemo
    }
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  const bgColor = useWatch({
    control: methods.control,
    name: 'bgColor'
  })

  const cardColor = useWatch({
    control: methods.control,
    name: 'cardColor'
  })

  useEffect(() => {
    if (!section) return

    methods.reset({
      title: section.title,
      bgColor: section.config.bgColor,
      cardColor: section.config.cardColor,
      keyvisual: section.files.find((f) => f.key === 'keyvisual'),
      inDemo: section.inDemo
    })
  }, [section])

  const onSubmit = async (submittedData: UpdateSectionFormInput) => {
    const translatableTexts = [convertToTextObject('title', submittedData.title)]

    let modelId = sectionId

    if (section) {
      try {
        await updateSection({
          variables: {
            id: section.id,
            data: {
              texts: translatableTexts,
              poiId: poi!.id,
              order: section.order,
              demo: submittedData.inDemo,
              config: {
                cardColor: submittedData.cardColor,
                bgColor: submittedData.bgColor
              }
            }
          }
        })
      } catch (e) {}
    } else {
      try {
        const result = await createSection({
          variables: {
            data: {
              texts: translatableTexts,
              poiId: poi!.id,
              order: poi!.sections.length,
              demo: submittedData.inDemo,
              config: {
                cardColor: submittedData.cardColor,
                bgColor: submittedData.bgColor
              }
            }
          }
        })

        modelId = result.data?.createContentSection.id
        navigate(`/tour/${id}/mode/${modeId}/poi/${poi!.id}/section/${modelId}`)
      } catch (e) {}
    }

    if (submittedData.keyvisual && !isGraphQlFile(submittedData.keyvisual) && modelId) {
      try {
        await uploadKeyvisual({
          variables: {
            file: submittedData.keyvisual,
            data: {
              key: 'keyvisual',
              replace: true,
              model: 'ContentSection',
              modelId
            }
          }
        })
      } catch (e) {}
    }

    setShowSnackbar(true)
  }

  const onConfirmDelete = async () => {
    try {
      await deleteSection({ variables: { id: sectionId! } })
      navigate(`/tour/${id}/mode/${modeId}/poi/${poi!.id}/sections`)
    } catch (e) {
      console.log(e)
    }
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
    methods.reset()
  }

  return <FormProvider {...methods} >
    <form style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Box
        flex={1}
        p={4}
        sx={{
          overflowY: 'auto'
        }}
      >

        <Box display="flex">
          <Box display="flex" flexDirection="column" flex="0 0 50%" paddingRight="32px" gap={4}>
            <TextField
              label={t('common.title')}
              id="title"
              {...methods.register('title')}
            />
            <TextField
              label={t('common.bgColor')}
              {...methods.register('bgColor')}
              id="bgColor"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Badge sx={{ backgroundColor: bgColor, width: '20px', height: '20px' }}></Badge>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label={t('common.cardColor')}
              {...methods.register('cardColor')}
              id="cardColor"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Badge sx={{ backgroundColor: cardColor, width: '20px', height: '20px' }}></Badge>
                  </InputAdornment>
                )
              }}
            />
            <Controller
              name="inDemo"
              control={methods.control}
              render={({ field: { onChange, value } }) => <FormControlLabel
                control={<Checkbox sx={{ py: 0 }} checked={value} onChange={e => onChange(e.currentTarget.checked)} />}
                label={t('common.demo')}
              />}
            />
          </Box>
          <Box flex="0 0 50%">
            <Controller
              name="keyvisual"
              control={methods.control}
              render={({ field: { onChange, value } }) => <FileDragDrop
                accept={{
                  'image/*': ['.png', '.jpg', '.jpeg', '.webp']
                }}
                preview
                initialFile={value}
                onFileChanged={onChange}
              >
                {!section?.keyvisual &&
                <Typography>{t('edit.poi.dragImage')}</Typography>
                }
              </FileDragDrop>}
            />
            {sectionId && <Box mt={4}>
              <FileList
                files={section?.files as any ?? []}
                model="ContentSection"
                modelId={sectionId}
                refetch={refetch}
              />
            </Box>}
          </Box>
        </Box>
      </Box>

      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={isUpdating || isCreating || isUploading}
        uploadError={errorInCreate || errorInUpdate || uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      >
        {section &&
          <Button
            color="warning"
            variant="outlined"
            endIcon={<DeleteIcon />}
            onClick={() => setShowConfirmDialog(true)}
          >
            {t('edit.poi.deleteSection')}
          </Button>}
      </FormFooterBar>
      <ConfirmDialog
        open={showConfirmDialog}
        text={t('edit.poi.confirmDeleteSection')}
        onConfirm={onConfirmDelete}
        onCancel={() => setShowConfirmDialog(false)}
      />

    </form>
  </FormProvider>
}

export default PoiEditSection
