export const lgsKidsModeConfigSchema = {
  type: 'object',
  properties: {
    backgroundType: {
      type: 'string',
      enum: ['default', 'video', 'image']
    },
    sectionOrderMarkerType: {
      type: 'string',
      enum: ['default', 'custom']
    },
    mapOverlayBoundingBox: {
      type: 'array',
      items: {
        type: 'number'
      }
    },
    mapStyleUrl: {
      type: 'string'
    },
    hidePoiCardNumbering: {
      type: 'boolean'
    },
    mapStatusBarMode: {
      type: 'string',
      enum: ['light', 'dark']
    },
    invertSelectedPoiColors: {
      type: 'boolean'
    }
  }
}
