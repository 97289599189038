import React, { useEffect, useMemo } from 'react'
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { useTourPoiQuery } from '@typings/graphql'
import SectionCard from '@features/cms/components/ui/SectionCard'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'

const PoiSections: React.FC = () => {
  const { t } = useTranslation()
  const { id, modeId, poiId } = useParams()
  const { data, refetch } = useTourPoiQuery({ variables: { id: poiId as string } })
  const poi = data?.tourPoi

  const navigate = useNavigate()

  useEffect(() => {
    refetch()
  }, [])

  const sectionCards = useMemo(() => (poi?.sections || []).map((section) => (
    <SectionCard key={`section-${section.id}`} poiId={poi!.id} section={section} />
  )), [poi])

  return (
    <Box
      flex={1}
      display="flex"
      flexWrap="wrap"
      alignItems="flex-start"
      p={4}
      sx={{
        overflowY: 'auto'
      }}
    >
      {sectionCards}
      <Card
        sx={{
          width: '250px',
          aspectRatio: '2/3',
          cursor: 'pointer',
          marginRight: '32px',
          marginBottom: '32px',
          padding: '12px',
          borderRadius: '15px'
        }}
        onClick={() => navigate(`/tour/${id}/mode/${modeId}/poi/${poi!.id}/section/create`)}
      >
        <CardContent sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%'
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                backgroundColor: 'gray',
                color: '#fff',
                marginBottom: '8px',
                fontSize: '12px'
              }}
            >
              {sectionCards.length + 1}
            </Avatar>
            <Typography
              textAlign="center"
              fontWeight="600"
              variant="h6"
              marginBottom="32px"
              sx={{ color: 'gray' }}
            >
              {t('edit.poi.addSection')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '150px',
              height: '150px',
              borderRadius: '30px',
              border: '3px solid gray'
            }}
          >
            <AddIcon sx={{ fontSize: '80px', color: 'gray' }} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default PoiSections
