import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import {
  Box,
  Chip,
  TextField
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentFile, isGraphQlFile, uploadFile } from '../mutation-helper/file'
import FileDragDrop from '../../FileDragDrop'

import { VoiceoverMarkerSelection } from './components/VoiceoverMarkerSelection'

import type { ContentEditorComponentProps } from '.'

type UpdateDownloadContentFormInput = {
  texts: {
    title?: string
    text: string
  },
  config: Record<string, any>,
  file: File | ContentFile | null | undefined
}

export const DownloadContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const defaultValues = useMemo<UpdateDownloadContentFormInput>(() => {
    return {
      texts: content.texts,
      config: content.config ?? {},
      file: content.files[0]
    }
  }, [content])

  const methods = useForm<UpdateDownloadContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const canSave = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = async (submittedData: UpdateDownloadContentFormInput) => {
    setLoading(true)

    const texts = await syncContentTexts(content, submittedData.texts)

    if (submittedData.file && !isGraphQlFile(submittedData.file)) {
      try {
        await uploadFile({
          file: submittedData.file,
          data: {
            key: 'file',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    try {
      await updateContent(content.id, {
        nuggetId,
        texts,
        config: submittedData.config,
        order: content.order
      })
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <Box display="flex" flexDirection="row" alignItems="flex-end" gap={2}>
            <Box flex={1}>
              <Box mb={2}>
                <Chip label={t('common.title')} size="small" />
              </Box>
              <Box>
                <TextField {...methods.register('texts.title')}
                  sx={{ width: '100%' }}
                  label={t('common.title')}
                  name="texts.title"
                  placeholder={t('edit.content.infoPopupTitlePlaceholder')}
                />
              </Box>
            </Box>
            <Box width="300px" mt={1.25}>
              <VoiceoverMarkerSelection name="config.voiceoverMarker.text" />
            </Box>
          </Box>

          <Box>
            <Chip label={t('common.text')} size="small" sx={{ marginBottom: '8px' }} />
            <MarkdownEditor name='texts.text' />
          </Box>

          <Box>
            <Chip label={t('common.file')} size="small" sx={{ marginBottom: '8px' }} />
            <Controller name="file"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FileDragDrop accept={{ 'application/*': ['.pdf'] }}
                  height="auto"
                  minHeight="400px"
                  preview
                  onFileChanged={onChange}
                  initialFile={value}
                />
              )}
            />
          </Box>
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading}
          uploadError={uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
