import React from 'react'
import { timestampToDate } from '@utils/date'
import { GridCellParams, GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro'
import { t as trans } from 'i18next'
import { deDE } from '@mui/x-date-pickers/locales'
import { FilterOperators } from '@typings/graphql'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { FormControl, InputLabel } from '@mui/material'
import { DateRange } from '@mui/lab'
import de from 'date-fns/locale/de'

import { PaginatedBookingColumnType } from '../PaymentHistoryDataGrid'

const RenderedFilter:React.FC<GridFilterInputValueProps> = (props) => {
  const { item, applyValue } = props
  const { t } = useTranslation()

  const handleFilterChange = (value: DateRange<any>) => {
    applyValue({ ...item, value })
  }

  return (
    <FormControl variant='standard'
      sx={{
        height: '100%'
      }}
    >
      <InputLabel sx={{
        top: 0,
        transform: 'translate(0, 0) scale(0.75)'
      }}
      >{t('payment.paymentMethod')}</InputLabel>
      <LocalizationProvider
        localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
        adapterLocale={de}
        dateAdapter={AdapterDateFns}
      >
        <DateRangePicker
          sx={{
            height: '100%',
            marginTop: '16px',
            '.MuiInputBase-root': {
              height: '100%',
              input: {
                height: '100%',
                padding: '4px 0 5px'
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
                borderBottom: '1px solid',
                borderColor: 'grey.500',
                borderRadius: 0
              },
              '&:hover': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                  borderBottom: '2px solid'
                }
              }
            }
          }}
          onChange={(value) => handleFilterChange(value)}
          slotProps={{
            actionBar: {
              actions: ['clear', 'accept']
            }
          }}
          slots={{ field: SingleInputDateRangeField }}
        />
      </LocalizationProvider>
    </FormControl>
  )
}

const getApplyFilterFn = (filterItem: GridFilterItem) => {
  if (!filterItem.field || !filterItem.value || !filterItem.operator) {
    return null
  }

  return (params: GridCellParams) => {
    return params.value === filterItem.value
  }
}

const createdAtFilterModel: GridFilterOperator[] = [{
  label: trans('filterOperator.between'),
  value: FilterOperators.Range as string,
  getApplyFilterFn,
  InputComponent: RenderedFilter
}]

export const createdAtColumnModel: PaginatedBookingColumnType = {
  field: 'createdAt',
  width: 140,
  type: 'date',
  renderCell: (params) => {
    return params.value ? timestampToDate(params.value) : ''
  },
  filterOperators: createdAtFilterModel
}
