import { PaymentStatus } from '@typings/graphql'

export enum ColumnStatusCouponStatus {
  Active = 'ACTIVE',
  Used = 'USED'
}

export const PAYMENT_STATUS_COLORS: Record<PaymentStatus | ColumnStatusCouponStatus, string> = {
  [PaymentStatus.Success]: 'success',
  [PaymentStatus.Failed]: 'error',
  [PaymentStatus.Pending]: 'warning',
  [ColumnStatusCouponStatus.Active]: 'warning',
  [ColumnStatusCouponStatus.Used]: 'success'
}
