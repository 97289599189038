export const audioPlayerConfigSchema = {
  type: 'object',
  properties: {
    variant: {
      type: 'string',
      enum: ['controls', 'button'],
      description: 'Default: "button"'
    }
  }
}
