import React from 'react'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { Box, Tooltip } from '@mui/material'

import { PaginatedBookingColumnType } from '../PaymentHistoryDataGrid'

export const chargeIdColumnModel: PaginatedBookingColumnType = {
  field: 'chargeId',
  flex: 1,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals'),
  renderCell: (params) => {
    if (!params.row.chargeId) {
      return ''
    }

    let id = params.row.chargeId

    try {
      id = JSON.parse(params.row.chargeId)?.orderId
    } catch (e) {
      // do nothing
    }

    return <Tooltip title={params.row.chargeId}>
      <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{id}</Box>
    </Tooltip>
  }
}
