import { MuiIconNames } from '@features/cms/typings/material-icon-font'

export const linkConfigSchema = {
  type: 'object',
  properties: {
    icon: { type: 'string', enum: MuiIconNames, description: 'Material Icon Name' },
    url: { type: 'string', description: 'URL' },
    buttonProps: { type: 'object' }
  }
}
