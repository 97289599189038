import { PaginatedBookingColumnType } from './PaymentHistoryDataGrid'
import { amountColumnModel } from './columns/AmountColumn'
import { chargeIdColumnModel } from './columns/ChargeIdColumn'
import { codeColumnModel } from './columns/CodeColumn'
import { createdAtColumnModel } from './columns/CreatedAtColumn'
import { paymentMethodColumnModel } from './columns/PaymentMethodColumn'
import { statusColumnModel } from './columns/StatusColumn'
import { usagesColumnModel } from './columns/UsagesColumn'

export const dataGridColumns: PaginatedBookingColumnType[] = [
  codeColumnModel,
  createdAtColumnModel,
  chargeIdColumnModel,
  paymentMethodColumnModel,
  statusColumnModel,
  usagesColumnModel,
  amountColumnModel
]
