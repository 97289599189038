export const miniGameFrogDodgeConfigSchema = {
  type: 'object',
  properties: {
    frogLoop: {
      type: 'array',
      items: [
        {
          type: 'number'
        },
        {
          type: 'number'
        }
      ]
    },
    frogTargetFrame: {
      type: 'number'
    },
    animationEndFrame: {
      type: 'number'
    },
    frogAnimationEndFrame: {
      type: 'number'
    },
    hintFrame: {
      type: 'number'
    },
    stopFrame: {
      type: 'number'
    },
    incrementBy: {
      type: 'number'
    },
    startSpeed: {
      type: 'number'
    },
    voiceoverMarker: {
      type: 'object'
    }
  },
  required: ['frogLoop', 'frogTargetFrame', 'animationEndFrame', 'frogAnimationEndFrame', 'hintFrame', 'stopFrame', 'incrementBy', 'startSpeed']
}
