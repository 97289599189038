import React from 'react'
import LoginView from '@features/auth/views/LoginView'
import ProtectedRoute from '@shared/components/access/ProtectedRoute'
import TourList from '@features/cms/views/tour/TourList'
import TourEdit from '@features/cms/views/tour/TourEdit'
import ModeEdit from '@features/cms/views/mode/ModeEdit'
import PoiEdit from '@features/cms/views/poi/PoiEdit'
import PoiSections from '@features/cms/views/poi/PoiSections'
import PoiEditSection from '@features/cms/views/poi/PoiEditSection'
import PoiNuggets from '@features/cms/views/poi/PoiNuggets'
import PoiEditNugget from '@features/cms/views/poi/PoiEditNugget'
import PoiNuggetContent from '@features/cms/views/poi/PoiNuggetContent'
import TourView from '@features/cms/views/tour/TourView'
import TourModes from '@features/cms/views/tour/TourModes'
import ModePois from '@features/cms/views/mode/ModePois'
import ModeView from '@features/cms/views/mode/ModeView'
import PoiView from '@features/cms/views/poi/PoiView'
import { Outlet, RouteObject } from 'react-router'
import {
  PoiBreadcrumb,
  SectionBreadcrumb,
  NuggetBreadcrumb,
  ModeBreadcrumb,
  TourBreadcrumb
} from '@shared/components/ui/Breadcrumbs'
import { t } from 'i18next'
import { HomeView } from '@features/home/view/HomeView'
import { DashBoardLayout } from '@shared/components/layouts/DashboardLayout'
import { themeIcons } from '@theme/index'
import { PaymentHistoryTourListView } from '@features/payment/views/PaymentHistoryTourListView'
import { PaymentHistoryTourView } from '@features/payment/views/PaymentHistoryTourView'
import { PaymentBreadcrumb } from '@features/payment/breadcrumbs/PaymentBreadcrumb'
import { PaymentTourBreadcrumb } from '@features/payment/breadcrumbs/PaymentTourBreadcrumb'
import { VoucherGenerationView } from '@features/voucher/views/VoucherGenerationView'

type RouteMetaData = {
  dashboard?: boolean
  name?: string
  icon?: keyof typeof themeIcons
  [key: string]: any
}

export type AppRouteObject = RouteObject & {
  children?: AppRouteObject[]
  meta?: RouteMetaData
  permission?: string
}

export const routes: AppRouteObject[] = [
  // Home
  {
    path: '/',
    element: <ProtectedRoute component={<DashBoardLayout />} />,
    children: [{
      index: true,
      element: <HomeView />
    }]
  },

  // Tours Dashboard
  {
    path: '/tours',
    element: <ProtectedRoute component={<DashBoardLayout />} />,
    meta: {
      dashboard: true,
      order: 1,
      name: 'cms',
      icon: 'Edit'
    },
    permission: 'feature:cms',
    children: [
      {
        index: true,
        element: <TourList />
      }
    ]
  },

  // Payment
  {
    path: '/payment',
    element: <ProtectedRoute component={<DashBoardLayout />} />,
    meta: {
      dashboard: true,
      order: 2,
      name: 'payment',
      icon: 'Payment'
    },
    handle: {
      breadcrumb: PaymentBreadcrumb
    },
    permission: 'feature:payment',
    children: [{
      index: true,
      element: <PaymentHistoryTourListView />
    }, {
      path: ':id',
      handle: {
        breadcrumb: PaymentTourBreadcrumb
      },
      element: <PaymentHistoryTourView />
    }]
  },

  // Payment
  {
    path: '/voucher',
    element: <ProtectedRoute component={<DashBoardLayout />} />,
    meta: {
      dashboard: true,
      order: 2,
      name: 'voucher',
      icon: 'QrCode2'
    },
    permission: 'feature:coupon',
    children: [{
      index: true,
      element: <VoucherGenerationView />
    }]
  },

  // Tour Edit
  {
    path: '/tour',
    element: <ProtectedRoute component={<TourView />} />,
    handle: {
      breadcrumb: () => t('tours.tour'),
      tabBarKey: 'tour'
    },
    permission: 'feature:cms',
    children: [
      {
        path: 'create',
        element: <TourEdit />,
        handle: {
          breadcrumb: () => t('edit.tour.new'),
          isCreate: true
        }
      },
      {
        path: ':id',
        element: <Outlet />,
        handle: {
          breadcrumb: TourBreadcrumb
        },
        children: [
          {
            index: true,
            element: <TourEdit />
          },
          {
            path: 'modes',
            element: <TourModes />,
            handle: {
              breadcrumb: () => t('common.modes')
            }
          },
          {
            path: 'mode',
            element: <ModeView />,
            handle: {
              tabBarKey: 'mode'
            },
            children: [
              {
                index: true,
                element: <ModeEdit />,
                handle: {
                  breadcrumb: null
                }
              },
              {
                path: 'create',
                element: <ModeEdit />,
                handle: {
                  breadcrumb: ModeBreadcrumb,
                  isCreate: true
                }
              },
              {
                path: ':modeId',
                element: <Outlet />,
                handle: {
                  breadcrumb: ModeBreadcrumb
                },
                children: [
                  {
                    index: true,
                    element: <ModeEdit />
                  },
                  {
                    path: 'pois',
                    element: <ModePois />,
                    handle: {
                      breadcrumb: () => t('common.pois')
                    }
                  },
                  {
                    path: 'poi/:poiId',
                    element: <ProtectedRoute component={<PoiView />} />,
                    handle: {
                      tabBarKey: 'poi',
                      breadcrumb: PoiBreadcrumb
                    },
                    children: [
                      {
                        index: true,
                        element: <PoiEdit />
                      },
                      {
                        path: 'sections',
                        element: <PoiSections />,
                        handle: {
                          breadcrumb: () => t('edit.poi.sections')
                        }
                      },
                      {
                        path: 'section/create',
                        element: <PoiEditSection />,
                        handle: {
                          breadcrumb: SectionBreadcrumb,
                          isCreate: true
                        }
                      },
                      {
                        path: 'section/:sectionId',
                        element: <Outlet />,
                        handle: {
                          breadcrumb: SectionBreadcrumb
                        },
                        children: [{
                          index: true,
                          element: <PoiEditSection />
                        }, {
                          path: 'nuggets',
                          element: <PoiNuggets />,
                          handle: {
                            breadcrumb: () => t('edit.poi.nuggets')
                          }
                        },
                        {
                          path: 'nugget/create',
                          element: <PoiEditNugget />,
                          handle: {
                            breadcrumb: NuggetBreadcrumb,
                            isCreate: true
                          }
                        },
                        {
                          path: 'nugget/:nuggetId',
                          element: <Outlet />,
                          handle: {
                            breadcrumb: NuggetBreadcrumb
                          },
                          children: [{
                            index: true,
                            element: <PoiEditNugget />
                          }, {
                            path: 'contents/:contentId?',
                            element: <PoiNuggetContent />,
                            handle: {
                              breadcrumb: () => t('edit.poi.contents')
                            }
                          }]
                        }]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },

  // Poi Edit
  {
    path: '/login',
    element: <LoginView />
  }]
