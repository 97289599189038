import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import CodeIcon from '@mui/icons-material/Code'
import { FileDataFragmentDoc, useDeleteFileMutation, useUpdateFileMutation, useUploadFileMutation } from '@typings/graphql'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentFile, isGraphQlFile } from '../mutation-helper/file'
import { ConfigEditor } from '../components/ConfigEditor'
import { miniGameBugRaceConfigSchema } from '../config-schemas/lgs-kids/miniGameBugRace'

import { ExerciseTextEditor } from './components/ExerciseTextEditor'
import { VoiceoverMarkerSelection } from './components/VoiceoverMarkerSelection'

import type { ContentEditorComponentProps } from '.'

type UpdateMiniGameBugRaceFormInput = {
  texts: {
    exercise: string
    solution?: string
  }
  config: {
    startSpeed: number,
    incrementInterval: number;
    incrementFactor: number,
    backgroundColor: string,
  },
  playerLottie?: ContentFile | File | null,
  playerLottieConfig?: Record<string, any>,
  backgroundImage?: ContentFile | File | null,
  obstacles: Item[]
}

type Position = 'left' | 'center' | 'right';

type Item = {
  file?: ContentFile | File | null;
  config: {
    positions: Position[],
    stretch: number
  }
}

export const MiniGameBugRaceEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)
  const [uploadFile] = useUploadFileMutation()
  const [updateFile] = useUpdateFileMutation()
  const [deleteFile] = useDeleteFileMutation()

  const [configEditFilePath, setConfigEditFilePath] = useState<string | null>(null)

  const defaultValues = useMemo<UpdateMiniGameBugRaceFormInput>(() => {
    return {
      texts: content.texts,
      config: content.config,
      playerLottie: content.files.find(file => file.key === 'playerLottie'),
      playerLottieConfig: content.files.find(file => file.key === 'playerLottie')?.config,
      backgroundImage: content.files.find(file => file.key === 'backgroundImage'),
      obstacles: content.files.filter(file => file.key === 'obstacle').map(file => {
        return {
          file,
          config: file.config
        }
      })
    }
  }, [content])

  const methods = useForm<UpdateMiniGameBugRaceFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { append, fields, remove } = useFieldArray({
    control: methods.control,
    name: 'obstacles'
  })

  const canSave = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = methods.handleSubmit(async (submittedData) => {
    setLoading(true)

    const texts = await syncContentTexts(content, submittedData.texts)

    for (const key of ['playerLottie', 'backgroundImage'] as const) {
      if (submittedData[key] && !isGraphQlFile(submittedData[key])) {
        await uploadFile({
          variables: {
            file: submittedData[key],
            data: {
              key,
              model: 'Content',
              modelId: content.id,
              replace: true
            }
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(content),
              fields: {
                files (existingRefs = [], { readField }) {
                  if (!data?.uploadFile) {
                    return existingRefs
                  }

                  const newFileRef = cache.writeFragment({
                    data: data.uploadFile,
                    fragment: FileDataFragmentDoc,
                    fragmentName: 'FileData'
                  })

                  const prev = existingRefs.filter((ref: any) => data.uploadFile.id !== readField('id', ref))

                  return [...prev, newFileRef]
                }
              }
            })
          }
        })
      }
    }

    if (methods.formState.dirtyFields.playerLottieConfig) {
      await updateFile({
        variables: {
          id: (submittedData.playerLottie as ContentFile).id,
          data: {
            config: submittedData.playerLottieConfig
          }
        }
      })
    }

    for (const prevFile of content.files.filter(file => file.key === 'obstacle')) {
      if (!submittedData.obstacles.some(item => isGraphQlFile(item.file) && (item.file.id === prevFile.id))) {
        await deleteFile({
          variables: {
            id: prevFile.id
          },
          update: (cache) => {
            cache.modify({
              id: cache.identify(content),
              fields: {
                files (existingFiles = []) {
                  return existingFiles.filter((file: any) => file.__ref !== `File:${prevFile.id}`)
                }
              }
            })
          }
        })
      }
    }

    for (let i = 0; i < submittedData.obstacles.length; i++) {
      const item = submittedData.obstacles[i]

      if (!isGraphQlFile(item.file)) {
        await uploadFile({
          variables: {
            file: item.file,
            data: {
              key: 'obstacle',
              model: 'Content',
              modelId: content.id,
              config: item.config
            }
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(content),
              fields: {
                files (existingFiles = []) {
                  const newFileRef = cache.writeFragment({
                    data: data?.uploadFile,
                    fragment: FileDataFragmentDoc,
                    fragmentName: 'FileData'
                  })
                  return [...existingFiles, newFileRef]
                }
              }
            })
          }
        })
      } else if (methods.formState.dirtyFields.obstacles?.[i]?.config) {
        await updateFile({
          variables: {
            id: item.file.id,
            data: {
              config: item.config
            }
          }
        })
      }
    }

    try {
      await updateContent(content.id, {
        nuggetId,
        texts,
        order: content.order,
        config: submittedData.config
      })
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  })

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  const itemElements = fields.map((item, index) => {
    const positions = ['left', 'center', 'right'] as const

    return (
      <Box key={item.id} display="flex" flexDirection="row">
        <Box>
          <Controller
            name={`obstacles.${index}.file`}
            control={methods.control}
            render={({ field: { value, onChange } }) => (
              <FileDragDrop
                initialFile={isGraphQlFile(value) ? value : undefined}
                onFileChanged={onChange}
                preview
                accept={{ 'image/*': ['.png', '.jpeg', '.jpg', '.webp'] }}
                sx={{ width: 150, height: 100 }}
              />
            )}
          />

          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            {positions.map(position => (
              <Controller
                key={position}
                name={`obstacles.${index}.config.positions`}
                control={methods.control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    value={position}
                    checked={field.value.includes(position)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        field.onChange([...field.value, position])
                      } else {
                        field.onChange(field.value.filter(p => p !== position))
                      }
                    }}
                  />
                )}
              />
            ))}
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="center">
          <IconButton
            onClick={() => setConfigEditFilePath(`obstacles.${index}.config`)}
          >
            <CodeIcon />
          </IconButton>
          <IconButton
            onClick={() => remove(index)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    )
  })

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={onSubmit}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <ExerciseTextEditor />

          <Box display="flex"
            sx={{
              flexDirection: {
                xs: 'column',
                xl: 'row'
              },
              gap: '32px'
            }}
          >
            <Box flex={1}>
              <Chip label={t('common.tryAgain')} size="small" sx={{ marginBottom: '8px' }} />
              <Stack direction="row" spacing={4} alignItems="center">
                <MarkdownEditor style={{ flex: 1 }} name='texts.tryAgain' height={150} />
                <VoiceoverMarkerSelection sx={{ width: 200 }} name='config.voiceoverMarker.tryAgain' />
              </Stack>
            </Box>
            <Box flex={1}>
              <Chip label={t('common.wellDone')} size="small" sx={{ marginBottom: '8px' }} />
              <Stack direction="row" spacing={4} alignItems="center">
                <MarkdownEditor style={{ flex: 1 }} name='texts.wellDone' height={150} />
                <VoiceoverMarkerSelection sx={{ width: 200 }} name='config.voiceoverMarker.wellDone' />
              </Stack>
            </Box>
          </Box>

          <Stack alignItems="center" spacing={4} direction="row">
            <Box>
              <Chip label={t('common.animation')} size="small" sx={{ marginBottom: '8px' }} />
              <Box display="flex" flexDirection="row" gap={1}>
                <Controller
                  name="playerLottie"
                  control={methods.control}
                  render={({ field: { value, onChange } }) => (
                    <FileDragDrop
                      initialFile={isGraphQlFile(value) ? value : undefined}
                      onFileChanged={onChange}
                      preview
                      accept={{ 'application/json': ['.json'] }}
                      sx={{ width: 300, height: 200 }}
                    />
                  )}
                />
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <IconButton
                    onClick={() => setConfigEditFilePath('playerLottieConfig')}
                  >
                    <CodeIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box>
              <Chip label={t('common.pattern')} size="small" sx={{ marginBottom: '8px' }} />
              <Controller
                name="backgroundImage"
                control={methods.control}
                render={({ field: { value, onChange } }) => (
                  <FileDragDrop
                    initialFile={isGraphQlFile(value) ? value : undefined}
                    onFileChanged={onChange}
                    preview
                    accept={{ 'image/*': ['.png', '.jpeg', '.jpg', '.webp'] }}
                    sx={{ width: 300, height: 200 }}
                  />
                )}
              />
            </Box>
            <Box>
              <Chip label={t('common.backgroundColor')} size="small" sx={{ marginBottom: '8px' }} />
              <Box>
                <TextField {...methods.register('config.backgroundColor')} type='color' sx={{ width: '100px' }} />
              </Box>
            </Box>
          </Stack>

          <Box>
            <Chip label={t('edit.content.obstacles')} size="small" sx={{ marginBottom: '8px' }} />

            <Box display="flex" flexDirection="row" gap={4} flexWrap="wrap">
              {itemElements}

              <Button sx={{
                width: '150px',
                height: '100px',
                display: 'flex',
                flexDirection: 'column'
              }}
                variant="outlined"
                onClick={() => append({
                  file: null,
                  config: {
                    positions: [],
                    stretch: 1
                  }
                })}
              >
                <AddIcon sx={{ mb: 1 }} />
                <Typography fontSize="small">{t('edit.content.addFile')}</Typography>
              </Button>
            </Box>
          </Box>

          <Stack direction="row" spacing={4} pt={4}>
            <Box>
              <TextField {...methods.register('config.startSpeed', { valueAsNumber: true, min: 0, required: true })}
                variant="standard"
                type="number"
                inputProps={{
                  step: 1,
                  min: 0
                }}
                sx={{ width: 200 }}
                label={t('edit.content.startSpeed')}
                defaultValue={100}
              />
            </Box>

            <Box>
              <TextField {...methods.register('config.incrementInterval', { valueAsNumber: true, min: 1, max: 60, required: true })}
                variant="standard"
                type="number"
                inputProps={{
                  step: 0.5,
                  min: 1,
                  max: 60
                }}
                sx={{ width: 200 }}
                label={t('edit.content.incrementInterval')}
                defaultValue={10}
              />
            </Box>

            <Box>
              <TextField {...methods.register('config.incrementFactor', { valueAsNumber: true, min: 0, max: 2, required: true })}
                variant="standard"
                type="number"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  max: 2
                }}
                sx={{ width: 200 }}
                label={t('edit.content.incrementFactor')}
                defaultValue={1.05}
              />
            </Box>

            <IconButton
              onClick={() => setConfigEditFilePath('config')}
            >
              <CodeIcon />
            </IconButton>
          </Stack>
        </Stack>

        <Dialog open={!!configEditFilePath} onClose={() => setConfigEditFilePath(null)}>
          <DialogContent>
            <Box sx={{ width: 500, maxWidth: '80vw' }}>
              {configEditFilePath && <ConfigEditor name={configEditFilePath} schema={miniGameBugRaceConfigSchema} />}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfigEditFilePath(null)}>{t('common.close')}</Button>
          </DialogActions>
        </Dialog>

        <FormFooterBar
          disabled={!canSave}
          loading={loading}
          uploadError={uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
