import { CurrencyEnum } from '@typings/currency'

export const getCurrency = (currency?: string): string => {
  if (!currency) {
    return CurrencyEnum.EUR
  }

  const parsedCurrency = currency.toUpperCase()

  return Object.keys(CurrencyEnum).includes(parsedCurrency)
    ? CurrencyEnum[parsedCurrency as keyof typeof CurrencyEnum]
    : CurrencyEnum.EUR
}
