import { Box, Typography } from '@mui/material'
import { useTourTitleQuery } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { PaymentHistoryDataGrid } from '../dataGrid/PaymentHistoryDataGrid'
import { PaymentHistoryDetailsModal } from '../components/PaymentHistoryDetails'

export const PaymentHistoryTourView: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams<{id: string}>()

  const [selectedRow, setSelectedRow] = React.useState<string>('')
  const [showDetailsOverlay, setShowDetailsOverlay] = React.useState<boolean>(false)

  const { data } = useTourTitleQuery({
    variables: {
      id: id as string
    },
    skip: !id
  })

  const handleShowDetails = (code: string) => {
    setSelectedRow(code)
    setShowDetailsOverlay(true)
  }

  return (
    <Box p={6}
      sx={{
        p: 6,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box sx={{
        flex: 0,
        pb: 4,
        display: 'flex',
        minHeight: '150px',
        justifyContent: 'space-between'
      }}
      >
        <Box>
          <Typography variant='h4'>{data?.tour.title}</Typography>
          <Typography>{t('payment.listOfBookings')}</Typography>
        </Box>
        <Box sx={{
          height: '100%',
          aspectRatio: '1 / 1',
          borderRadius: '12px',
          backgroundImage: `url(${data?.tour.keyvisual?.url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          ml: 2
        }}
        />
      </Box>
      <Box sx={{
        flex: 1,
        overflow: 'hidden'
      }}
      >
        <PaymentHistoryDataGrid onShowDetails={handleShowDetails}/>
      </Box>
      <PaymentHistoryDetailsModal
        code={selectedRow}
        open={showDetailsOverlay}
        onClose={() => setShowDetailsOverlay(false)}
      />
    </Box>
  )
}
