import { MuiIconNames } from '@features/cms/typings/material-icon-font'

export const interactionCardConfigSchemaProperties = {
  icon: {
    type: 'string',
    enum: MuiIconNames,
    description: 'Icon des Buttons in der Interaction-Card'
  },
  headingColor: {
    type: 'string',
    description: 'Farbe des Headings in der Interaction-Card'
  }
}
