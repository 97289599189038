import LoadingButton from '@mui/lab/LoadingButton'
import { Badge, Box, Button, Dialog, DialogActions, DialogContent, InputAdornment, Stack, TextField } from '@mui/material'
import { PoiTagDataFragment, useCreateTagMutation, useTagQuery, useUpdateTagMutation } from '@typings/graphql'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  tagId?: string
  open: boolean
  onClose: () => void
  onCreated: (tag: PoiTagDataFragment) => void
}

export const CreatePoiCategoryDialog: React.FC<Props> = ({ tagId, open, onClose, onCreated }) => {
  const { t } = useTranslation()
  const [tagName, setTagName] = React.useState<string>('')
  const [tagKey, setTagKey] = React.useState<string>('')
  const [materialIcon, setMaterialIcon] = React.useState<string>()
  const [svgIcon, setSvgIcon] = React.useState<string>()
  const [color, setColor] = React.useState<string>('')
  const [textColor, setTextColor] = React.useState<string>('')

  const { data } = useTagQuery({
    variables: {
      id: tagId!
    },
    skip: !tagId
  })

  const [createTag, { loading }] = useCreateTagMutation()
  const [updateTag, { loading: updateLoading }] = useUpdateTagMutation()

  useEffect(() => {
    if (data) {
      setTagName(data.tag.texts.name)
      setTagKey(data.tag.key)
      setMaterialIcon(data.tag.config.materialIcon)
      setSvgIcon(data.tag.config.svgIcon)
      setColor(data.tag.config.color)
      setTextColor(data.tag.config.textColor)
    } else {
      setTagName('')
      setTagKey('')
      setMaterialIcon('')
      setSvgIcon('')
      setColor('')
      setTextColor('')
    }
  }, [data])

  const onCreate = async () => {
    if (!data) {
      const tag = await createTag({
        variables: {
          data: {
            key: tagKey,
            name: tagName,
            config: {
              materialIcon,
              svgIcon,
              color,
              textColor
            }
          }
        }
      })
      onCreated(tag.data?.createTag as PoiTagDataFragment)
    } else {
      await updateTag({
        variables: {
          id: tagId!,
          data: {
            name: tagName,
            key: tagKey,
            config: {
              materialIcon,
              svgIcon,
              color,
              textColor
            }
          }
        }
      })
      onClose()
    }
  }

  return <Dialog open={open} onClose={onClose} sx={{ width: '100%', maxHeight: '90vh' }}>
    <DialogContent sx={{ width: '600px' }}>
      <Box display="flex" flexDirection="column">
        <TextField
          label={t('common.category')}
          variant="standard"
          value={tagName || ''}
          onChange={(e) => setTagName(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label={t('common.key')}
          variant="standard"
          value={tagKey || ''}
          onChange={(e) => setTagKey(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          sx={{ marginY: '32px' }}
          label={t('common.color')}
          value={color || ''}
          onChange={(e) => setColor(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Badge sx={{ backgroundColor: color, width: '20px', height: '20px' }}></Badge>
              </InputAdornment>
            )
          }}
        />
        <TextField
          sx={{ marginY: '32px' }}
          label={t('common.textColor')}
          value={textColor || ''}
          onChange={(e) => setTextColor(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Badge sx={{ backgroundColor: textColor, width: '20px', height: '20px' }}></Badge>
              </InputAdornment>
            )
          }}
        />
        <TextField
          label={t('common.materialIcon')}
          variant="standard"
          value={materialIcon || ''}
          onChange={(e) => setMaterialIcon(e.target.value)}
        />
        <TextField
          multiline
          minRows={3}
          maxRows={10}
          sx={{ flex: 1, mt: 2 }}
          label={t('common.svgIcon')}
          variant="standard"
          value={svgIcon || ''}
          onChange={(e) => setSvgIcon(e.target.value)}
        />
        {svgIcon && (
          <Stack mt={2} direction="row" alignItems="center" spacing={2}>
            <span>Preview:</span>
            <img width={40} height={40} src={`data:image/svg+xml;base64,${btoa(svgIcon)}`} />
          </Stack>)}
      </Box>
    </DialogContent>
    <DialogActions>
      <Button color='secondary' onClick={onClose}>
        {t('common.close')}
      </Button>
      <LoadingButton
        color="primary"
        onClick={onCreate}
        loading={loading || updateLoading}
        disabled={!tagName}
      >
        {t('common.save')}
      </LoadingButton>
    </DialogActions>
  </Dialog>
}
