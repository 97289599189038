import React from 'react'
import { Box, AppBar, Toolbar, IconButton } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import Logo from '@assets/images/logo2.png'
import { useNavigate } from 'react-router'
import { useAppDispatch } from '@services/store'
import { resetAuthData } from '@services/store/slices/auth'
import { useLogoutMutation } from '@typings/graphql'
import { AllBreadcrumbs } from '@shared/components/ui/Breadcrumbs'

export const NavBar: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [logout] = useLogoutMutation()

  const performLogout = async () => {
    await logout()
    dispatch(resetAuthData())
    navigate('/')
  }
  const staging = import.meta.env.REACT_APP_API_ENDPOINT.includes('staging')

  return (
    <AppBar sx={{ flex: '0 0 64px', position: 'relative' }}>
      <Toolbar
        sx={{
          backgroundColor: staging ? '#a7ffae' : '#fff',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box display="flex" alignItems="center">
          <img
            src={Logo}
            onClick={() => navigate('/', { replace: true })}
            style={{ cursor: 'pointer', height: '15px' }}
          />
          <AllBreadcrumbs />
        </Box>
        <IconButton
          size="large"
          edge="start"
          sx={{
            color: '#000',
            '&:hover': { color: '#8159F3' },
            cursor: 'pointer'
          }}
          onClick={performLogout}
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
