import React from 'react'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { Box, Checkbox, Chip, Collapse, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'

import { VoiceoverMarkerSelection } from './VoiceoverMarkerSelection'

type Props = {
  withSolution?: boolean
}

export const ExerciseTextEditor: React.FC<Props> = ({ withSolution = true }) => {
  const { t } = useTranslation()

  const { control } = useFormContext()
  const solutionText = useWatch({
    control,
    name: 'texts.solution'
  })

  const [showSolution, setShowSolution] = React.useState(!!solutionText)

  return (
    <Stack spacing={2}>
      <Box>
        <Chip label={t('common.exercise')} size="small" sx={{ marginBottom: '8px' }} />

        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <MarkdownEditor style={{ flex: 1 }} name='texts.exercise' height={150} />
          <Box width="250px">
            <VoiceoverMarkerSelection name='config.voiceoverMarker.exercise' />
          </Box>
        </Box>
      </Box>

      {withSolution && <Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Checkbox checked={showSolution} sx={{ ml: -1 }} onChange={(e) => setShowSolution(e.target.checked)} />
          <Chip label={t('common.solutionText')} size="small" />
        </Box>
        <Collapse in={showSolution}>

          <Box display="flex" flexDirection="row" alignItems="center" mt={2} gap={2}>
            <MarkdownEditor style={{ flex: 1 }} name='texts.solution' height={150} />
            <Box width="250px">
              <VoiceoverMarkerSelection name='config.voiceoverMarker.solution' />
            </Box>
          </Box>
        </Collapse>
      </Box>}
    </Stack>
  )
}
