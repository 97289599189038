import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

export const PaymentBreadcrumb: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return <Typography onClick={() => navigate('/payment')}>
    {t('payment.paymentHistory')}
  </Typography>
}
