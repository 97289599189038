import { Box, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import ReactAudioPlayer from 'react-audio-player'

import { MediaInfos } from './content-type-editors/components/MediaInfos'

export type PreviewFileData = {
  mimeType: string,
  url: string,
  fileName: string,
  fileSize?: number
}

type Props = {
  preview: PreviewFileData
}

const getStyle = (_preview: PreviewFileData) => {
  return {
    height: '100%',
    maxWidth: '100%'
  }
}

const ImagePreview: React.FC<Props> = ({ preview }) => {
  const style = useMemo(() => {
    return {
      ...getStyle(preview),
      objectFit: 'contain'
    }
  }, [])

  return (
    <>
      <img key={preview.fileName} src={preview.url} style={style} />
      <MediaInfos file={preview}></MediaInfos>
    </>
  )
}

const AudioPreview: React.FC<Props> = ({ preview }) => {
  const style = getStyle(preview)

  return <Box style={{
    ...style,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  }}
    sx={{
      'audio::-webkit-media-controls-volume-control-container': {
        display: 'none !important;'
      },
      'audio::-webkit-media-controls-mute-button': {
        display: 'none !important;'
      },
      'audio::-webkit-media-controls-volume-slider': {
        display: 'none !important;'
      }
    }}
  >
    <ReactAudioPlayer
      src={preview.url}
      style={{
        width: '100%',
        maxWidth: '300px',
        height: '32px'
      }}
      controls
    />
    <Typography mt={2}>{preview.fileName}</Typography>
    <MediaInfos file={preview}></MediaInfos>
  </Box>
}

const VideoPreview: React.FC<Props> = ({ preview }) => {
  const style = getStyle(preview)

  return <Box style={style}>
    <video autoPlay loop style={{ width: '100%', height: '100%' }}>
      <source src={preview.url} type={preview.mimeType} />
    </video>
  </Box>
}

const LottiePreview: React.FC<Props> = ({ preview }) => {
  const style = getStyle(preview)

  return <Box style={{ ...style, position: 'relative' }}>
    <Player
      src={preview.url}
      autoplay
      loop
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid meet'
      }}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0
      }}
    >
    </Player>
  </Box>
}

const PDFPreview: React.FC<Props> = ({ preview }) => {
  const style = getStyle(preview)

  return <Box style={style}>
    <embed src={`${preview.url}#toolbar=0&navpanes=0&scrollbar=0`}
      style={{ width: '100%', height: '100%' }}
      width="100%"
      height="100%"
      type="application/pdf"
    >
    </embed>
  </Box>
}

const DefaultPreview: React.FC<Props> = ({ preview }) => {
  const style = getStyle(preview)

  return <Box style={style}>
    <Typography>{preview.fileName}</Typography>
  </Box>
}

const fileFormatComponents = {
  image: ImagePreview,
  video: VideoPreview,
  audio: AudioPreview,
  'application/json': LottiePreview,
  'application/pdf': PDFPreview
  // 'text/html'?: string[]
}

export const FilePreview: React.FC<Props> = ({ preview }) => {
  const mimeType = preview.mimeType ?? (preview as any).type
  const mimeRoot = mimeType?.split('/')[0] as keyof typeof fileFormatComponents

  const PreviewComponent = fileFormatComponents[mimeType as keyof typeof fileFormatComponents] ??
    fileFormatComponents[mimeRoot as keyof typeof fileFormatComponents] ?? DefaultPreview

  return <PreviewComponent preview={preview} />
}
