import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, TypographyProps } from '@mui/material'
import { FilterOperators, PaymentStatus } from '@typings/graphql'
import { GridCellParams, GridFilterInputValueProps, GridFilterItem, GridFilterOperator, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { t as trans } from 'i18next'
import { ColumnStatusCouponStatus, PAYMENT_STATUS_COLORS } from '@features/payment/constants/constants'

import { PaginatedBookingColumnType, PaginatedBookingRowModel } from '../PaymentHistoryDataGrid'

const StatusText: React.FC<TypographyProps & {status: PaymentStatus | ColumnStatusCouponStatus}> = ({ status, ...props }) => {
  const { t } = useTranslation()

  return (<Typography
    color={`${PAYMENT_STATUS_COLORS[status]}.main`}
    {...props}
  >
    {t(`payment.statusTypes.${status.toLowerCase()}`)}
  </Typography>)
}

const RenderedCell:React.FC<GridRenderCellParams<PaginatedBookingRowModel>> = ({ row }) => {
  const status = row.usagesLeft === 0 ? ColumnStatusCouponStatus.Used : ColumnStatusCouponStatus.Active

  return <StatusText status={row.status || status} />
}

const RenderedFilter:React.FC<GridFilterInputValueProps> = (props) => {
  const { item, applyValue } = props
  const { t } = useTranslation()

  const handleFilterChange = (event: SelectChangeEvent) => {
    applyValue({ ...item, value: event.target.value })
  }

  const combinedStatus = { ...PaymentStatus, ...ColumnStatusCouponStatus }

  return (
    <FormControl variant='standard'>
      <InputLabel htmlFor="my-input">{t('payment.status')}</InputLabel>
      <Select onChange={handleFilterChange} value={item.value ?? ''}>
        {Object.keys(combinedStatus).map((status) => (
          <MenuItem key={status} value={combinedStatus[status as keyof typeof combinedStatus]}>
            <StatusText sx={{
              lineHeight: 1.4
            }}
              status={combinedStatus[status as keyof typeof combinedStatus]}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const statusFilterModel: GridFilterOperator[] = [{
  label: trans('filterOperator.equals'),
  value: FilterOperators.Equals as string,
  getApplyFilterFn (filterItem: GridFilterItem) {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null
    }

    return (params: GridCellParams) => {
      return params.value === filterItem.value
    }
  },
  InputComponent: RenderedFilter
}]

export const statusColumnModel: PaginatedBookingColumnType = {
  field: 'status',
  width: 175,
  valueGetter: (params) => params.row.status || '',
  renderCell (params) {
    return <RenderedCell {...params} />
  },
  filterOperators: statusFilterModel
}
