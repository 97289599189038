const lngLat = {
  type: 'array',
  items: [
    {
      type: 'number'
    },
    {
      type: 'number'
    }
  ]
}

export const defaultModeConfigSchema = {
  type: 'object',
  properties: {
    tourRoute: {
      type: 'array',
      description: '[[lng, lat], [lng, lat], ...]',
      items: {
        ...lngLat
      }
    },
    routeColor: {
      type: 'string'
    }
  }
}
