export const compassConfigSchema = {
  type: 'object',
  properties: {
    coords: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          lat: { type: 'number' },
          lng: { type: 'number' }
        }
      }
    }
  },
  required: ['coords']
}
