import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material'
import { themeIcons } from '@theme/index'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PaymentHistoryDetailsRow } from './PaymentHistoryDetailsRow'

type Props = {
  data: {
    orderId: string
    [key: string]: string
  }
}

export const PaymentChargeInformation: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <Accordion sx={{
      '&.MuiPaper-root': {
        boxShadow: 'none',
        margin: 0,
        '.MuiAccordionSummary-root': {
          minHeight: 'auto',
          padding: 0
        },
        '.MuiAccordionSummary-content': {
          margin: 0,
          p: '8px 0'
        },
        '.MuiAccordionDetails-root': {
          padding: 0
        }
      }
    }}
    >
      <AccordionSummary
        expandIcon={<themeIcons.ExpandMore />}
      >
        <Typography>{t('payment.moreInformation')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {
        Object.keys(data).map((key) => {
          if (key === 'orderId') return null

          return (
            <PaymentHistoryDetailsRow propKey={t(`payment.charge.${key}`)} key={key}>
              {data[key]}
            </PaymentHistoryDetailsRow>
          )
        })
      }
      </AccordionDetails>
    </Accordion>
  )
}
