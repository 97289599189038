import { PaginatedBookingColumnType } from './VoucherDataGrid'
import { codeColumnModel } from './columns/codeColumn'
import { createdAtColumnModel } from './columns/createdAtColumn'
import { usagesColumnModel } from './columns/usagesColumn'

export const dataGridColumns: PaginatedBookingColumnType[] = [
  codeColumnModel,
  createdAtColumnModel,
  usagesColumnModel
]
