import { Avatar, Box, Card, CardContent, Chip, Typography } from '@mui/material'
import { TourPoiQuery } from '@typings/graphql'
import React from 'react'
import DemoIcon from '@mui/icons-material/VisibilityOutlined'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

type Section = TourPoiQuery['tourPoi']['sections'][0]

type Props = {
  poiId: string
  section: Section
}

const SectionCard: React.FC<Props> = ({ poiId, section }) => {
  const navigate = useNavigate()
  const { id, modeId } = useParams()
  const { t } = useTranslation()

  const navigateTo = `/tour/${id}/mode/${modeId}/poi/${poiId}/section/${section.id}`

  return <Card
    sx={{
      width: '250px',
      aspectRatio: '2/3',
      backgroundColor: `${section.config.bgColor}`,
      '&:hover': {
        backgroundColor: `${section.config.cardColor}`
      },
      cursor: 'pointer',
      marginRight: '32px',
      marginBottom: '32px',
      padding: '12px',
      borderRadius: '15px'
    }}
    onClick={() => navigate(navigateTo)}
  >
    <CardContent sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      position: 'relative'
    }}
    >
      {section.inDemo && <Chip icon={<DemoIcon />}
        label={t('common.demo')}
        style={{
          backgroundColor: '#fff9',
          borderBottom: '1px solid #ccc',
          borderLeft: '1px solid #ccc',
          position: 'absolute',
          top: -12,
          right: -12,
          padding: '8px',
          borderStartStartRadius: 0,
          borderEndEndRadius: 0
        }}
      />}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden'
      }}
      >
        <Avatar
          sx={{
            width: 24,
            height: 24,
            backgroundColor: `${section.config.cardColor}`,
            color: '#fff',
            marginBottom: '8px',
            fontSize: '12px'
          }}
        >
          {section.order + 1}
        </Avatar>
        <Typography
          textAlign="center"
          fontWeight="600"
          variant="h6"
          marginBottom="32px"
          sx={{ color: '#000' }}
          textOverflow="ellipsis"
          width="100%"
          overflow="hidden"
        >
          {section.title}
        </Typography>
      </Box>
      {section.keyvisual &&
      <Box
        sx={{
          backgroundImage: `url(${section.keyvisual.url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          width: '150px',
          height: '150px',
          borderRadius: '30px',
          border: `3px solid ${section.config.cardColor}`
        }}
      />}
    </CardContent>
  </Card>
}

export default SectionCard
