import { interactionCardConfigSchemaProperties } from './interactionCard'

export const cameraViewConfigSchema = {
  type: 'object',
  properties: {
    flip: {
      type: 'string',
      enum: ['horizontal', 'vertical']
    },
    photoEnabled: { type: 'boolean' },
    ...interactionCardConfigSchemaProperties
  }
}
