import { Box, Card, CardContent, Typography } from '@mui/material'
import { TourPoiQuery } from '@typings/graphql'
import React from 'react'
import { useNavigate, useParams } from 'react-router'

type Section = TourPoiQuery['tourPoi']['sections'][0]
type Nugget = Section['nuggets'][0]

type Props = {
  poiId: string
  section: Section
  nugget: Nugget
}

const NuggetCard: React.FC<Props> = ({ poiId, section, nugget }) => {
  const navigate = useNavigate()
  const { id, modeId } = useParams()

  const cardItem = nugget || section
  const navigateTo = `/tour/${id}/mode/${modeId}/poi/${poiId}/section/${section.id}/nugget/${nugget.id}`

  return (<Card
    sx={{
      width: '250px',
      aspectRatio: '2/3',
      cursor: 'pointer',
      marginRight: '32px',
      marginBottom: '32px',
      borderRadius: '15px'
    }}
    onClick={() => navigate(navigateTo)}
  >
    <CardContent sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '100%',
      padding: 0
    }}
    >
      <Box
        sx={{
          backgroundImage: `url(${cardItem?.keyvisual?.url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '150px'
        }}
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '12px'
      }}
      >
        <Typography
          textAlign="center"
          fontWeight="600"
          variant="h6"
          marginBottom="12px"
          sx={{ color: '#000' }}
        >
          {nugget?.texts.title || section.title}
        </Typography>
        {nugget?.texts.subtitle && <Typography textAlign="center">
            {nugget.texts.subtitle}
          </Typography>}
      </Box>
    </CardContent>
  </Card>)
}

export default NuggetCard
