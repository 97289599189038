import { Box, Typography } from '@mui/material'
import { themeIcons } from '@theme/index'
import React from 'react'

type Props = {
  title: string;
  icon?: keyof typeof themeIcons;
  onFeatureClick: () => void;
};

export const RoleBasedFeatureCard: React.FC<Props> = ({ title, onFeatureClick, icon }) => {
  const Icon = React.useMemo(() => icon ? themeIcons[icon] : null, [icon])

  return (
    <Box onClick={onFeatureClick}
      sx={{
        cursor: 'pointer',
        borderRadius: '15px',
        backgroundColor: 'white',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        p: 5,
        '&:hover': {
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
          }
        }
      }}
    >
      {Icon && <Icon sx={{ width: '48px', height: '48px', mb: 2 }} />}
      <Typography variant="h6">{title}</Typography>
    </Box>
  )
}
