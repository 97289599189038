import { Box, Button, IconButton, Snackbar, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { themeIcons } from '@theme/index'

import { useVoucherContext } from '../../provider/VoucherProvider'
import { CreateVoucherModal } from '../createDrawer/CreateVoucherModal'

import { VoucherDataGrid } from './dataGrid/VoucherDataGrid'
import { VoucherDownloadOverlay } from './VocherDownloadOverlay'

export const VoucherHistoryList: React.FC = () => {
  const { t } = useTranslation()
  const { selectedTour } = useVoucherContext()

  const [fetchingCodes, setFetchingCodes] = React.useState<boolean>(false)
  const [showErrorSnackbar, setShowErrorSnackbar] = React.useState<boolean>(false)

  const createdTimestampRef = React.useRef<number>()

  const [showCreateVoucher, setShowCreateVoucher] = React.useState<boolean>(false)
  const [selectedVouchers, setSelectedVouchers] = React.useState<GridRowSelectionModel>()

  const voucherDownload = async (url: string) => {
    setFetchingCodes(true)
    createdTimestampRef.current = Date.now()

    let downloadLink: HTMLAnchorElement | null = null

    await fetch(url).then(async (response) => {
      const blob = await response.blob()
      downloadLink = document.createElement('a')

      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = 'qr-codes.zip'
    }).catch(() => {
      setShowErrorSnackbar(true)
    })

    const handleResponse = () => {
      if (downloadLink) {
        downloadLink.click()
      } else {
        setShowErrorSnackbar(true)
      }

      setFetchingCodes(false)
    }

    if (createdTimestampRef.current && Date.now() - createdTimestampRef.current < 2000) {
      setTimeout(() => {
        handleResponse()
      }, 2000 - (createdTimestampRef.current && Date.now() - createdTimestampRef.current))
    } else {
      handleResponse()
    }
  }

  const handleVoucherDownload = () => {
    if (!selectedVouchers?.length) {
      return
    }

    voucherDownload(`${import.meta.env.REACT_APP_API_ENDPOINT}/file/codes/${selectedVouchers.join(',')}`)
  }

  return (
    <Box sx={{
      backgroundColor: 'white',
      height: '100%',
      position: 'relative',
      p: 4
    }}
    >
      {!selectedTour
        ? <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        >
          <Typography variant="h5">{t('voucher.noTourSelected')}</Typography>
        </Box>
        : <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden'
        }}
        >
          <Box sx={{
            flex: 0,
            mb: 2
          }}
          >
            <Typography variant="h5">{selectedTour.title}</Typography>
          </Box>
          <Box sx={{
            flex: 1,
            overflow: 'hidden'
          }}
          >
            <VoucherDataGrid onVoucherSelected={setSelectedVouchers}/>
          </Box>
          <Box sx={{
            flex: 0,
            mt: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
          >
            <Button
              variant="contained"
              disabled={!selectedVouchers?.length}
              onClick={handleVoucherDownload}
            >
              {t('voucher.downloadVoucher')}
            </Button>
            <Button
              variant="contained"
              onClick={() => setShowCreateVoucher(true)}
            >
              {t('voucher.generateVoucher')}
            </Button>
          </Box>
        </Box>}

      <CreateVoucherModal
        open={showCreateVoucher}
        onCreated={voucherDownload}
        onClose={() => setShowCreateVoucher(false)}
      />

      <VoucherDownloadOverlay open={fetchingCodes} />

      {/* TODO Show detailed error message */}
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowErrorSnackbar(false)}
        message={t('errors.downloadError')}
        action={
          <IconButton
            size="small"
            onClick={() => setShowErrorSnackbar(false)}
          >
            <themeIcons.Close
              fontSize="small"
              sx={{
                color: 'white'
              }}
            />
          </IconButton>
          }
      />
    </Box>
  )
}
