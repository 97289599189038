import { LoginInput, useLoginMutation } from '@typings/graphql'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField, Alert } from '@mui/material'
import { useAppDispatch } from '@services/store'
import { setAuthData } from '@services/store/slices/auth'
import { useLocation, useNavigate } from 'react-router'
import { LoadingButton } from '@mui/lab'

type Props = {}

const LoginForm: React.FC<Props> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [login, { loading: isLoggingIn }] = useLoginMutation()

  const [loginBlockedFor, setLoginBlockedFor] = React.useState(0)
  const [invalidCredentials, setInvalidCredentials] = React.useState(false)

  const { handleSubmit, register } = useForm<LoginInput>({
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const onLogin = async (input: LoginInput) => {
    try {
      const result = await login({ variables: { data: input }, errorPolicy: 'all', fetchPolicy: 'network-only' })

      if (!result.data) {
        const { response } = result.errors?.[0].extensions.exception as Record<string, any>

        if (response?.loginBlockedFor) {
          setLoginBlockedFor(response.loginBlockedFor)
          window.setTimeout(() => {
            setLoginBlockedFor(0)
          }, response.loginBlockedFor)
        }

        setInvalidCredentials(true)
        return
      }

      dispatch(setAuthData(result.data.login))

      const origin = (location.state as any)?.from?.pathname || '/'
      navigate(origin, { replace: true })
    } catch (e) {
      setInvalidCredentials(true)
    }
  }

  const onSubmit = async (input: LoginInput) => {
    if (!loginBlockedFor) {
      await onLogin(input)
    }
  }

  return (<>
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        sx={{ mb: 3 }}
        fullWidth
        label={t('login.email') as string}
        {...register('email')}
        id="email"
        type="email"
      />
      <TextField
        sx={{ mb: 3 }}
        fullWidth
        label={t('login.password') as string}
        {...register('password')}
        id="password"
        type="password"
      />

      <LoadingButton
        variant="contained"
        fullWidth
        disabled={!!loginBlockedFor}
        loading={isLoggingIn}
        color="secondary"
        sx={{
          alignSelf: 'right',
          marginTop: '8px',
          backgroundColor: '#000'
        }}
        type="submit"
      >
        {t('login.title')}
      </LoadingButton>

      {invalidCredentials && !(loginBlockedFor > 0) && (
      <Alert severity="error" sx={{ width: '100%', fontSize: '12px', mt: 2 }}>
        {t('login.invalidCredentials', { seconds: Math.ceil(loginBlockedFor / 1000) })}
      </Alert>
      )}

      {loginBlockedFor > 0 && (
      <Alert severity="warning" sx={{ width: '100%', fontSize: '12px', mt: 2 }}>
        {t('login.loginBlockedFor', { seconds: Math.ceil(loginBlockedFor / 1000) })}
      </Alert>
      )}
    </form>
  </>)
}

export default LoginForm
