import React from 'react'
import {
  DataGridPro, GridColDef, GridColumnVisibilityModel, GridFilterModel, GridLocaleText,
  GridPagination, GridPaginationModel, GridSortModel, deDE, enUS
} from '@mui/x-data-grid-pro'
import {
  BookingFilter,
  BookingFilterModels,
  BookingOrder,
  BookingOrderField, FilterOperators, OrderDirection,
  TourPaginatedBookingsQuery, TourPaginatedBookingsQueryVariables, useTourPaginatedBookingsQuery
} from '@typings/graphql'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { FetchMoreQueryOptions } from '@apollo/client'
import i18n from '@services/i18n'
import { Box, IconButton } from '@mui/material'
import { themeIcons } from '@theme/index'
import { can } from '@utils/permissions'

import { ColumnStatusCouponStatus } from '../constants/constants'

import { dataGridColumns } from './DataGridColumns'
import { DataGridToolbar } from './components/DataGridToolbar'

type Props = {
  onShowDetails: (code: string) => void
}

export type PaginatedBookingNode = Exclude<TourPaginatedBookingsQuery['tour']['bookingsPaginated']['edges'], null | undefined>[0]['node']
export type PaginatedBookingNodePayment = Exclude<PaginatedBookingNode['payment'], null | undefined>
export type PaginatedBookingRowModel = Omit<PaginatedBookingNode, '__typename' | 'payment'>
  & Partial<Omit<PaginatedBookingNodePayment, '__typename'>>

export type PaginatedBookingColumnType = Omit<GridColDef<PaginatedBookingRowModel>, 'field'> & {
  field: keyof PaginatedBookingRowModel | 'action',
  adminOnly?: boolean
  hideInPanel?: boolean
}

// TODO - find better solution for this
const PAYMENT_FIELDS: (keyof PaginatedBookingNodePayment)[] = ['amount', 'paymentMethod', 'status', 'chargeId', 'email']

const PAGE_SIZE = 10
const COUPON_BASE_FILTER = {
  field: 'payment',
  operator: FilterOperators.Is,
  value: null,
  model: BookingFilterModels.Booking
}

export const PaymentHistoryDataGrid: React.FC<Props> = ({ onShowDetails }) => {
  const { t } = useTranslation()
  const { id } = useParams<{id: string}>()

  const isFetchingRef = React.useRef<boolean>(false)

  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE
  })

  const [sortModel, setSortModel] = React.useState<GridSortModel[0] | undefined>({
    field: BookingOrderField.CreatedAt,
    sort: OrderDirection.Desc
  })

  const [filterModel, setFilterModel] = React.useState<GridFilterModel | undefined>({
    items: []
  })

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})

  const [rowCountState, setRowCountState] = React.useState(0)
  const [refetching, setRefetching] = React.useState(false)

  const { data, loading, fetchMore } = useTourPaginatedBookingsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: id as string,
      first: PAGE_SIZE,
      orderBy: sortModel
        ? {
            field: sortModel.field as BookingOrderField,
            direction: sortModel.sort as OrderDirection
          }
        : undefined
    },
    skip: !id
  })

  React.useEffect(() => {
    setRefetching(isFetchingRef.current)
  }, [isFetchingRef.current])

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.tour.bookingsPaginated.totalCount !== undefined
        ? data?.tour.bookingsPaginated.totalCount
        : prevRowCountState
    )
  }, [data])

  // TODO Dynamic Translations
  const dataGridTranslations = React.useMemo<Partial<GridLocaleText> | null>(() => {
    return i18n.language.includes('DE')
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : enUS.components.MuiDataGrid.defaultProps.localeText
  }, [])

  const paginationEdges = React.useMemo(() => data?.tour.bookingsPaginated.edges || [], [data])

  const columnData = React.useMemo<PaginatedBookingColumnType[]>(() => {
    const columnVisibility: GridColumnVisibilityModel = {}

    const filledColumnData: PaginatedBookingColumnType[] = [...dataGridColumns.filter((column) => {
      if (column.hideInPanel) {
        columnVisibility[column.field] = false
      }
      return !column.adminOnly || can('scope:admin')
    }), {
      field: 'action',
      width: 100,
      filterable: false,
      hideable: false,
      renderHeader: () => null,
      renderCell: (params) => {
        return (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%'
          }}
          >
            <IconButton onClick={() => onShowDetails(params.row.code) }>
              <themeIcons.Visibility />
            </IconButton>
          </Box>
        )
      }

    }]

    setColumnVisibilityModel(columnVisibility)
    return filledColumnData.map((column) => {
      return {
        sortable: Object.values(BookingOrderField).includes(column.field as any),
        headerName: t(`payment.${column.field}`),
        disableColumnMenu: true,
        ...column
      }
    })
  }, [])

  const gridRowData = React.useMemo<PaginatedBookingRowModel[]>(() => {
    if (!paginationEdges) {
      return []
    }

    const mappedRows = paginationEdges.map(({ node }) => {
      return {
        code: node.code,
        usages: node.usages,
        usagesLeft: node.usagesLeft,
        createdAt: node.createdAt,
        ...node.payment
      }
    })

    return mappedRows
  }, [paginationModel, paginationEdges])

  const getFilterModel = React.useCallback((filter?: GridFilterModel): BookingFilter[] => {
    const additionalFilter: BookingFilter[] = []

    const activeFilter = filter?.items.filter(({ value }) => value !== undefined).map((item) => {
      const newItem = { ...item }

      if (item.field === 'paymentMethod' && item.value === 'coupon') {
        return item.operator === FilterOperators.Not ? { ...COUPON_BASE_FILTER, operator: FilterOperators.IsNotNull } : COUPON_BASE_FILTER
      }

      if (item.field === 'status' && Object.values(ColumnStatusCouponStatus).includes(item.value as any)) {
        newItem.field = 'usagesLeft'
        newItem.operator = item.value === ColumnStatusCouponStatus.Used ? FilterOperators.Equals : FilterOperators.Gt
        newItem.value = 0

        additionalFilter.push(COUPON_BASE_FILTER)
      }

      const model = PAYMENT_FIELDS.includes(newItem.field as keyof PaginatedBookingNodePayment)
        ? BookingFilterModels.Payment
        : BookingFilterModels.Booking

      return {
        field: newItem.field as BookingFilter['field'],
        operator: newItem.operator as FilterOperators,
        value: newItem.value,
        model
      }
    }) || []

    return [...activeFilter, ...additionalFilter]
  }, [])

  const getSortModel = React.useCallback((sort: GridSortModel[0] | undefined): BookingOrder => {
    const sorting = sort?.field ? sort : undefined

    setSortModel(sorting)

    return {
      field: sorting ? sorting.field as BookingOrderField : BookingOrderField.CreatedAt,
      direction: sorting ? sorting.sort as OrderDirection : OrderDirection.Desc
    }
  }, [])

  const callFetchMore = React.useCallback(async (options: FetchMoreQueryOptions<TourPaginatedBookingsQueryVariables>) => {
    await fetchMore({
      ...options,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          ...prev,
          tour: {
            ...prev.tour,
            bookingsPaginated: {
              ...prev.tour.bookingsPaginated,
              edges: [
                ...(fetchMoreResult.tour.bookingsPaginated.edges || [])
              ],
              pageInfo: fetchMoreResult.tour.bookingsPaginated.pageInfo,
              totalCount: fetchMoreResult.tour.bookingsPaginated.totalCount
            }
          }
        }
      }
    })

    isFetchingRef.current = false
  }, [fetchMore, data?.tour.bookingsPaginated.pageInfo.endCursor, data?.tour.bookingsPaginated.pageInfo.startCursor])

  const handlePaginationModelChange = async (newPaginationModel: GridPaginationModel) => {
    if (isFetchingRef.current) {
      return
    }

    const directionForward = newPaginationModel.page >= paginationModel.page
    const pageSizeChanged = newPaginationModel.pageSize !== paginationModel.pageSize
    const currentPage = pageSizeChanged
      ? Math.floor(paginationModel.page * paginationModel.pageSize / newPaginationModel.pageSize)
      : newPaginationModel.page

    isFetchingRef.current = true

    setPaginationModel({
      page: currentPage,
      pageSize: newPaginationModel.pageSize
    })

    await callFetchMore({
      variables: {
        ...(directionForward
          ? {
              first: newPaginationModel.pageSize,
              skip: pageSizeChanged ? currentPage * newPaginationModel.pageSize : 1,
              after: pageSizeChanged ? undefined : data?.tour.bookingsPaginated.pageInfo.endCursor
            }
          : {
              first: undefined,
              last: newPaginationModel.pageSize,
              skip: 1,
              after: undefined,
              before: data?.tour.bookingsPaginated.pageInfo.startCursor
            }),
        orderBy: getSortModel(sortModel),
        filter: getFilterModel(filterModel)
      }
    })

    isFetchingRef.current = false
  }

  const handleSortingModelChange = async (newSortModel: GridSortModel) => {
    if (isFetchingRef.current) {
      return
    }

    await callFetchMore({
      variables: {
        first: paginationModel.pageSize,
        skip: paginationModel.page * paginationModel.pageSize,
        after: undefined,
        before: undefined,
        orderBy: getSortModel(newSortModel[0]),
        filter: getFilterModel(filterModel)
      }
    })
  }

  const handelFilterModelChange = async (newFilterModel: GridFilterModel) => {
    if (isFetchingRef.current) {
      return
    }

    isFetchingRef.current = true

    setFilterModel(newFilterModel)

    await callFetchMore({
      variables: {
        first: paginationModel.pageSize,
        skip: 0,
        after: undefined,
        before: undefined,
        orderBy: getSortModel(sortModel),
        filter: getFilterModel(newFilterModel)
      }
    })

    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize
    })

    isFetchingRef.current = false
  }

  return (
    <DataGridPro
      sx={{
        height: '100%'
      }}
      columns={columnData}
      rows={gridRowData}
      rowCount={rowCountState}
      paginationModel={paginationModel}
      paginationMode='server'
      pagination
      onPaginationModelChange={handlePaginationModelChange}
      sortModel={sortModel ? [sortModel] : []}
      sortingMode='server'
      onSortModelChange={handleSortingModelChange}
      filterModel={filterModel}
      filterMode='server'
      onFilterModelChange={handelFilterModelChange}
      loading={loading || refetching}
      pageSizeOptions={[10, 20, 50, 100, 200, 500, 1000]}
      getRowId={({ code }) => code}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={setColumnVisibilityModel}
      slotProps={{ filterPanel: { logicOperators: [] } }}
      slots={{
        toolbar: DataGridToolbar,
        pagination: () =>
          <GridPagination
            backIconButtonProps={{
              disabled: loading || refetching || paginationModel.page === 0
            }}
            nextIconButtonProps={{
              disabled: loading || refetching || !data?.tour.bookingsPaginated.pageInfo.hasNextPage
            }}
          />
      }}
      {...dataGridTranslations && { localeText: dataGridTranslations }}
    />
  )
}
