const questionItemSchema = {
  type: 'object',
  properties: {
    type: { type: 'string', enum: ['poll'] },
    answer: { type: 'integer' },
    count: { type: 'integer' }
  }
}

export const quizConfigSchema = {
  type: 'object',
  properties: {
    questions: {
      type: 'array',
      items: {
        type: questionItemSchema
      },
      minItems: 1
    }
  },
  required: ['questions']
}
