import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import {
  Box,
  Chip,
  TextField
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { MarkdownEditor } from '../../MarkdownEditor'

import { ExerciseTextEditor } from './components/ExerciseTextEditor'
import { VoiceoverMarkerSelection } from './components/VoiceoverMarkerSelection'

import type { ContentEditorComponentProps } from '.'

type UpdateCountdownCounterContentFormInput = {
  texts: {
    exercise: string,
    unit?: string,
    textLowCount?: string,
    textMediumCount?: string,
    textHighCount?: string,
  },
  config: {
    voiceoverMarker?: {
      exercise: string,
    },
    limitLow?: number,
    limitMedium?: number,
    time: number,
    increment?: number,
  }
}

export const CountdownCounterContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const defaultValues = useMemo<UpdateCountdownCounterContentFormInput>(() => {
    return {
      texts: content.texts,
      config: {
        ...{
          time: 30,
          increment: 1,
          limitLow: 5,
          limitMedium: 10
        },
        ...content.config
      }
    }
  }, [content])

  const methods = useForm<UpdateCountdownCounterContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const canSave = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = async (submittedData: UpdateCountdownCounterContentFormInput) => {
    setLoading(true)

    const texts = await syncContentTexts(content, submittedData.texts)

    try {
      await updateContent(content.id, {
        nuggetId,
        texts,
        config: submittedData.config,
        order: content.order
      })
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={4} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <ExerciseTextEditor withSolution={false} />

          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <Box flex={1}>
              <TextField {...methods.register('texts.unit')}
                sx={{ width: '100%' }}
                label={t('common.unit')}
              />
            </Box>

            <Box flex={1}>
              <TextField {...methods.register('config.time', { required: true, valueAsNumber: true })}
                type="number"
                sx={{ width: '100%' }}
                label={t('edit.content.timeInSeconds')}
              />
            </Box>

            <Box flex={1}>
              <TextField {...methods.register('config.increment', { required: true, valueAsNumber: true })}
                type="number"
                inputProps={{ step: 0.1 }}
                sx={{ width: '100%' }}
                label={t('edit.content.increment')}
              />
            </Box>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <TextField sx={{ flex: 1 }} label="limitLow" {...methods.register('config.limitLow', { required: true, valueAsNumber: true })} />
            <TextField sx={{ flex: 1 }} label="limitMedium" {...methods.register('config.limitMedium', { required: true, valueAsNumber: true })} />
            <Box flex={1} />
          </Box>

          <Box display="flex" flexDirection="row" gap={2}>
            <Box display="flex" flexDirection="column" flex={1} gap={4}>
              <Box>
                <Chip label="textLowCount" size="small" sx={{ marginBottom: '8px' }} />
                <MarkdownEditor height={150} name="texts.textLowCount" />
              </Box>
              <VoiceoverMarkerSelection name='config.voiceoverMarker.textLowCount' />
            </Box>

            <Box display="flex" flexDirection="column" flex={1} gap={4}>
              <Box>
                <Chip label="textMediumCount" size="small" sx={{ marginBottom: '8px' }} />
                <MarkdownEditor height={150} name="texts.textMediumCount" />
              </Box>
              <VoiceoverMarkerSelection name='config.voiceoverMarker.textMediumCount' />
            </Box>

            <Box display="flex" flexDirection="column" flex={1} gap={4}>
              <Box>
                <Chip label="textHighCount" size="small" sx={{ marginBottom: '8px' }} />
                <MarkdownEditor height={150} name="texts.textHighCount" />
              </Box>
              <VoiceoverMarkerSelection name='config.voiceoverMarker.textHighCount' />
            </Box>
          </Box>
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading}
          uploadError={uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
