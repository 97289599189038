import {
  ApolloClient,
  ApolloLink,
  split
} from '@apollo/client'
import { withScalars } from 'apollo-link-scalars'
import { DateTimeResolver } from 'graphql-scalars'
import { buildClientSchema } from 'graphql'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { setContext } from '@apollo/client/link/context'

import introspectionResult from '../../graphql.schema.json'
import { store } from '../store/index'

import { errorLink } from './token-refresh-link'
import { cache } from './cache'

interface Definition {
  kind: string;
  operation?: string;
}

const schema = buildClientSchema(introspectionResult as any)

const typesMap = {
  Date: DateTimeResolver
}

export const wsClient = createClient({
  url: `${(import.meta.env.REACT_APP_API_ENDPOINT ?? '').replace('http', 'ws')}/graphql`,
  keepAlive: 10000,
  isFatalConnectionProblem: () => false,
  retryAttempts: 20,
  retryWait: async () => {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 2000 + Math.random() * 3000))
  },
  connectionParams: () => {
    const token = store.getState().auth.accessToken
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : ''
      }
    }
  }
})
const wsLink = new GraphQLWsLink(wsClient)

const authLink = setContext(({ operationName }, { headers }) => {
  const token = store.getState().auth.accessToken
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-apollo-operation-name': operationName,
      authorization: token ? `Bearer ${token}` : '',
      'accept-language': 'de'
    }
  }
})

const httpLink = ApolloLink.from([
  errorLink as unknown as ApolloLink,
  authLink,
  withScalars({ schema, typesMap }),
  createUploadLink({
    credentials: 'include',
    uri: `${import.meta.env.REACT_APP_API_ENDPOINT}/graphql`
  }) as unknown as ApolloLink
])

const link = split(
  ({ query }) => {
    const { kind, operation }: Definition = getMainDefinition(query)

    return (
      kind === 'OperationDefinition' &&
      operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

export const apolloClient = new ApolloClient({
  link,
  cache
})
