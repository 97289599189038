export const crimeModeConfigSchema = {
  type: 'object',
  properties: {
    mapMaxZoom: {
      type: 'number'
    },
    mapMinZoom: {
      type: 'number'
    },
    backgroundType: {
      type: 'string',
      enum: ['default', 'video', 'image']
    },
    nuggetBackgroundType: {
      type: 'string',
      enum: ['default', 'video', 'image']
    },
    mapAnimationEndHeading: {
      type: 'number'
    },
    sectionOrderMarkerType: {
      type: 'string',
      enum: ['default', 'custom']
    },
    mapAnimationStartHeading: {
      type: 'number'
    }
  }
}
