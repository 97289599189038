import DefaultContentTypes from './default'
import LGSKidsContentTypes from './lgs-kids'
import LGSContentTypes from './lgs'

export const ContentTypeEditors = {
  default: DefaultContentTypes,
  crime: DefaultContentTypes,
  lgs: { ...DefaultContentTypes, ...LGSContentTypes },
  'lgs-kids': LGSKidsContentTypes
}
