import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import { Autocomplete, AutocompleteRenderInputParams, Box, Chip, Divider, Stack, TextField } from '@mui/material'
import { UpdateContentInput } from '@typings/graphql'
import { MuiIconNames } from '@features/cms/typings/material-icon-font'

import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { FormFooterBar } from '../../ui/FormFooterBar'
import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { linkConfigSchema } from '../config-schemas/default/link'
import { MarkdownEditor } from '../../MarkdownEditor'

import { ContentEditorComponentProps } from '.'

type UpdateLinkContentFormInput = {
  texts: Record<string, string>,
  blockedById: string | null,
  style: string,
  config: {
    url: string,
    icon: string
    buttonProps?: Record<string, any>
  }
}

const IconRow: React.FC<AutocompleteRenderInputParams> = (params) => {
  return <Stack direction="row" alignItems="center">
    <TextField {...params} />
  </Stack>
}

export const LinkContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const getDefaultValues = () => {
    return {
      texts: content.texts,
      blockedById: content.blockedById || null,
      config: content.config,
      style: content.style || ''
    }
  }

  const methods = useForm<UpdateLinkContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const onSubmit = async (submittedData: UpdateLinkContentFormInput) => {
    setLoading(true)

    const texts = await syncContentTexts(content, submittedData.texts)

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      blockedById: submittedData.blockedById || null,
      config: submittedData.config,
      style: submittedData.style,
      texts
    }

    try {
      await updateContent(content.id, data)
      methods.reset()
    } catch (e) {
      setError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch?.()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setError(undefined)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Stack spacing={2} direction="row">
          <Stack spacing={2} flex={1} width="100%">
            <Box>
              <Chip
                label={t('common.url')}
                size="small"
                sx={{ marginBottom: '12px' }}
              />
              <TextField label={t('common.url')} {...methods.register('config.url', { required: true })} sx={{ width: '100%' }} />
            </Box>
            <Box>
              <Chip
                label={t('common.icon')}
                size="small"
                sx={{ marginBottom: '12px' }}
              />
              <Controller name="config.icon"
                control={methods.control}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    options={MuiIconNames}
                    renderInput={IconRow}
                    disableClearable
                    defaultValue="open_in_new"
                    value={value}
                    onChange={(_, newValue) => onChange(newValue as string)}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Box>
            <Box>
              <Chip
                label={t('common.buttonText')}
                size="small"
                sx={{ marginBottom: '12px' }}
              />
              <TextField label={t('common.buttonText')}
                {...methods.register('texts.buttonText')}
                placeholder="Webseite öffnen"
                sx={{ width: '100%' }}
              />
            </Box>
          </Stack>
          <Box flex={1}>
            <Chip
              label={t('common.text')}
              size="small"
              sx={{ marginBottom: '12px' }}
            />
            <MarkdownEditor name='texts.text' />
          </Box>
        </Stack>

        <Divider />
        <ConfigAndStyleEditor content={content} schema={linkConfigSchema} />
        <ContentBlockEditor content={content} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
