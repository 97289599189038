import { ThemeOptions } from '@mui/material/styles'

import { palette } from './palette'
import { MuiDataGrid } from './components/dataGrid'

export { customThemeIcons, themeIcons } from './icons/icons'

const components: ThemeOptions['components'] = {
  MuiDataGrid,
  MuiInputBase: {
    defaultProps: {
      inputProps: {
        spellCheck: true
      }
    }
  }
}

export const themeProperties: ThemeOptions = {
  components, palette
}
