export const sequenceConfigSchema = {
  type: 'object',
  properties: {
    frames: {
      type: 'array',
      items: [
        {
          type: 'number'
        },
        {
          type: 'number'
        }
      ]
    },
    backgroundPosition: {
      type: 'number'
    }
  },
  required: ['frames']
}
