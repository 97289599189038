import { useAppSelector } from '@services/store'
import { isLoggedIn } from '@services/store/slices/auth'
import React, { ReactElement } from 'react'
import { Navigate } from 'react-router'
import { Error403 } from '@features/auth/views/Error403'
import { can } from '@utils/permissions'
import { useMatchedRoutes } from '@hooks/useMatchedRoute'

type Props = {
  component: ReactElement,
}

const ProtectedRoute: React.FC<Props> = ({ component }) => {
  const isUserLoggedIn = useAppSelector(isLoggedIn)
  const matchedRoutes = useMatchedRoutes()

  if (!isUserLoggedIn) {
    return <Navigate to="/login" replace />
  }

  const permission = matchedRoutes?.[0].route.permission || ''

  if (permission && !can(permission)) {
    return <Error403 />
  }

  return component
}

export default ProtectedRoute
