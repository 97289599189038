import { apolloClient } from '@services/apollo'
import {
  ContentDataFragment,
  DeleteTranslationDocument,
  DeleteTranslationMutationFn,
  DeleteTranslationMutationVariables,
  DeleteTranslationsDocument,
  DeleteTranslationsMutationFn,
  DeleteTranslationsMutationVariables
} from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'

export const syncContentTexts = async (content: ContentDataFragment, texts: Record<string, string>) => {
  const deletedTextKeys = Object.keys(content.texts ?? {}).filter((key) => !texts[key])

  if (deletedTextKeys.length) {
    await apolloClient.mutate<DeleteTranslationsMutationFn, DeleteTranslationsMutationVariables>({
      mutation: DeleteTranslationsDocument,
      variables: {
        model: 'Content',
        modelId: content.id,
        keys: deletedTextKeys
      }
    })
  }

  return Object.keys(texts).filter(key => !!texts[key]).map((key) => convertToTextObject(key, texts[key]))
}

export const deleteTexts = async (variables: DeleteTranslationMutationVariables[]) => {
  const deletedTexts = variables.map((vars) => (
    new Promise((resolve, reject) => {
      apolloClient.mutate<DeleteTranslationMutationFn, DeleteTranslationMutationVariables>({
        mutation: DeleteTranslationDocument,
        variables: vars
      }).then(resolve).catch(reject)
    })
  ))

  return await Promise.all(deletedTexts).then((values) => values).catch((e) => e)
}
