import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'

import { VoucherTourType, useVoucherContext } from '../../provider/VoucherProvider'

type Props = {
  tour: VoucherTourType
  groupId: string
  isLast?: boolean
}

export const VoucherTourListAccordionItem: React.FC<Props> = ({ tour, groupId, isLast = false }) => {
  const { selectedTour, updateSelectedTour, updateSelectedCustomerGroup } = useVoucherContext()

  const onSelectTour = () => {
    updateSelectedTour(tour)
    updateSelectedCustomerGroup(groupId)
  }

  return (
    <Box sx={{
      cursor: 'pointer',
      px: 2,
      py: 1,
      backgroundColor: selectedTour?.id === tour.id ? 'primary.main' : 'transparent',
      color: selectedTour?.id === tour.id ? 'primary.contrastText' : 'text.primary',
      '&:hover': {
        backgroundColor: selectedTour?.id === tour.id ? 'primary.main' : 'grey.100'
      },
      ...(!isLast && {
        borderBottom: '1px solid',
        borderColor: 'grey.200'
      })
    }}
      onClick={onSelectTour}
    >
      <Box sx={{

        height: '60px',
        display: 'flex',
        flexDirection: 'row'
      }}
      >
        <Box sx={{
          height: '100%',
          aspectRatio: '1 / 1',
          borderRadius: '12px',
          backgroundImage: `url(${tour.keyvisual?.url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          mr: 2
        }}
        />
        <Box sx={{
          flex: 1,
          overflow: 'hidden'
        }}
        >
          <Typography>{tour.title}</Typography>
          <Tooltip title={tour.subtitle}>
            <Typography sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
              variant="subtitle2"
            >{tour.subtitle}</Typography>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )
}
