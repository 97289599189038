import { CustomerGroupsQuery } from '@typings/graphql'
import React, { PropsWithChildren } from 'react'

export type VoucherProviderContextType = {
  selectedTour: VoucherTourType | undefined
  selectedCustomerGroup: string
  updateSelectedTour: (tour: VoucherTourType) => void
  updateSelectedCustomerGroup: (groupId: string) => void
}

export type VoucherTourType = CustomerGroupsQuery['customerGroups'][0]['tours'][0]

const VoucherProviderContext = React.createContext<VoucherProviderContextType>(
  {} as any
)

export const VoucherProvider:React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedTour, setSelectedTour] = React.useState<VoucherTourType | undefined>()
  const [selectedCustomerGroup, setSelectedCustomerGroup] = React.useState<string>('')

  const updateSelectedTour = React.useCallback((tour: VoucherTourType) => {
    setSelectedTour(tour)
  }, [])

  const updateSelectedCustomerGroup = React.useCallback((groupId: string) => {
    setSelectedCustomerGroup(groupId)
  }, [])

  const value = React.useMemo<VoucherProviderContextType>(() => ({
    selectedTour,
    selectedCustomerGroup,
    updateSelectedTour,
    updateSelectedCustomerGroup
  }), [selectedTour, selectedCustomerGroup])

  return (
    <VoucherProviderContext.Provider value={value}>
      {children}
    </VoucherProviderContext.Provider>
  )
}

export const useVoucherContext = () => React.useContext(VoucherProviderContext)
