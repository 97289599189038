import React, { useMemo, useState } from 'react'
import { UploadFileMutationVariables } from '@typings/graphql'
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  ContentFile,
  deleteContentFiles,
  replaceContentFiles,
  uploadContentFiles
} from './content-type-editors/mutation-helper/file'
import FileDragDrop, { FileFormats } from './FileDragDrop'

type Props = {
  files: ContentFile[];
  accept?: FileFormats;
  model: string;
  modelId: string;
  refetch?: () => void;
}

type FormData = {
  files: {
    file: ContentFile | File | null,
    replaceId: string | null,
    key: string,
  }[]
}

type FileIdAndKey = {
  id: string,
  key: string
}

export const FileList: React.FC<Props> = ({
  accept = {
    'image/*': ['.png', '.jpg', '.jpeg', '.webp'],
    'video/*': ['.mp4', '.webm'],
    'audio/*': ['.mp3', '.mp4', '.acc', '.wav'],
    'application/json': ['.json'],
    'text/csv': ['.csv']
  },
  files,
  model,
  modelId,
  refetch
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [error, setError] = useState<unknown | undefined>(undefined)
  // const [showSnackbar, setShowSnackbar] = useState(false)
  const [deletedFileIdsAndKeys, setDeletedFileIdsAndKeys] = useState<FileIdAndKey[]>([])

  const { t } = useTranslation()

  const getFormValue = () => {
    return {
      files: [...files].map((file) => ({
        file,
        replaceId: null,
        key: file.key
      }))
    }
  }

  const methods = useForm<FormData>({
    defaultValues: getFormValue()
  })

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: 'files'
  })

  const onSubmit = async () => {
    const submittedData = methods.getValues()
    setLoading(true)

    const carouselFiles = submittedData.files.filter((file) => file.file !== null)

    const newFilesUploadVariables = carouselFiles.reduce((acc, curr, index) => {
      if (!(curr.file as any)?.id && !curr.replaceId) {
        acc.push({
          file: curr.file,
          data: {
            key: curr.key,
            order: index,
            model,
            modelId
          }
        })
      }

      return acc
    }, [] as UploadFileMutationVariables[])

    if (newFilesUploadVariables.length) {
      try {
        await uploadContentFiles(newFilesUploadVariables)
      } catch (e) {
        // setError(e)
      }
    }

    const replaceFileVariables = carouselFiles.reduce((acc, curr, index) => {
      if (curr.replaceId) {
        acc.push({
          file: curr.file,
          data: {
            key: curr.key,
            replaceId: curr.replaceId,
            order: index,
            model,
            modelId
          }
        })
      }

      return acc
    }, [] as UploadFileMutationVariables[])

    if (replaceFileVariables.length) {
      try {
        await replaceContentFiles(replaceFileVariables)
      } catch (e) {
        // setError(e)
      }
    }

    if (deletedFileIdsAndKeys.length) {
      const deletedFileIds = deletedFileIdsAndKeys.map((item) => ({ id: item.id }))

      try {
        await deleteContentFiles(deletedFileIds)
      } catch (e) {
        // setError(e)
      }
    }

    setLoading(false)
    // setShowSnackbar(true)
    refetch?.()
    setShowDialog(false)
  }

  const replaceFile = (newFile: File, oldFile: ContentFile, index: number) => {
    methods.setValue(`files.${index}.file`, newFile, { shouldDirty: true })

    if (oldFile) methods.setValue(`files.${index}.replaceId`, oldFile.id)
  }

  const deleteFile = (index: number, fileId: string, key: string) => {
    remove(index)

    if (fileId) {
      setDeletedFileIdsAndKeys((prev) => [...prev, {
        id: fileId,
        key
      }])
    }
  }

  const addFileItem = () => {
    append({
      file: null,
      replaceId: null,
      key: ''
    })
  }

  const fileEditItems = useMemo(() => (
    fields.map((field, index) => {
      const { file, id } = field

      return (
        <Box
          key={id}
          height="150px"
          width="100%"
          display="flex"
          alignItems="flex-start"
          p={2}
          pr={1}
          border="1px solid #CFD7DD"
          borderTop={index === 0 ? undefined : 'none'}
        >
          <FileDragDrop
            accept={accept}
            height="100%"
            width="30%"
            flex="0 0 30%"
            initialFile={file as ContentFile}
            preview
            onFileChanged={(value) => replaceFile(value, file as ContentFile, index)}
          />
          <Box flex={1} px={2} display="flex" height="100%" flexDirection="column" justifyContent="space-between">
            <Controller
              control={methods.control}
              name={`files.${index}.key`}
              rules={{
                validate: () => methods.getValues(`files.${index}.file`) !== null,
                required: true
              }}
              render={({ field: { onChange, value }, fieldState: { error: e } }) => {
                return <TextField
                  value={value}
                  onChange={onChange}
                  error={!!e}
                  disabled={(file as ContentFile)?.id?.startsWith('cl')}
                  sx={{ width: '100%' }}
                  helperText={!!e && t('edit.content.fileRequired')}
                  label={t('common.key')}
                />
              }}
            />
            {file && <Box>
              <Typography fontSize={12}>Filename: {(file as ContentFile).fileName}</Typography>
              <Typography fontSize={12}>Mime-Type: {(file as ContentFile).mimeType}</Typography>
            </Box>}
          </Box>
          <Box padding="10px" height="56px">
            <IconButton onClick={() => deleteFile(index, (file as any)?.id, (file as any)?.key)}>
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </Box>
        </Box>
      )
    })
  ), [fields])

  return <>
    <Button variant="outlined" onClick={() => setShowDialog(true)} startIcon={<FileCopyIcon />}>
      {t('edit.content.files')} ({files.length})
    </Button>
    <Dialog open={showDialog} PaperProps={{ sx: { maxWidth: '800px', width: '60vw' } }}>
      <DialogContent sx={{ width: '100%' }}>
        <Box>
          {fileEditItems}

          <Box width="100%" mt={2}>
            <Button variant="outlined" sx={{ width: '100%' }} onClick={addFileItem}>
              <Typography sx={{ marginRight: 1 }}>{t('edit.content.addFile')}</Typography>
              <AddCircleOutlineIcon></AddCircleOutlineIcon>
            </Button>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 4, pt: 0 }}>
        <Button
          variant="outlined"
          type="button"
          disabled={loading}
          onClick={() => setShowDialog(false)}
        >
          {t('common.cancel')}
        </Button>

        <Button
          variant="contained"
          type="button"
          disabled={!methods.formState.isValid || !methods.formState.isDirty || loading}
          onClick={onSubmit}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  </>
}
