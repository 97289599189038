import { useTourPoiQuery } from '@typings/graphql'
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { Howl } from 'howler'
import { parse } from 'papaparse'
import { parseVoiceoverMarkerTimestamp } from '@utils/format'

type VoiceoverContextData = {
  play: (sprite: string) => void;
  stop: () => void;
  sprite?: Record<string, [number, number]>;
};

const VoiceoverContext = React.createContext<VoiceoverContextData>(
  {} as any
)

export const SpriteVoiceoverProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const sound = useRef<Howl>()

  const [sprite, setSprite] = React.useState<Record<string, [number, number]>>()
  const { poiId } = useParams()

  const { data } = useTourPoiQuery({
    variables: { id: poiId as string }
  })

  const voiceoverFile = useMemo(() => {
    return data?.tourPoi.files.find((file) => file.key === 'voiceover')
  }, [data])

  const markerFile = useMemo(() => {
    return data?.tourPoi.files.find((file) => file.key === 'voiceover_marker')
  }, [data])

  const getSpriteData = async () => {
    if (!markerFile) {
      return
    }

    const fileData = await fetch(markerFile.url)
    const text = await fileData.text()

    const entries = [...parse(text).data] as (string[])[]

    setSprite(entries.reduce((acc, curr, index) => {
      if (index === 0 || !curr[0]) {
        return acc
      }

      acc[curr[0]] = [parseVoiceoverMarkerTimestamp(curr[1]), parseVoiceoverMarkerTimestamp(curr[2])]

      return acc
    }, {} as Record<string, [number, number]>))
  }

  useEffect(() => {
    if (!voiceoverFile || !markerFile || !sprite) {
      return
    }

    sound.current = new Howl({
      src: voiceoverFile?.url ?? '',
      sprite,
      html5: true
    })
  }, [sprite])

  useEffect(() => {
    getSpriteData()
  }, [markerFile])

  const stopSound = useCallback(() => {
    sound.current?.stop()
  }, [])

  const playSound = useCallback((id: string) => {
    sound.current?.stop()
    sound.current?.play(id)
  }, [])

  const value = useMemo(() => {
    return {
      play: playSound,
      stop: stopSound,
      sprite
    }
  }, [sprite])

  return <VoiceoverContext.Provider value={value}>{children}</VoiceoverContext.Provider>
}

export const useSpriteVoiceover = () => useContext(VoiceoverContext)
