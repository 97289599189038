import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import {
  Box,
  Chip,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentFile, uploadFile } from '../mutation-helper/file'
import { ConfigEditor } from '../components/ConfigEditor'
import { miniGameFrogDodgeConfigSchema } from '../config-schemas/lgs-kids/miniGameFrogDodge'

import { ExerciseTextEditor } from './components/ExerciseTextEditor'
import { VoiceoverMarkerSelection } from './components/VoiceoverMarkerSelection'

import type { ContentEditorComponentProps } from '.'

type UpdateMiniGameFrogDodgeFormInput = {
  texts: {
    exercise: string
    solution?: string,
    tryAgain?: string,
    wellDone?: string,
  }
  config: {
    [key: string]: any
    startSpeed: number,
    incrementBy: number,
  },
  files: Record<keyof typeof ImageFiles, ContentFile | File | undefined>
}

const ImageFiles = {
  background: {
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
    translationKey: 'dragImage'
  },
  animation: {
    accept: { 'application/json': ['.json'] },
    translationKey: 'dragLottie'
  },
  frogAnimation: {
    accept: { 'application/json': ['.json'] },
    translationKey: 'dragLottie'
  },
  foreground: {
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
    translationKey: 'dragImage'
  },
  ambientSound: {
    accept: { 'audio/*': ['.mp3', '.mp4', '.acc'] },
    translationKey: 'dragAudio'
  }
} as const

export const MiniGameFrogDodgeEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const defaultValues = useMemo<UpdateMiniGameFrogDodgeFormInput>(() => {
    return {
      texts: content.texts,
      config: content.config,
      files: {
        background: content.files.find(f => f.key === 'background'),
        frogAnimation: content.files.find(f => f.key === 'frogAnimation'),
        animation: content.files.find(f => f.key === 'animation'),
        foreground: content.files.find(f => f.key === 'foreground'),
        ambientSound: content.files.find(f => f.key === 'ambientSound')
      }
    }
  }, [content])

  const methods = useForm<UpdateMiniGameFrogDodgeFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const canSave = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = async (submittedData: UpdateMiniGameFrogDodgeFormInput) => {
    setLoading(true)

    const texts = await syncContentTexts(content, submittedData.texts)

    for (const fileKey in submittedData.files) {
      const file = submittedData.files[fileKey as keyof typeof ImageFiles]

      if (file && !(file as any).id) {
        await uploadFile({
          data: {
            key: fileKey,
            model: 'Content',
            modelId: content.id,
            replace: true
          },
          file
        })
      }
    }

    try {
      await updateContent(content.id, {
        nuggetId,
        texts,
        order: content.order,
        config: submittedData.config
      })
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={4} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <ExerciseTextEditor />

          <Stack direction="row" spacing={4}>
            <Box flex={1}>
              <Chip label={t('common.tryAgain')} size="small" sx={{ marginBottom: '8px' }} />
              <Stack direction="row" spacing={4} alignItems="center">
                <MarkdownEditor style={{ flex: 1 }} name='texts.tryAgain' height={150} />
                <VoiceoverMarkerSelection sx={{ width: 200 }} name='config.voiceoverMarker.tryAgain' />
              </Stack>
            </Box>
            <Box flex={1}>
              <Chip label={t('common.wellDone')} size="small" sx={{ marginBottom: '8px' }} />
              <Stack direction="row" spacing={4} alignItems="center">
                <MarkdownEditor style={{ flex: 1 }} name='texts.wellDone' height={150} />
                <VoiceoverMarkerSelection sx={{ width: 200 }} name='config.voiceoverMarker.wellDone' />
              </Stack>
            </Box>
          </Stack>

          <Stack direction="row" spacing={4}>
            <Box>
              <TextField {...methods.register('config.startSpeed', { valueAsNumber: true, min: 0, max: 100, required: true })}
                variant="standard"
                type="number"
                inputProps={{
                  step: 0.05,
                  min: 0,
                  max: 100
                }}
                sx={{ width: 200 }}
                label={t('edit.content.startSpeed')}
                defaultValue={0.5}
              />
            </Box>

            <Box>
              <TextField {...methods.register('config.incrementBy', { valueAsNumber: true, min: 0, max: 1, required: true })}
                variant="standard"
                type="number"
                inputProps={{
                  step: 0.05,
                  min: 0,
                  max: 1
                }}
                sx={{ width: 200 }}
                label={t('edit.content.incrementBy')}
                defaultValue={0.1}
              />
            </Box>

            <Box>
              <TextField {...methods.register('config.maxSpeed', { valueAsNumber: true, min: 1, max: 5, required: true })}
                variant="standard"
                type="number"
                inputProps={{
                  step: 0.1,
                  min: 1,
                  max: 5
                }}
                sx={{ width: 200 }}
                label={t('edit.content.maxSpeed')}
                defaultValue={1}
              />
            </Box>
          </Stack>

          <Stack direction="row" spacing={4}>
            {Object.keys(ImageFiles).map(key => {
              const { accept, translationKey } = ImageFiles[key as keyof typeof ImageFiles]

              return (
                <Box key={key} flex={1}>
                  <Chip label={key} size="small" sx={{ mb: 1 }} />
                  <Controller
                    name={`files.${key as keyof typeof ImageFiles}`}
                    control={methods.control}
                    render={({ field: { value, onChange } }) => (
                      <FileDragDrop
                        accept={accept as any}
                        height="300px"
                        preview
                        initialFile={(value as any)?.id ? value as ContentFile : undefined}
                        onFileChanged={onChange}
                      >
                        {!value && <Typography>{t(`edit.poi.${translationKey}`)}</Typography>}
                      </FileDragDrop>
                    )}
                  />
                </Box>
              )
            })}
          </Stack>

          <ConfigEditor schema={miniGameFrogDodgeConfigSchema} />
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading}
          uploadError={uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
