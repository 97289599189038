import React from 'react'
import { GridCellParams, GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { t as trans } from 'i18next'
import { FilterOperators } from '@typings/graphql'

import { PaginatedBookingColumnType } from '../PaymentHistoryDataGrid'

const PaymentMethods = [
  'paypal',
  'coupon',
  'free',
  'inapp-android',
  'inapp-ios'
]

const RenderedFilter:React.FC<GridFilterInputValueProps> = (props) => {
  const { item, applyValue } = props
  const { t } = useTranslation()

  const handleFilterChange = (event: SelectChangeEvent) => {
    applyValue({ ...item, value: event.target.value })
  }

  return (
    <FormControl variant='standard'>
      <InputLabel htmlFor="my-input">{t('payment.paymentMethod')}</InputLabel>
      <Select multiple onChange={handleFilterChange} value={item.value ?? []}>
        {PaymentMethods.map((method) => (
          <MenuItem key={method} value={method}>
            {t(`payment.paymentMethodType.${method}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const getApplyFilterFn = (filterItem: GridFilterItem) => {
  if (!filterItem.field || !filterItem.value || !filterItem.operator) {
    return null
  }

  return (params: GridCellParams) => {
    return params.value === filterItem.value
  }
}

const paymentMethodFilterModel: GridFilterOperator[] = [{
  label: trans('filterOperator.equals'),
  value: FilterOperators.Equals as string,
  getApplyFilterFn,
  InputComponent: RenderedFilter
}, {
  label: trans('filterOperator.not'),
  value: FilterOperators.Not as string,
  getApplyFilterFn,
  InputComponent: RenderedFilter
}]

export const paymentMethodColumnModel: PaginatedBookingColumnType = {
  field: 'paymentMethod',
  width: 200,
  valueGetter: (params) => params.row.paymentMethod?.toLowerCase() || 'coupon',
  renderCell: (params) => trans(`payment.paymentMethodType.${params.row.paymentMethod?.toLowerCase() || 'coupon'}`) as string,
  filterOperators: paymentMethodFilterModel
}
