import React, { useMemo } from 'react'
import { useParams, Outlet, useLocation, useMatches } from 'react-router'
import { useTourPoiQuery } from '@typings/graphql'
import { Box } from '@mui/material'
import { LinkTab } from '@features/cms/components/ui/LinkTab'
import { useTranslation } from 'react-i18next'

const PoiView: React.FC = () => {
  const { id, modeId, poiId, sectionId, nuggetId } = useParams()
  const { t } = useTranslation()

  const { data } = useTourPoiQuery({ variables: { id: poiId as string } })
  const poi = data?.tourPoi

  const location = useLocation()
  const matched = useMatches()
  const currentRoute = matched?.find((m) => m.pathname === location.pathname)
  const isCreate = useMemo(() => (currentRoute?.handle as any)?.isCreate, [currentRoute])

  const tourModeRoute = `/tour/${id}/mode/${modeId}`

  if (!poi) {
    return null
  }

  return (<>
    {!isCreate && <Box
      paddingX="16px"
      paddingY="8px"
      sx={{ borderBottom: 1, borderColor: 'divider' }}
    >
      <LinkTab
        label={t('edit.poi.settings')}
        to={!sectionId
          ? `${tourModeRoute}/poi/${poi.id}`
          : nuggetId
            ? `${tourModeRoute}/poi/${poi.id}/section/${sectionId}/nugget/${nuggetId}`
            : `${tourModeRoute}/poi/${poi.id}/section/${sectionId}`}
      />
        {!sectionId &&
          <LinkTab label={t('edit.poi.sections')} to={`${tourModeRoute}/poi/${poi.id}/sections`} />}
        {sectionId && !nuggetId &&
          <LinkTab label={t('edit.poi.nuggets')} to={`${tourModeRoute}/poi/${poi.id}/section/${sectionId}/nuggets`} />}
        {sectionId && nuggetId &&
          <LinkTab label={t('edit.poi.contents')} to={`${tourModeRoute}/poi/${poi.id}/section/${sectionId}/nugget/${nuggetId}/contents`} />}
      </Box>}

    <Outlet />
  </>)
}

export default PoiView
