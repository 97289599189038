import React, { useEffect, useRef } from 'react'

type Props = {
  url: string,
  width?: string,
  height?: string,
  autoPlay?: boolean,
  controls?: boolean,
}

export const VideoPlayer: React.FC<Props> = ({ url, width, height, autoPlay, controls }) => {
  const videoRef = useRef<any>()

  useEffect(() => {
    videoRef.current?.load()
  }, [url])

  return (<video
    ref={videoRef}
    width={width}
    height={height}
    controls={controls}
    autoPlay={autoPlay}
    style={{ objectFit: 'contain' }}
  >
    <source src={url}></source>
  </video>)
}
