import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Chip } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import { FileDataFragmentDoc, useUploadFileMutation } from '@typings/graphql'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentFile, isGraphQlFile } from '../mutation-helper/file'
import FileDragDrop from '../../FileDragDrop'

import { ExerciseTextEditor } from './components/ExerciseTextEditor'

import type { ContentEditorComponentProps } from '.'

type UpdateMiniGameHoneycombFormInput = {
  background?: ContentFile | File | null;
  foreground?: ContentFile | File | null;
  texts: {
    exercise: string;
    solution?: string;
  };
  config: {
    solution: number;
  };
};

export const MiniGameHoneycombEditor: React.FC<ContentEditorComponentProps> = ({
  nuggetId,
  content,
  onEdited
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile] = useUploadFileMutation()

  const defaultValues = useMemo<UpdateMiniGameHoneycombFormInput>(() => {
    return {
      background: content.files.find(file => file.key === 'background'),
      foreground: content.files.find(file => file.key === 'foreground'),
      texts: content.texts,
      config: content.config
    }
  }, [content])

  const methods = useForm<UpdateMiniGameHoneycombFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const canSave = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = async (submittedData: UpdateMiniGameHoneycombFormInput) => {
    setLoading(true)

    const texts = await syncContentTexts(content, submittedData.texts)

    for (const key of ['foreground', 'background'] as const) {
      if (submittedData[key] && !isGraphQlFile(submittedData[key])) {
        await uploadFile({
          variables: {
            file: submittedData[key],
            data: {
              key,
              model: 'Content',
              modelId: content.id,
              replace: true
            }
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(content),
              fields: {
                files (existingRefs = [], { readField }) {
                  if (!data?.uploadFile) {
                    return existingRefs
                  }

                  const newFileRef = cache.writeFragment({
                    data: data.uploadFile,
                    fragment: FileDataFragmentDoc,
                    fragmentName: 'FileData'
                  })

                  const prev = existingRefs.filter((ref: any) => data.uploadFile.id !== readField('id', ref))

                  return [...prev, newFileRef]
                }
              }
            })
          }
        })
      }
    }

    try {
      await updateContent(content.id, {
        nuggetId,
        texts,
        order: content.order,
        config: submittedData.config
      })
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflowY: 'hidden'
        }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <ExerciseTextEditor />

          <Box display="flex" flexDirection="row" gap={2}>
            <Box>
              <Chip label={t('common.background')} size="small" sx={{ marginBottom: '8px' }} />

              <Controller
                name="background"
                control={methods.control}
                render={({ field: { value, onChange } }) => (
                  <FileDragDrop
                    initialFile={isGraphQlFile(value) ? value : undefined}
                    onFileChanged={onChange}
                    preview
                    accept={{ 'image/*': ['.png', '.jpeg', '.jpg', '.webp'] }}
                    sx={{ width: 300, height: 200 }}
                  />
                )}
              />
            </Box>

            <Box>
              <Chip label={t('common.foreground')} size="small" sx={{ marginBottom: '8px' }} />
              <Controller
                name="foreground"
                control={methods.control}
                render={({ field: { value, onChange } }) => (
                  <FileDragDrop
                    initialFile={isGraphQlFile(value) ? value : undefined}
                    onFileChanged={onChange}
                    preview
                    accept={{ 'image/*': ['.png', '.jpeg', '.jpg', '.webp'] }}
                    sx={{ width: 300, height: 200 }}
                  />
                )}
              />
            </Box>
          </Box>
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading}
          uploadError={uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>
  )
}
