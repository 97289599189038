import React from 'react'
import {
  SvgIconComponent,
  Edit,
  Payment,
  Visibility,
  OpenInNew,
  QrCode2,
  ExpandMore,
  Close
} from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'

export type ThemeIcons = Record<string, SvgIconComponent | React.FC<SvgIconProps>>

export const customThemeIcons = {
  // If doyo ever has custom Icons put it in here, to overwrite the default ones
}

export const themeIcons = {
  Edit,
  Payment,
  Visibility,
  OpenInNew,
  QrCode2,
  ExpandMore,
  Close,
  ...customThemeIcons
}
