import React, { useEffect, useMemo } from 'react'
import {
  Breadcrumbs, Typography, BreadcrumbsProps
} from '@mui/material'
import { useMatches, useNavigate, useParams } from 'react-router'
import { useTourFullQuery, useTourPoiQuery, useTourQuery } from '@typings/graphql'
import { useTranslation } from 'react-i18next'

type IsCreateProps ={
  isCreate?: boolean
}

export const AllBreadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const matches = useMatches()
  const matchesWithBreadcrumbs = useMemo(() => matches.filter((match) => Boolean((match.handle as any)?.breadcrumb)), [matches])
  const navigate = useNavigate()

  return (
    <Breadcrumbs
      separator="›"
      sx={{ padding: '32px', paddingY: '16px' }}
      {...props}
    >
      {matchesWithBreadcrumbs.map((match, index) => {
        const currentRoute = index === matches.length - 1
        const { breadcrumb, ...data } = match.handle as any

        const component = typeof breadcrumb === 'function' ? React.createElement(breadcrumb, data) : breadcrumb

        return (
          <Typography
            key={match.pathname}
            sx={{
              pointerEvents: currentRoute ? 'none' : 'all',
              cursor: 'pointer',
              '&:hover': { color: 'secondary.main' }
            }}
            color={currentRoute ? 'text.primary' : 'currentColor'}
            variant="subtitle1"
            onClick={match.pathname === '/tour' ? () => navigate('/tours') : undefined }
          >
            {component}
          </Typography>
        )
      })}
    </Breadcrumbs>
  )
}

export const TourBreadcrumb: React.FC<IsCreateProps> = ({
  isCreate = false
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  const { data } = useTourQuery({ variables: { id: id as string } })

  if (isCreate) {
    return <Typography>{t('edit.tour.create')}</Typography>
  }

  return <Typography onClick={() => navigate(`/tour/${id}`)}>
    {data?.tour.title}
  </Typography>
}

export const ModeBreadcrumb: React.FC<IsCreateProps> = ({
  isCreate = false
}) => {
  const { t } = useTranslation()
  const { id, modeId } = useParams()
  const navigate = useNavigate()

  const { data } = useTourFullQuery({ variables: { id: id as string, modeId: modeId as string }, skip: !modeId })

  if (isCreate) {
    return <Typography>{t('edit.mode.create')}</Typography>
  }

  return <Typography onClick={() => navigate(`/tour/${id}/mode/${modeId}`)}>
    {data?.tour.mode.title}
  </Typography>
}

export const PoiBreadcrumb: React.FC<IsCreateProps> = () => {
  const { id, modeId, poiId } = useParams()
  const navigate = useNavigate()

  const { data } = useTourPoiQuery({
    variables: {
      id: poiId as string
    }
  })

  return <Typography onClick={() => navigate(`/tour/${id}/mode/${modeId}/poi/${poiId}`)}>
    {data?.tourPoi.title}
  </Typography>
}

export const SectionBreadcrumb: React.FC<IsCreateProps> = () => {
  const { id, modeId, poiId, sectionId } = useParams()
  const navigate = useNavigate()

  const { data, refetch } = useTourPoiQuery({
    variables: {
      id: poiId as string
    }
  })

  useEffect(() => {
    refetch()
  }, [sectionId])

  const section = useMemo(() => data?.tourPoi.sections.find((s) => s.id === sectionId), [data, sectionId])

  return <Typography onClick={() => navigate(`/tour/${id}/mode/${modeId}/poi/${poiId}/section/${sectionId}`)}>
    {section?.title}
  </Typography>
}

export const NuggetBreadcrumb: React.FC<IsCreateProps> = ({
  isCreate = false
}) => {
  const { id, modeId, poiId, sectionId, nuggetId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data, refetch } = useTourPoiQuery({
    variables: {
      id: poiId as string
    }
  })

  useEffect(() => {
    refetch()
  }, [sectionId])

  if (isCreate) {
    return <Typography>{t('edit.poi.addNugget')}</Typography>
  }

  const nugget = data?.tourPoi.sections.find((s) => s.id === sectionId)?.nuggets.find((n) => n.id === nuggetId)

  return <Typography onClick={() => navigate(`/tour/${id}/mode/${modeId}/poi/${poiId}/section/${sectionId}/nugget/${nuggetId}`)}>
    {nugget?.texts.title}
  </Typography>
}
