import { useMemo } from 'react'
import { matchRoutes, useLocation } from 'react-router'
import { routes } from '@services/router'

export const useMatchedRoutes = () => {
  const location = useLocation()

  const matched = useMemo(() => matchRoutes(routes, location), [location])

  return matched
}
