import { t } from 'i18next'

import { PaginatedBookingColumnType } from '../VoucherDataGrid'

export const usagesColumnModel: PaginatedBookingColumnType = {
  field: 'usagesLeft',
  width: 125,
  filterable: false,
  headerName: t('payment.usages'),
  renderCell: (params) => t('payment.usagesCombined', { redeemed: params.row.usages - params.row.usagesLeft, usages: params.row.usages }) as string
}
