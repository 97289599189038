import { Resource } from 'i18next'

import de from '../../locales/de/translation.json'

const loadLocales = () => {
  return {
    de: {
      translation: de
    } as Resource
  }
}

export const locales = loadLocales()
