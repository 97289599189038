import React from 'react'
import * as ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import CoreProvider from '@providers/CoreProvider'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'
import { LicenseInfo } from '@mui/x-license-pro'

import './index.css'

LicenseInfo.setLicenseKey(import.meta.env.REACT_APP_MUI_LICENSE_TOKEN)

Sentry.init({
  enabled: import.meta.env.MODE !== 'development',

  environment: import.meta.env.MODE,

  dsn: 'https://0a57dba46a1a44b5b59495c0e7d9aa9f@sentry.kaleidoscode.de/3',
  integrations: [
    new Sentry.Replay(),
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

const container = document.getElementById('root')

ReactDOM.createRoot(container as HTMLElement).render(
  <CoreProvider />
)
