export const miniGameBugRaceConfigSchema = {
  type: 'object',
  properties: {
    loop: {
      type: 'array',
      items: [
        {
          type: 'number'
        },
        {
          type: 'number'
        }
      ]
    },
    startSpeed: {
      type: 'number'
    },
    backgroundColor: {
      type: 'string'
    },
    incrementFactor: {
      type: 'number'
    },
    voiceoverMarker: {
      type: 'object'
    },
    incrementInterval: {
      type: 'number'
    }
  },
  required: [
    'loop',
    'startSpeed',
    'backgroundColor',
    'incrementFactor',
    'voiceoverMarker',
    'incrementInterval'
  ]
}
