import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Button, Chip, IconButton, TextField, Typography } from '@mui/material'
import { FileDataFragmentDoc, UpdateContentInput, useUploadFileMutation } from '@typings/graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentFile, isGraphQlFile } from '../mutation-helper/file'

import { ExerciseTextEditor } from './components/ExerciseTextEditor'

import type { ContentEditorComponentProps } from '.'

type UpdateImageZoomContentFormInput = {
  texts: {
    exercise: string,
    solution?: string
  }
  items: ImageZoomItem[]
}

type ImageZoomItem = {
  level: number
  file?: ContentFile | File | null
}

export const ImageZoomContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile, { loading: isUploading, error }] = useUploadFileMutation()

  const defaultValues = useMemo<UpdateImageZoomContentFormInput>(() => {
    const levels = content.config.levels ?? {}
    const sortedFiles = content.files.filter((file) => file.key === 'image').sort((a, b) => ((levels[a.id] ?? 0) - (levels[b.id] ?? 0)))

    return {
      texts: content.texts,
      items: sortedFiles.length
        ? sortedFiles.map((file) => ({
          level: content.config.levels?.[file.id] ?? 1,
          file
        }))
        : [{ level: 1 }]
    }
  }, [content])

  const methods = useForm<UpdateImageZoomContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'items'
  })

  const appendItem = () => {
    const items = methods.getValues('items')
    const levels = items.map((item) => item.level).sort((a, b) => (a - b))
    const lastIndex = levels.length ? levels.pop() as number : 0

    append({ level: lastIndex + 1, file: null })
  }

  const canSave = useMemo(() => methods.formState.isValid && methods.formState.isDirty, [methods.formState.isDirty, methods.formState.isValid])

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = async (submittedData: UpdateImageZoomContentFormInput) => {
    setLoading(true)

    const translatableTexts = {
      ...submittedData.texts
    }

    const texts = await syncContentTexts(content, translatableTexts)

    const files = submittedData.items
      .filter((item) => item.file && !(item.file as any).id)
      .map((item) => ({
        key: 'image',
        file: item.file as File
      }))

    if (files.length) {
      try {
        await Promise.all(files.map(async ({ key, file }) => {
          await uploadFile({
            variables: {
              file,
              data: {
                key,
                model: 'Content',
                modelId: content.id
              }
            },
            update: (cache, { data }) => {
              submittedData.items.forEach((item) => {
                if (item.file === file) {
                  item.file = data?.uploadFile
                }
              })

              cache.modify({
                id: cache.identify({
                  __typename: 'Content',
                  id: content.id
                }),
                fields: {
                  files (existingFiles = []) {
                    const newFileRef = cache.writeFragment({
                      data: data?.uploadFile,
                      fragment: FileDataFragmentDoc,
                      fragmentName: 'FileData'
                    })

                    return [...existingFiles, newFileRef]
                  }
                }
              })
            }
          })
        }))
      } catch (e) {
        setUploadError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      texts,
      order: content.order,
      config: {
        levels: submittedData.items.reduce((acc, item) => {
          if (item.file) {
            acc[isGraphQlFile(item.file) ? item.file.id : ''] = item.level
          }

          return acc
        }, {} as Record<string, number>)
      }
    }

    try {
      await updateContent(content.id, data)
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  const textFields = useMemo(() => {
    return fields.map((field, index) => (
      <Box key={field.id} display="flex" alignItems="center" gap={2}>

        <TextField
          type="number"
          sx={{ width: 100 }}
          {...methods.register(`items.${index}.level`, { required: true, valueAsNumber: true })}
        />
        <Controller name={`items.${index}.file`}
          control={methods.control}
          render={({ field: { value, onChange } }) => {
            return <FileDragDrop
              accept={{ 'image/*': ['.png', '.webp', '.jpg', '.jpeg'] }}
              alignItems="stretch"
              alignSelf="unset"
              width={200}
              maxHeight={200}
              preview
              initialFile={isGraphQlFile(value) ? value : undefined}
              onFileChanged={onChange}
            >
              <Typography>{ !value ? t('edit.poi.dragImage') : (value as any).fileName }</Typography>
            </FileDragDrop>
          }}
        />
        <IconButton
          sx={{ ml: 2, alignSelf: 'center' }}
          onClick={() => remove(index)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
    ))
  }, [fields])

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <ExerciseTextEditor />
          <Box>
            <Chip label={t('common.texts')} size="small" sx={{ marginBottom: '8px' }} />

            <Stack spacing={2}>
              {textFields}

              <Button
                variant="outlined"
                sx={{ alignSelf: 'flex-start' }}
                onClick={appendItem}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Stack>
          </Box>
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading || isUploading}
          uploadError={error || uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
