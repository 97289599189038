import { getGridStringOperators } from '@mui/x-data-grid-pro'

import { PaginatedBookingColumnType } from '../PaymentHistoryDataGrid'

export const codeColumnModel: PaginatedBookingColumnType = {
  field: 'code',
  width: 115,
  hideable: false,
  filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains' || operator.value === 'equals')
}
