import React from 'react'
import { Box } from '@mui/material'
import BGImage from '@assets/images/dotted-background.png'
import { Outlet } from 'react-router'

import { NavBar } from '../ui/NavBar'

export const DashBoardLayout: React.FC = () => {
  return (
    <>
      <NavBar />
      <Box flex={1}
        overflow="hidden"
        sx={{
          backgroundColor: '#D9D9D9'
        }}
      >
        <Box sx={{
          pointerEvents: 'none',
          backgroundImage: `url(${BGImage})`,
          backgroundSize: '500px 500px',
          backgroundRepeat: 'repeat',
          backgroundPosition: 'center',
          height: '100%',
          width: '100%',
          opacity: 0.1,
          position: 'absolute',
          top: 0,
          left: 0
        }}
        />
        <Outlet />
      </Box>
    </>
  )
}
