import { Box, Chip, Divider, TextareaAutosize, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
/* import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline' */
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { JsonEditor } from '@features/cms/components/JsonEditor'
import { UpdateContentInput } from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { deleteTexts } from '../mutation-helper/text'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { updateContent } from '../mutation-helper/content'

import { ContentEditorComponentProps } from '.'

type UpdateCustomContentFormInput = {
  texts: {
    [key: string]: string
  },
  partials: string,
  blockedById: string | null,
  style: string,
  config: string
}

export const CustomContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const getDefaultValues = () => {
    return {
      texts: {
        accessibilityText: content?.texts.accessibilityText || ''
      },
      partials: JSON.stringify(content.partials, null, '\t') || '',
      blockedById: content.blockedById || null,
      config: JSON.stringify(content.config, null, '\t') || '',
      style: JSON.stringify(content.style, null, '\t') || ''
    }
  }

  const methods = useForm<UpdateCustomContentFormInput>({
    defaultValues: getDefaultValues(),
    mode: 'onChange'
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const onSubmit = methods.handleSubmit(async (submittedData: UpdateCustomContentFormInput) => {
    setLoading(true)

    const deletedTextKeys = Object.keys(submittedData.texts).filter(key => !submittedData.texts[key])

    if (deletedTextKeys.length) {
      const deletedTextsVariables = deletedTextKeys.map(key => ({
        key,
        model: 'Content',
        modelId: content.id
      }))

      try {
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      /* partials: JSON.parse(submittedData.partials), */
      blockedById: submittedData.blockedById || null,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style),
      texts: Object.keys(submittedData.texts)
        .filter((key) => !!submittedData.texts[key])
        .map((key) => (convertToTextObject(key, submittedData.texts[key])))
    }

    try {
      await updateContent(content.id, data)
      methods.reset()
    } catch (e) {
      setError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch && refetch()
  })

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setError(undefined)
  }

  return <FormProvider {...methods} >
    <form onSubmit={onSubmit}>
      <Box>
        <TextField
          sx={{ marginBottom: '40px', width: '500px' }}
          label={t('edit.content.blockedBy')}
          {...methods.register('blockedById')}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Chip label={t('edit.content.partials')} size="small" sx={{ marginBottom: '12px' }} />
      <JsonEditor
        name="partials"
        style={{ marginBottom: '60px', boxShadow: '0 0 0 1px #CFD7DD, 0 0 0 #CFD7DD, 0 1px 1px #CFD7DD' }}
      />
      <Divider />
      <Box display="flex" flexDirection="column" mt={4}>
        <Box display="block">
          <Chip label="accessibilityText" size="small" sx={{ marginBottom: '8px' }} />
        </Box>
        <TextareaAutosize
          minRows={3}
          {...methods.register('texts.accessibilityText')}
          style={{ width: 600, marginBottom: '60px' }}
        />
      </Box>
      <Divider />
      <ConfigAndStyleEditor content={content} />
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
