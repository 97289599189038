import {
  Box,
  CircularProgress,
  Modal,
  ModalProps,
  Paper,
  Typography
} from '@mui/material'
import { useBookingQuery } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { can } from '@utils/permissions'
import { timestampToDate } from '@utils/date'
import { getCurrency } from '@utils/currency'

import { PAYMENT_STATUS_COLORS } from '../constants/constants'

import { PaymentHistoryDetailsRow } from './PaymentHistoryDetailsRow'
import { PaymentChargeInformation } from './PaymentChargeInformation'

type Props = Omit<ModalProps, 'children'> & {
  code: string
}

type ChargeInformation = {
  orderId: string
  [key: string]: string
}

export const PaymentHistoryDetailsModal: React.FC<Props> = ({ code, sx, ...props }) => {
  const { t } = useTranslation()

  const { data, loading } = useBookingQuery({
    variables: {
      code
    }
  })

  const charge = React.useMemo<ChargeInformation>(() => {
    try {
      const dataParsed = JSON.parse(data?.booking?.payment?.chargeId || '')

      return {
        orderId: dataParsed?.orderId || '',
        ...dataParsed
      }
    } catch (e) {
      return {
        orderId: data?.booking?.payment?.chargeId || ''
      }
    }
  }, [data])

  return (
    <Modal {...props}
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Paper sx={{
        p: 4,
        width: '50%',
        height: '70%',
        boxShadow: 'none',
        overflow: 'auto'
      }}
      >
        {!data?.booking
          ? (
            <Box sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            > {loading
              ? <CircularProgress />
              : <Typography>{t('payment.noData')}</Typography>
            }
            </Box>
            )
          : (<Box>
            <Box>
              <Typography variant="h4" mb={2}>{t('payment.details')}</Typography>
              <PaymentHistoryDetailsRow propKey={t('payment.code')}>{data.booking.code}</PaymentHistoryDetailsRow>
              <PaymentHistoryDetailsRow propKey={t('payment.createdAt')}>{timestampToDate(data.booking.createdAt)}</PaymentHistoryDetailsRow>
              <PaymentHistoryDetailsRow propKey={t('payment.usages')}>{data.booking.usages}</PaymentHistoryDetailsRow>
              <PaymentHistoryDetailsRow propKey={t('payment.usagesLeft')}>{data.booking.usagesLeft}</PaymentHistoryDetailsRow>
              <PaymentHistoryDetailsRow propKey={t('payment.paymentMethodType.coupon')}>
                {t(`common.${!data.booking.payment ? 'yes' : 'no'}`)}
              </PaymentHistoryDetailsRow>
              {data.booking.payment && <Box>
                <Typography variant="h6" mt={2}>{t('payment.payment')}</Typography>
                  {can('scope:admin') && <PaymentHistoryDetailsRow propKey={t('payment.id')}>{data.booking.payment.id}</PaymentHistoryDetailsRow>}
                  {can('scope:admin') &&
                    <PaymentHistoryDetailsRow propKey={t('payment.name')}>
                      {data.booking.payment.name || '-'}
                    </PaymentHistoryDetailsRow>
                  }
                  {can('scope:admin') &&
                    <PaymentHistoryDetailsRow propKey={t('payment.email')}>
                      {data.booking.payment.email}
                    </PaymentHistoryDetailsRow>
                  }
                <PaymentHistoryDetailsRow propKey={t('payment.chargeId')}>{charge.orderId}</PaymentHistoryDetailsRow>
                <PaymentHistoryDetailsRow propKey={t('payment.paymentMethod')}>
                  {t(`payment.paymentMethodType.${data.booking.payment.paymentMethod}`)}
                </PaymentHistoryDetailsRow>
                <PaymentHistoryDetailsRow propKey={t('payment.status')}>
                  <Box color={`${PAYMENT_STATUS_COLORS[data.booking.payment.status]}.main`}>
                    {t(`payment.statusTypes.${data.booking.payment.status.toLocaleLowerCase()}`)}
                  </Box>
                </PaymentHistoryDetailsRow>
                <PaymentHistoryDetailsRow propKey={t('payment.amount')}>{
                  t('payment.amountCurrency', {
                    amount: data?.booking?.payment?.amount ? (data.booking?.payment?.amount.toFixed(2)).toString().replace('.', ',') : 0,
                    currency: getCurrency(data.booking.payment.currency)
                  })
                }
                </PaymentHistoryDetailsRow>
                </Box>
              }
              {
                Object.keys(charge).length > 1 &&
                  <PaymentChargeInformation data={charge} />
              }
            </Box>
          </Box>)
        }
      </Paper>
    </Modal>
  )
}
