export const pollConfigSchema = {
  type: 'object',
  properties: {
    options: {
      type: 'array',
      description: 'Keys der Antwortoptionen => "answer_[key]"',
      items: {
        type: 'string'
      }
    },
    answer: {
      type: 'string',
      description: 'Key der richtigen Antwortoption => "answer_[key]"'
    }
  },
  required: ['options']
}
