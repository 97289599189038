import { ContentDataFragment } from '@typings/graphql'

import { BalanceCheckContentEditor } from './BalanceCheckContentEditor'

export type ContentEditorComponentProps = {
  nuggetId: string,
  content: ContentDataFragment,
  refetch?: Function,
}

export const contentTypeEditors = {
  balanceCheck: BalanceCheckContentEditor
}

export default contentTypeEditors
