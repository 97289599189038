import { BookingFilterModels, BookingOrderField, FilterOperators, OrderDirection } from '@typings/graphql'

export const VOUCHER_GRID_PAGE_SIZE = 10

export const VOUCHER_GRID_BASE_ORDER_FILTER = {
  orderBy: {
    field: BookingOrderField.CreatedAt,
    direction: OrderDirection.Desc
  },
  filter: [{
    field: 'payment',
    operator: FilterOperators.Is,
    value: null,
    model: BookingFilterModels.Booking
  }, {
    field: 'usagesLeft',
    operator: FilterOperators.Gt,
    value: 0,
    model: BookingFilterModels.Booking
  }]
}
