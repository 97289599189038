import { LoadingButton } from '@mui/lab'
import { Alert, Box, Snackbar } from '@mui/material'
import React, { PropsWithChildren, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

type Props = PropsWithChildren<{
  disabled?: boolean,
  loading?: boolean,
  disableSnackbar?: boolean,
  showSnackbar: boolean,
  uploadError: any,
  closeSnackbar: Function
}>

export const FormFooterBar: React.FC<Props> = ({
  children,
  disabled,
  loading,
  disableSnackbar,
  showSnackbar,
  uploadError,
  closeSnackbar
}) => {
  const { t } = useTranslation()
  const saveButtonRef = useRef<HTMLButtonElement>(null)

  const onKeyDown = useCallback((e: KeyboardEvent) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 's') {
      e.preventDefault()
      saveButtonRef.current?.click()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return (<>
    <Box
      bottom="0"
      left="0"
      display="flex"
      justifyContent="flex-end"
      flex={0}
      p={4}
      pt={2}
    >
      {children}
      <Box
        flex={1}
        display="flex"
        justifyContent="flex-end"
      >
        <LoadingButton
          ref={saveButtonRef}
          variant="contained"
          disabled={disabled}
          loading={loading}
          sx={{
            alignSelf: 'right'
          }}
          color="primary"
          type="submit"
        >
          {t('tours.save')}
        </LoadingButton>
      </Box>
    </Box>
    {!disableSnackbar && <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={showSnackbar}
      autoHideDuration={6000}
      onClose={() => closeSnackbar()}
    >
      <Alert
        onClose={() => closeSnackbar()}
        severity={uploadError ? 'error' : 'success'}
        sx={{ width: '100%' }}
      >
        {uploadError?.message || uploadError || t('edit.poi.updateSuccess')}
      </Alert>
    </Snackbar>}
  </>)
}
