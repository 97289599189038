import { Box, Button, Dialog, DialogActions, DialogContent, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentTypeEditors } from '@features/cms/components/content-type-editors'
import { ContentDataFragmentDoc, useCreateContentMutation, useTourModeQuery } from '@typings/graphql'
import { LoadingButton } from '@mui/lab'

type Props = {
  modeId: string
  nuggetId: string
  onClose: () => void
  onCreated: (id: string) => void
  show: boolean
}

export const CreateContentDialog: React.FC<Props> = ({ modeId, nuggetId, onClose, onCreated, show }) => {
  const { t } = useTranslation()
  const { data: modeData } = useTourModeQuery({ variables: { id: modeId as string } })
  const [selectedContentType, setSelectedContentType] = useState<string>('')

  const [createContent, { loading }] = useCreateContentMutation()

  const menuItems = useMemo(() => {
    if (!modeData) return null

    const types = ContentTypeEditors[modeData.tourMode.type as keyof typeof ContentTypeEditors] ?? ContentTypeEditors.default
    const keys = Object.keys(types)
    keys.sort()

    return keys.map((ct) => <MenuItem key={ct} value={ct}>{ct}</MenuItem>)
  }, [ContentTypeEditors, modeData])

  const onCreate = async () => {
    try {
      const { data } = await createContent({
        variables: {
          data: {
            nuggetId,
            type: selectedContentType!,
            texts: []
          }
        },
        update: (cache, { data: newContent }) => {
          if (!newContent) return

          cache.modify({
            id: cache.identify({
              __typename: 'ContentNugget',
              id: nuggetId
            }),
            fields: {
              content (existingRefs = []) {
                const ref = cache.writeFragment({
                  data: newContent.createContent,
                  fragment: ContentDataFragmentDoc,
                  fragmentName: 'ContentData'
                })

                return [...existingRefs, ref]
              }
            }
          })
        }
      })

      if (!data) return

      onCreated(data?.createContent.id)
    } catch (e) {
      console.log(e)
    }
  }

  return <Dialog open={show} onClose={onClose} sx={{ width: '100%', maxHeight: '90vh' }}>
    <DialogContent>
      <Box p={2} pb={0}>
        <Typography>{t('edit.poi.selectContentType')}</Typography>
        <Box mt={2}>
          <InputLabel id="status-label">{t('edit.content.type')}</InputLabel>
          <Select
            sx={{ width: '100%' }}
            labelId="status-label"
            value={selectedContentType}
            label="Age"
            onChange={v => setSelectedContentType(v.target.value)}
          >
            {menuItems}
          </Select>
        </Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button color='secondary' onClick={onClose}>
        {t('common.close')}
      </Button>
      <LoadingButton
        color="primary"
        onClick={onCreate}
        loading={loading}
        disabled={!selectedContentType}
      >
        {t('edit.content.create')}
      </LoadingButton>
    </DialogActions>
  </Dialog>
}
