import { fromPromise } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { store } from '@services/store'
import { resetAuthData, setAuthToken } from '@services/store/slices/auth'
import {
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables
} from '@typings/graphql'

import { apolloClient } from '.'

let isRefreshing = false
let pendingRequests: (() => void)[] = []

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

const getNewToken = async () => {
  const response = await apolloClient.mutate<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >({
    mutation: RefreshTokenDocument,
    variables: {
      token: store.getState().auth.refreshToken
    }
  })

  if (!response.data) {
    throw response.errors?.[0]
  }
  return response.data.refreshToken
}

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions.code === 'UNAUTHENTICATED') {
          let forward$

          if (operation.operationName === 'refreshToken') {
            // Highly unlikely that this will happen
            // Caused by reseed of database
            store.dispatch(resetAuthData())
            return forward(operation)
          }

          if (!isRefreshing) {
            isRefreshing = true
            forward$ = fromPromise(
              getNewToken()
                .then(({ accessToken, refreshToken }) => {
                  store.dispatch(setAuthToken({ accessToken, refreshToken }))
                  return true
                })
                .then(() => {
                  resolvePendingRequests()
                  return true
                })
                .catch(() => {
                  pendingRequests = []
                  store.dispatch(resetAuthData())
                  return false
                })
                .finally(() => {
                  isRefreshing = false
                })
            ).filter(value => Boolean(value))
          } else {
            // Will only emit once the Promise is resolved
            forward$ = fromPromise(
              new Promise<void>(resolve => {
                pendingRequests.push(() => resolve())
              })
            )
          }

          return forward$.flatMap(() => forward(operation))
        } else {
          console.log(
            `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
          )
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }
  }
)
