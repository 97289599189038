import { Box } from '@mui/material'
import React from 'react'
import { ContentDataFragment } from '@typings/graphql'

import { ConfigEditor } from './ConfigEditor'
import { StyleEditor } from './StyleEditor'

type Props = {
  content: ContentDataFragment,
  schema?: Record<string, any>
}

export const ConfigAndStyleEditor: React.FC<Props> = ({ content, schema }) => {
  return <Box display="flex">
    <Box flex={1}>
      <ConfigEditor schema={schema}/>
    </Box>
    <Box flex={1} ml={2}>
      <StyleEditor content={content} />
    </Box>
  </Box>
}
