export const videoQuizConfigSchema = {
  type: 'object',
  properties: {
    answers: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          type: { type: 'string', enum: ['poll'] },
          answer: { type: 'integer' },
          count: { type: 'integer' }
        }
      },
      minItems: 2
    }
  },
  required: ['answers']
}
