import {
  ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme
} from '@mui/material'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'

export type MuiDataGridType = {
  defaultProps?: ComponentsProps['MuiDataGrid'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDataGrid'];
  variants?: ComponentsVariants['MuiDataGrid'];
}

export const MuiDataGrid:MuiDataGridType = {
  styleOverrides: {
    root: {
      padding: '8px',
      backgroundColor: 'white',
      '.MuiDataGrid-columnHeaders': {
        '.MuiDataGrid-columnSeparator': {
          display: 'none'
        },
        '.MuiDataGrid-columnHeader': {
          border: 'none',
          '&:focus, &:focus-within': {
            outline: 'none'
          }
        }
      },
      '.MuiDataGrid-cell ': {
        border: 'none',
        '&:focus, &:focus-within': {
          outline: 'none'
        }
      }
    }
  }
}
