import { VOUCHER_GRID_BASE_ORDER_FILTER, VOUCHER_GRID_PAGE_SIZE } from '@features/voucher/constants/constants'
import { useVoucherContext } from '@features/voucher/provider/VoucherProvider'
import { LoadingButton } from '@mui/lab'
import { Box, Button, FormControl, FormHelperText, Input, Modal, ModalProps, Paper, Typography } from '@mui/material'
import { TourPaginatedBookingsDocument, useCreateBookingsMutation } from '@typings/graphql'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = Omit<ModalProps, 'children'> & {
  onClose: () => void
  onCreated: (url: string) => void
}

const MAX_GENERATION_COUNT = 100

type VoucherGenerationInput = {
  usages: number
  bookingCount: number
}

export const CreateVoucherModal: React.FC<Props> = ({ onClose, onCreated, ...props }) => {
  const { t } = useTranslation()

  const { selectedTour } = useVoucherContext()

  const { reset, register, watch, formState: { isValid }, handleSubmit } = useForm<VoucherGenerationInput>({
    mode: 'onChange',
    defaultValues: {
      usages: 1,
      bookingCount: 1
    }
  })

  const [createBookings, { loading }] = useCreateBookingsMutation({
    refetchQueries: [
      {
        query: TourPaginatedBookingsDocument,
        variables: {
          id: selectedTour?.id,
          first: VOUCHER_GRID_PAGE_SIZE,
          ...VOUCHER_GRID_BASE_ORDER_FILTER
        }
      }
    ]
  })

  React.useEffect(() => {
    reset({
      usages: 1,
      bookingCount: 1
    })
  }, [selectedTour])

  const onSubmit = handleSubmit(async (input) => {
    if (!selectedTour) {
      return
    }

    const { data } = await createBookings({
      variables: {
        tourId: selectedTour.id,
        ...input
      }
    })

    if (data) {
      onCreated(data.createBookings.url)
    }

    onClose()
  })

  return (
    <Modal
      {...props}
      onClose={() => onClose()}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Paper sx={{
        p: 4,
        width: '40%',
        boxShadow: 'none',
        overflow: 'auto'
      }}
      >
        <Box sx={{
          mb: 2,
          flex: 0
        }}
        >
          <Typography variant="h5">
            {t('voucher.createVoucher')}
          </Typography>
        </Box>

        <Box sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
        >
          <Box sx={{ width: '45%' }}>
            <Typography>
              {t('voucher.bookingCount')}
            </Typography>

            <FormControl
              error={watch('bookingCount') > MAX_GENERATION_COUNT}
              variant="standard"
              fullWidth
            >
              <Input
                fullWidth
                type='number'
                {...register('bookingCount', { required: true, max: MAX_GENERATION_COUNT, valueAsNumber: true })}
              />
              <FormHelperText sx={{ opacity: watch('bookingCount') > MAX_GENERATION_COUNT ? 1 : 0 }}>
                {t('voucher.maxUsages', { count: MAX_GENERATION_COUNT })}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box sx={{ width: '45%' }}>
            <Typography>
              {t('voucher.usages')}
            </Typography>

            <FormControl
              error={watch('usages') > MAX_GENERATION_COUNT}
              variant="standard"
              fullWidth
            >
              <Input
                fullWidth
                type='number'
                {...register('usages', { required: true, max: MAX_GENERATION_COUNT, valueAsNumber: true })}
              />
              <FormHelperText sx={{ opacity: watch('usages') > MAX_GENERATION_COUNT ? 1 : 0 }}>
                {t('voucher.maxUsages', { count: MAX_GENERATION_COUNT })}
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>

        <Box sx={{
          flex: 0,
          mt: 2,
          display: 'flex',
          flexDirection: 'row'
        }}
        >
          <Button
            variant="contained"
            onClick={() => onClose()}
          >
            {t('common.cancel')}
          </Button>
          <LoadingButton sx={{ ml: 2 }}
            variant="contained"
            disabled={!isValid}
            type='submit'
            onClick={onSubmit}
            loading={loading}
          >
            {t('common.save')}
          </LoadingButton>
        </Box>
      </Paper>
    </Modal>
  )
}
