export const carouselConfigSchema = {
  type: 'object',
  properties: {
    showPagination: { type: 'boolean' },
    fullscreen: { type: 'boolean' },
    cardColor: { type: 'string' },
    textColor: { type: 'string' },
    paginationColor: { type: 'string' },
    imageAspectRatio: { type: 'number' },
    foregroundImage: { type: 'boolean' },
    backgroundImage: { type: 'boolean' }
  }
}
