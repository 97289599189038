import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { UpdateContentInput } from '@typings/graphql'
import { Box, Chip, Stack, TextareaAutosize, TextField, Typography } from '@mui/material'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { VideoPlayer } from '@features/cms/components/VideoPlayer'
import { convertToTextObject } from '@utils/texts'

import { updateContent } from '../mutation-helper/content'
import { ContentFile, uploadFile } from '../mutation-helper/file'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { deleteTexts } from '../mutation-helper/text'
import { MediaInfos } from '../components/MediaInfos'
import { videoConfigSchema } from '../config-schemas/default/video'

import { ContentEditorComponentProps } from '.'

const textKeys = ['title', 'buttonText', 'accessibilityText', 'accessibilityAnnouncement'] as const

type UpdateImageContentFormInput = {
  video: ContentFile | File | undefined,
  blockedById: string | null,
  style: string,
  config: string,
  texts: Record<typeof textKeys[number], string>
}

export const VideoContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [uploadError, setUploadError] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const getDefaultValues = () => {
    return {
      video: content.files[0],
      blockedById: content.blockedById || null,
      style: JSON.stringify(content.style, null, '\t') || '',
      config: JSON.stringify(content.config, null, '\t') || '',
      texts: content.texts
    }
  }

  const methods = useForm<UpdateImageContentFormInput>({
    defaultValues: getDefaultValues()
  })

  const addVideo = (file: File) => {
    methods.setValue('video', file, { shouldDirty: true })
  }

  const onSubmit = async (submittedData: UpdateImageContentFormInput) => {
    setLoading(true)

    const translatableTexts = textKeys
      .filter((key) => !!submittedData.texts[key])
      .map((key) => convertToTextObject(key, submittedData.texts[key]))

    const deletedTextsVariables = textKeys
      .filter((key) => !submittedData.texts[key] && content.texts[key])
      .map((key) => ({
        key,
        model: 'Content',
        modelId: content.id
      }))

    if (!!submittedData.video && !(submittedData.video as any)?.id) {
      try {
        await uploadFile({
          file: submittedData.video,
          data: {
            key: 'video',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    if (methods.formState.isDirty) {
      const data: UpdateContentInput = {
        nuggetId,
        order: content.order,
        type: content.type,
        blockedById: submittedData.blockedById || null,
        style: JSON.parse(submittedData.style),
        config: JSON.parse(submittedData.config),
        texts: translatableTexts
      }

      try {
        await updateContent(content.id, data)
      } catch (e) {
        setUploadError(e)
      }

      if (deletedTextsVariables.length) {
        try {
          await deleteTexts(deletedTextsVariables)
        } catch (e) {
          setUploadError(e)
        }
      }

      setLoading(false)
    }

    setShowSnackbar(true)
    refetch && refetch()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const video = useWatch({
    control: methods.control,
    name: 'video'
  })

  useEffect(() => {
    onEdited?.((!!video && !(video as any)?.id) || methods.formState.isDirty)
  }, [video, methods.formState.isDirty])

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box>
          <Chip label={t('edit.content.video')} size="small" sx={{ marginBottom: '8px' }} />
          <Stack direction="row" spacing={2} alignItems="center">
            <FileDragDrop
              flex={1}
              accept={{ 'video/*': ['.mp4'] }}
              height={300}
              preview
              onFileChanged={addVideo}
            >
              <Stack>
                <Typography>{!content?.files.length ? t('edit.poi.dragVideo') : content.files[0].fileName}</Typography>
                <MediaInfos file={ content.files[0] }></MediaInfos>
              </Stack>
            </FileDragDrop>

            <Box flex={1} mt={2}>
              {video && <VideoPlayer
                width="100%"
                height="300px"
                url={(video as any).id
                  ? (video as ContentFile).url
                  : URL.createObjectURL(video as File)}
                controls
              />}
            </Box>

          </Stack>
        </Box>
        <Stack direction="row" spacing={2}>
          <Box flex={1}>
            <Chip label={t('common.title')} size="small" sx={{ marginBottom: '8px' }} />
            <TextField
              minRows={3}
              {...methods.register('texts.title')}
              style={{ width: '100%' }}
            />
          </Box>
          <Box flex={1}>
            <Chip label="buttonText" size="small" sx={{ marginBottom: '8px' }} />
            <TextField
              minRows={3}
              {...methods.register('texts.buttonText')}
              style={{ width: '100%' }}
            />
          </Box>
          <Box flex={1}>
            <Chip label="accessibilityText" size="small" sx={{ marginBottom: '8px' }} />
            <TextareaAutosize
              minRows={3}
              {...methods.register('texts.accessibilityText')}
              style={{ width: '100%' }}
            />
          </Box>
          <Box flex={1}>
            <Chip label="accessibilityAnnouncement" size="small" sx={{ marginBottom: '8px' }} />
            <TextareaAutosize
              minRows={3}
              {...methods.register('texts.accessibilityAnnouncement')}
              style={{ width: '100%' }}
            />
          </Box>
        </Stack>
        <ConfigAndStyleEditor content={content} schema={videoConfigSchema} />
        <ContentBlockEditor content={content} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={() => setShowSnackbar(false)}
      />
    </form>
  </FormProvider>
}
