import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@services/store'

import { RoleBasedFeatureList } from '../components/RoleBasedFeatureList'

export const HomeView: React.FC = () => {
  const { t } = useTranslation()

  const { user } = useAppSelector(({ auth }) => auth)

  return (
    <Box p={6}>
      <Typography variant="h4">{t('home.userWelcome', {
        firstname: user?.firstname,
        lastname: user?.lastname
      })}</Typography>
      <Box sx={{
        mt: 4
      }}
      >
        <RoleBasedFeatureList />
      </Box>
    </Box>
  )
}
