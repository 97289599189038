import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { NavBar } from '@shared/components/ui/NavBar'
import { Outlet, useParams, useLocation, useMatches } from 'react-router'
import { useTourQuery } from '@typings/graphql'
import { LinkTab } from '@features/cms/components/ui/LinkTab'
import { useTranslation } from 'react-i18next'

const TourView: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const location = useLocation()
  const matched = useMatches()
  const currentRoute = matched?.find((m) => m.pathname === location.pathname)
  const tabBarKey = useMemo(() => matched?.reduce((acc, match) => (match.handle as any)?.tabBarKey ?? acc, ''), [currentRoute])

  const { data } = useTourQuery({ variables: { id: id as string }, skip: !id })
  const tour = data?.tour

  return (
    <>
      <NavBar />
      {
        tabBarKey === 'tour' &&
        <Box
          paddingX="16px"
          paddingY="8px"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <LinkTab
            label={t('tours.tour')}
            to={tour?.id ? `/tour/${tour.id}` : '/tour/create'}
          />
          {tour?.id && <LinkTab label={t('common.modes')} to={`/tour/${tour?.id}/modes`} />}
        </Box>
      }
      <Outlet />
    </>
  )
}

export default TourView
