export const lottieConfigSchema = {
  type: 'object',
  properties: {
    resizeMode: { type: 'string', enum: ['cover', 'contain'] },
    trigger: { type: 'string', enum: ['autoplay', 'tap'] },
    triggerPosition: {
      type: 'array',
      items: [
        {
          type: 'number'
        },
        {
          type: 'number'
        }
      ]
    },
    hitSlop: { type: 'number' },
    voiceoverMarker: { type: 'object' }
  },
  required: ['resizeMode', 'trigger', 'triggerPosition', 'hitSlop']
}
