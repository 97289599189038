import { Autocomplete, Box, IconButton, ListItem, SxProps, TextField, Theme, Typography } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSpriteVoiceover } from '@features/cms/provider/SpriteVoiceoverProvider'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite'

type Props = {
  name: string;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>
}

export const VoiceoverMarkerSelection: React.FC<Props> = ({ name, size = 'medium', sx }) => {
  const { sprite, play, stop } = useSpriteVoiceover()

  const { t } = useTranslation()

  const { control } = useFormContext()

  useEffect(() => {
    return () => {
      stop()
    }
  })

  const handlePlayClick = useCallback((event: React.MouseEvent<HTMLButtonElement>, marker: string) => {
    event.preventDefault()
    event.stopPropagation()
    play(marker)
  }, [play])

  if (!sprite) return null

  return <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value } }) => <Autocomplete
      options={Object.keys(sprite || {})}
      value={value ?? ''}
      size={size}
      sx={sx}
      freeSolo
      getOptionLabel={(option) => option}
      onInputChange={(_, newValue) => onChange(newValue)}
      renderInput={(params) => <TextField {...params} label={t('common.voiceoverMarker')} />}
      renderOption={(props, option) => (
        <ListItem {...props} key={option}>
          <Box flex={1}>
            <Typography>{option}</Typography>
          </Box>
          <IconButton onClick={(e) => handlePlayClick(e, option)}>
            <PlayCircleFilledWhiteIcon />
          </IconButton>
        </ListItem>
      )}
    />}
  />
}
