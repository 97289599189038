export const estimationSliderConfigSchema = {
  type: 'object',
  properties: {
    rangeStart: { type: 'number' },
    rangeEnd: { type: 'number' },
    start: { type: 'number' },
    step: { type: 'number', description: 'default: 1' },
    correctAnswer: { type: 'number' }
  },
  required: ['rangeStart', 'rangeEnd', 'start', 'correctAnswer']
}
