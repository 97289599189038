import React from 'react'
import { Provider } from 'react-redux'
import { store, theme } from '@services/index'
import * as Sentry from '@sentry/react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from '@services/apollo/index'
import { routes } from '@services/router'
import '@services/i18n'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter
)

// Disable media session actions
navigator.mediaSession.setActionHandler('play', function () {})
navigator.mediaSession.setActionHandler('pause', function () {})
navigator.mediaSession.setActionHandler('seekbackward', function () {})
navigator.mediaSession.setActionHandler('seekforward', function () {})
navigator.mediaSession.setActionHandler('previoustrack', function () {})
navigator.mediaSession.setActionHandler('nexttrack', function () {})

const CoreProvider: React.FC = () => {
  const router = sentryCreateBrowserRouter(routes)

  return <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      {/*  <PersistGate persistor={persistor}> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
      {/*  </PersistGate> */}
    </ApolloProvider>
  </Provider>
}

export default CoreProvider
