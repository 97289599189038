import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { UpdateContentInput } from '@typings/graphql'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Stack } from '@mui/material'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { compassConfigSchema } from '../config-schemas/default/compass'

import { ContentEditorComponentProps } from '.'

type UpdateCompassContentFormInput = {
  blockedById: string | null,
  config: string,
  style: string
}

export const CompassContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const [loading, setLoading] = useState(false)
  const [updateError, setUpdateError] = useState<any>()
  const [showSnackbar, setShowSnackbar] = useState(false)

  const methods = useForm<UpdateCompassContentFormInput>({
    defaultValues: {
      blockedById: content.blockedById || null,
      config: JSON.stringify(content.config, null, '\t'),
      style: JSON.stringify(content.style, null, '\t')
    }
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const onSubmit = async (submittedData: UpdateCompassContentFormInput) => {
    const data: UpdateContentInput = {
      nuggetId,
      texts: [],
      order: content.order,
      type: content.type,
      blockedById: submittedData.blockedById || null,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style)
    }

    setLoading(true)

    try {
      await updateContent(content.id, data)

      methods.reset()
    } catch (e) {
      setUpdateError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch && refetch()
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setUpdateError(null)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <ConfigAndStyleEditor content={content} schema={compassConfigSchema} />
        <ContentBlockEditor content={content} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={updateError}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
