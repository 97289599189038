import React, { useMemo } from 'react'
import { LinkTab } from '@features/cms/components/ui/LinkTab'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useMatches, useParams } from 'react-router'

const ModeView = () => {
  const { t } = useTranslation()
  const { id, modeId } = useParams()

  const location = useLocation()
  const matched = useMatches()
  const currentRoute = matched?.find((m) => m.pathname === location.pathname)
  const tabBarKey = useMemo(() => matched?.reduce((acc, match) => (match.handle as any)?.tabBarKey ?? acc, ''), [currentRoute])

  return <>
    {tabBarKey === 'mode' && <Box
      paddingX="16px"
      paddingY="8px"
      sx={{ borderBottom: 1, borderColor: 'divider' }}
    >
      <LinkTab label={t('common.mode')} to={modeId ? `/tour/${id}/mode/${modeId}` : `/tour/${id}/mode/create`} />
      {modeId && <LinkTab label={t('common.pois')} to={`/tour/${id}/mode/${modeId}/pois`} />}
    </Box>}

    <Outlet />
  </>
}

export default ModeView
