import { resetAuthData } from '@services/store/slices/auth'

import { store, RootState } from '../services/store/index'

export const can = (permission: string) => {
  const state: RootState = store.getState()
  const [resource, ability] = permission.split(':')

  if (!state.auth.user?.role.permissions) {
    store.dispatch(resetAuthData())
    window.location.href = '/login'
  }

  return state.auth.user?.role.permissions.some((p) => p.resource === resource && p.ability === ability)
}
