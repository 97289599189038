import { CircularProgress, Modal, ModalProps, Paper, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = Omit<ModalProps, 'children'> & {}

export const VoucherDownloadOverlay: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Modal {...props}>
      <Paper sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      >
        <Typography sx={{ mb: 2 }}
          variant="h6"
        >{t('voucher.preparingVouchers')}</Typography>
        <CircularProgress></CircularProgress>
      </Paper>
    </Modal>
  )
}
