import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Box, Chip, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PoiTagDataFragment, UpdateContentInput, useTagsQuery } from '@typings/graphql'
import { LoadingButton } from '@mui/lab'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'

import { ContentEditorComponentProps } from '.'

export const FeaturedPoisContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [uploadError, setUploadError] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [selectedTags, setSelectedTags] = useState<string[]>(content.config.tags ?? [])
  const [isDirty, setIsDirty] = useState(false)

  const { data: tagsData } = useTagsQuery({ variables: { key: 'poi:category' } })
  const allTags = useMemo(() => tagsData?.tags as PoiTagDataFragment[] ?? [], [tagsData])

  useEffect(() => {
    if (selectedTags.length !== content.config.tags?.length) {
      setIsDirty(true)
    } else {
      if (selectedTags.some((tag) => !content.config.tags?.includes(tag))) {
        setIsDirty(true)
      } else {
        setIsDirty(false)
      }
    }
  }, [selectedTags])

  useUnsavedChangesAlert(isDirty)

  useEffect(() => {
    onEdited?.(isDirty)
  }, [isDirty])

  const onSubmit = async () => {
    setLoading(true)

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      style: {},
      config: {
        tags: selectedTags
      },
      texts: []
    }

    try {
      await updateContent(content.id, data)
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    setIsDirty(false)
    refetch?.()
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return (
    <Box
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <InputLabel id="category-label">{t('common.categories')}</InputLabel>
        <Select
          labelId="tag-select"
          multiple
          value={selectedTags}
          onChange={(e) => setSelectedTags(e.target.value as string[])}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          style={{ width: '500px' }}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {allTags.map((tag) => (
            <MenuItem
              key={tag.id}
              value={tag.texts.name}
            >
              {tag.texts.name}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack flex={0}
        alignItems="flex-end"
        p={4}
        pt={2}
      >
        <LoadingButton
          color="primary"
          onClick={onSubmit}
          loading={loading}
          disabled={!isDirty}
        >
          {t('common.save')}
        </LoadingButton>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => closeSnackbar()}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={uploadError ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {uploadError?.message || uploadError || t('edit.poi.updateSuccess')}
        </Alert>
      </Snackbar>
    </Box>
  )
}
