import {
  DataGridPro,
  GridColDef,
  GridLocaleText,
  GridPagination,
  GridPaginationModel,
  GridRowSelectionModel,
  deDE,
  enUS
} from '@mui/x-data-grid-pro'
import {
  BookingOrderField, TourPaginatedBookingsQuery, useTourPaginatedBookingsQuery
} from '@typings/graphql'
import React from 'react'
import i18n from '@services/i18n'
import { useTranslation } from 'react-i18next'
import { VOUCHER_GRID_BASE_ORDER_FILTER, VOUCHER_GRID_PAGE_SIZE } from '@features/voucher/constants/constants'

import { useVoucherContext } from '../../../provider/VoucherProvider'

import { dataGridColumns } from './dataGridColumns'

type Props = {
  onVoucherSelected: (voucher: GridRowSelectionModel) => void
}

export type PaginatedBookingNode = Exclude<TourPaginatedBookingsQuery['tour']['bookingsPaginated']['edges'], null | undefined>[0]['node']
export type PaginatedBookingNodePayment = Exclude<PaginatedBookingNode['payment'], null | undefined>
export type PaginatedBookingRowModel = Omit<PaginatedBookingNode, '__typename' | 'payment'>

export type PaginatedBookingColumnType = Omit<GridColDef<PaginatedBookingRowModel>, 'field'> & {
  field: keyof PaginatedBookingRowModel,
}

export const VoucherDataGrid: React.FC<Props> = ({ onVoucherSelected }) => {
  const { t } = useTranslation()
  const { selectedTour } = useVoucherContext()

  const { data, loading, fetchMore } = useTourPaginatedBookingsQuery({
    variables: {
      id: selectedTour?.id as string,
      first: VOUCHER_GRID_PAGE_SIZE,
      ...VOUCHER_GRID_BASE_ORDER_FILTER
    },
    skip: !selectedTour?.id
  })

  const isFetchingRef = React.useRef<boolean>(false)

  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: VOUCHER_GRID_PAGE_SIZE
  })

  const [rowCountState, setRowCountState] = React.useState(0)
  const [refetching, setRefetching] = React.useState(false)

  const columnData = React.useMemo<PaginatedBookingColumnType[]>(() => {
    return dataGridColumns.map((column) => {
      return {
        sortable: Object.values(BookingOrderField).includes(column.field as any),
        headerName: t(`payment.${column.field}`),
        disableColumnMenu: true,
        ...column
      }
    })
  }, [])

  const gridRowData = React.useMemo<PaginatedBookingRowModel[]>(() => {
    if (!data?.tour.bookingsPaginated.edges) {
      return []
    }

    const mappedRows = data?.tour.bookingsPaginated.edges.map(({ node }) => {
      return {
        code: node.code,
        usages: node.usages,
        usagesLeft: node.usagesLeft,
        createdAt: node.createdAt
      }
    })

    return mappedRows
  }, [paginationModel, data])

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.tour.bookingsPaginated.totalCount !== undefined
        ? data?.tour.bookingsPaginated.totalCount
        : prevRowCountState
    )
  }, [data])

  React.useEffect(() => {
    setRefetching(isFetchingRef.current)
  }, [isFetchingRef.current])

  // TODO Dynamic Translations
  const dataGridTranslations = React.useMemo<Partial<GridLocaleText> | null>(() => {
    return i18n.language.includes('DE')
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : enUS.components.MuiDataGrid.defaultProps.localeText
  }, [])

  const handlePaginationModelChange = async (newPaginationModel: GridPaginationModel) => {
    if (isFetchingRef.current) {
      return
    }

    const directionForward = newPaginationModel.page >= paginationModel.page
    const pageSizeChanged = newPaginationModel.pageSize !== paginationModel.pageSize
    const currentPage = pageSizeChanged
      ? Math.floor(paginationModel.page * paginationModel.pageSize / newPaginationModel.pageSize)
      : newPaginationModel.page

    isFetchingRef.current = true

    setPaginationModel({
      page: currentPage,
      pageSize: newPaginationModel.pageSize
    })

    await fetchMore({
      variables: {
        ...(directionForward
          ? {
              first: newPaginationModel.pageSize,
              skip: pageSizeChanged ? currentPage * newPaginationModel.pageSize : 1,
              after: pageSizeChanged ? undefined : data?.tour.bookingsPaginated.pageInfo.endCursor
            }
          : {
              first: undefined,
              last: newPaginationModel.pageSize,
              skip: 1,
              after: undefined,
              before: data?.tour.bookingsPaginated.pageInfo.startCursor
            }),
        ...VOUCHER_GRID_BASE_ORDER_FILTER
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          ...prev,
          tour: {
            ...prev.tour,
            bookingsPaginated: {
              ...prev.tour.bookingsPaginated,
              edges: [
                ...(fetchMoreResult.tour.bookingsPaginated.edges || [])
              ],
              pageInfo: fetchMoreResult.tour.bookingsPaginated.pageInfo,
              totalCount: fetchMoreResult.tour.bookingsPaginated.totalCount
            }
          }
        }
      }
    })

    isFetchingRef.current = false
  }

  return (
    <DataGridPro
      sx={{
        height: '100%'
      }}
      pagination
      columns={columnData}
      rows={gridRowData}
      rowCount={rowCountState}
      paginationModel={paginationModel}
      paginationMode='server'
      onPaginationModelChange={handlePaginationModelChange}
      loading={loading || refetching}
      pageSizeOptions={[10, 20, 50]}
      checkboxSelection
      disableRowSelectionOnClick
      onRowSelectionModelChange={onVoucherSelected}
      getRowId={({ code }) => code}
      slots={{
        pagination: () =>
          <GridPagination
            backIconButtonProps={{
              disabled: loading || refetching || paginationModel.page === 0
            }}
            nextIconButtonProps={{
              disabled: loading || refetching || !data?.tour.bookingsPaginated.pageInfo.hasNextPage
            }}
          />
      }}
      {...dataGridTranslations && { localeText: dataGridTranslations }}
    />
  )
}
