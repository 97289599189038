import { Box, Card, Divider, Rating, Typography } from '@mui/material'
import React from 'react'

type TourCardData = {
  keyvisual?: string
  rating: number
  title: string
  subtitle: string
}

type Props = {
  tour: TourCardData
  onTourSelect: () => void
}

export const TourCard: React.FC<Props> = ({ tour, onTourSelect }) => {
  return <Card
    sx={{
      position: 'relative',
      height: '430px',
      aspectRatio: '2/3',
      cursor: 'pointer',
      borderRadius: '15px'
    }}
    onClick={onTourSelect}
  >
    {tour.keyvisual &&
    <Box
      sx={{
        ...(tour.keyvisual && { backgroundImage: `url(${tour.keyvisual})` }),
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: '100%'
      }}
    />}
    <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundColor: '#000',
      opacity: 0.3,
      '&:hover': {
        opacity: 0.5
      }
    }}
    />
    <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      pointerEvents: 'none'
    }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Rating name="read-only" value={tour.rating} />
        <Typography variant="body2" sx={{ color: '#fff', ml: 1 }}>{(tour.rating)?.toFixed(1)}</Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold' }}>{tour.title}</Typography>
        <Typography variant="body2" sx={{ color: '#fff' }}>{tour.subtitle}</Typography>
        <Divider sx={{ backgroundColor: '#fff', mt: 2 }} />
      </Box>
    </Box>
  </Card>
}
