import { Card, CardContent, Box, Typography } from '@mui/material'
import { useTourQuery } from '@typings/graphql'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import HouseboatIcon from '@mui/icons-material/Houseboat'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

const TourModes = () => {
  // const { t } = useTranslation()
  const navigate = useNavigate()

  const { id } = useParams()
  const { data } = useTourQuery({ variables: { id: id as string }, skip: !id })
  const tour = data?.tour

  const modes = useMemo(() => {
    return tour?.modes.map((mode) => mode)
  }, [tour])

  const modeCards = useMemo(() => (modes || []).map((mode) => (
    <Card
      key={`mode-${mode.id}`}
      sx={{
        width: '250px',
        aspectRatio: '2/3',
        cursor: 'pointer',
        padding: '12px',
        borderRadius: '15px'
      }}
      onClick={() => navigate(`/tour/${tour?.id}/mode/${mode.id}`)}
    >
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'

      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        >
          <HouseboatIcon />
          <Typography
            textAlign="center"
            variant="h6"
            sx={{ color: '#000' }}
          >
            {mode.title}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )), [modes])

  return <Box
    display="flex"
    flexWrap="wrap"
    width="100%"
    height="100%"
    padding="32px"
    alignItems="flex-start"
    gap={2}
    sx={{ overflowY: 'auto' }}
  >
    {modeCards}
    <Card
      key={'mode-create'}
      sx={{
        width: '250px',
        aspectRatio: '2/3',
        cursor: 'pointer',
        padding: '12px',
        borderRadius: '15px'
      }}
      onClick={() => navigate(`/tour/${tour?.id}/mode/create`)}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <AddCircleOutlineIcon />
        </Box>
      </CardContent>
    </Card>
  </Box>
}

export default TourModes
