import { interactionCardConfigSchemaProperties } from './interactionCard'

export const annotatedVideoConfigSchema = {
  type: 'object',
  properties: {
    stops: { type: 'array', items: { type: 'number' } },
    ...interactionCardConfigSchemaProperties
  },
  required: ['stops']
}
