import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Radio,
  TextField,
  Typography
} from '@mui/material'
import { FileDataFragmentDoc, UpdateContentInput, useUploadFileMutation } from '@typings/graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentFile, isGraphQlFile } from '../mutation-helper/file'

import { ExerciseTextEditor } from './components/ExerciseTextEditor'
import { VoiceoverMarkerSelection } from './components/VoiceoverMarkerSelection'

import type { ContentEditorComponentProps } from '.'

type UpdateChoiceContentFormInput = {
  texts: {
    exercise: string,
    solution?: string
  }
  multiple: boolean
  items: ChoiceItem[],
  config: Record<string, any>
}

type ChoiceItem = {
  key: string
  text: string
  correct: boolean
  file?: ContentFile | File | null
  voiceover?: string
}

export const ChoiceContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile, { loading: isUploading, error }] = useUploadFileMutation()

  const defaultValues = useMemo<UpdateChoiceContentFormInput>(() => {
    const itemTextKeys = Object.keys(content.texts).filter((key) => key.startsWith('item_'))
    itemTextKeys.sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]))

    return {
      texts: content.texts,
      multiple: content.config?.multiple || false,
      items: itemTextKeys.length
        ? itemTextKeys.map((key) => ({
          key,
          text: content.texts[key],
          file: content.files.find(file => file.key === key),
          correct: content.config?.correct?.includes(parseInt(key.split('_')[1])),
          voiceover: content.config?.voiceoverMarker?.[key]
        }))
        : [{ key: 'item_1', text: '', correct: false }],
      config: content.config || {}
    }
  }, [content])

  const methods = useForm<UpdateChoiceContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'items'
  })

  const isMultipleChoice = useWatch({
    control: methods.control,
    name: 'multiple'
  })

  const appendItem = () => {
    const items = methods.getValues('items')
    const indices = items.map((item) => parseInt(item.key.split('_')[1])).sort((a, b) => (a - b))
    const lastIndex = indices.length ? indices.pop() as number : 0

    append({ key: `item_${lastIndex + 1}`, text: '', correct: false })
  }

  const updateSingleChoiceCorrectValue = (index: number) => {
    const items = methods.getValues('items')

    for (let i = 0; i < items.length; i++) {
      methods.setValue(`items.${i}.correct`, i === index, { shouldDirty: true })
    }
  }

  const canSave = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    if (isMultipleChoice) {
      return
    }

    const items = methods.getValues('items')

    for (let i = 0; i < items.length; i++) {
      const isCorrect = methods.getValues(`items.${i}.correct`)
      if (isCorrect) {
        updateSingleChoiceCorrectValue(i)
        break
      }
    }
  }, [isMultipleChoice])

  const onSubmit = async (submittedData: UpdateChoiceContentFormInput) => {
    setLoading(true)

    const translatableTexts = {
      ...submittedData.texts,
      ...submittedData.items.reduce((acc, item) => {
        acc[item.key] = item.text
        return acc
      }, {} as Record<string, string>)
    }

    const texts = await syncContentTexts(content, translatableTexts)

    const files = submittedData.items
      .filter((item) => item.file && !(item.file as any).id)
      .map((item) => ({
        key: item.key,
        file: item.file as File
      }))

    if (files.length) {
      try {
        await Promise.all(files.map(async ({ key, file }) => {
          await uploadFile({
            variables: {
              file,
              data: {
                key,
                model: 'Content',
                modelId: content.id,
                replace: true
              }
            },
            update: (cache, { data }) => {
              cache.modify({
                id: cache.identify({
                  __typename: 'Content',
                  id: content.id
                }),
                fields: {
                  files (existingFiles = []) {
                    const newFileRef = cache.writeFragment({
                      data: data?.uploadFile,
                      fragment: FileDataFragmentDoc,
                      fragmentName: 'FileData'
                    })

                    return [...existingFiles, newFileRef]
                  }
                }
              })
            }
          })
        }))
      } catch (e) {
        setUploadError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      texts,
      order: content.order,
      config: {
        ...submittedData.config,
        voiceoverMarker: {
          ...submittedData.config.voiceoverMarker,
          ...submittedData.items.reduce((acc, item) => {
            acc[item.key] = item.voiceover
            return acc
          }, {} as Record<string, string | undefined>)
        },
        multiple: submittedData.multiple,
        correct: submittedData.items
          .filter((item) => item.correct)
          .map((item) => parseInt(item.key.split('_')[1]))
      }
    }

    try {
      await updateContent(content.id, data)
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  const textFields = useMemo(() => {
    const fieldsSorted = [...fields]
    fieldsSorted.sort((a, b) => parseInt(a.key.split('_')[1]) - parseInt(b.key.split('_')[1]))

    return fieldsSorted.map((field, index) => (
      <Box key={field.id} display="flex" alignItems="center" gap={2}>
        <Typography fontSize="small" sx={{ opacity: 0.7 }} width={12}>{field.key.split('_')[1]}</Typography>

        <Controller name={`items.${index}.correct`}
          control={methods.control}
          render={({ field: { value, onChange } }) => {
            if (isMultipleChoice) {
              return <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
            }

            return <Radio checked={value} onChange={() => updateSingleChoiceCorrectValue(index)} />
          }}
        />
        <TextField sx={{ flex: 2 }} {...methods.register(`items.${index}.text`, { required: true })} />
        <Box flex={1}
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          justifyContent="stretch"
        >
          <Controller name={`items.${index}.file`}
            control={methods.control}
            render={({ field: { value, onChange } }) => {
              return <FileDragDrop
                accept={{ 'image/*': ['.png', '.webp', '.jpg', '.jpeg'] }}
                width="100%"
                maxHeight={100}
                preview
                initialFile={isGraphQlFile(value) ? value : undefined}
                onFileChanged={onChange}
              >
                <Typography>{ !value ? t('edit.poi.dragImage') : (value as any).fileName }</Typography>
              </FileDragDrop>
            }}
          />
        </Box>
        <Box width="250px">
          <VoiceoverMarkerSelection name={`items.${index}.voiceover`} />
        </Box>
        <IconButton
          sx={{ ml: 2, alignSelf: 'center' }}
          onClick={() => remove(index)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
    ))
  }, [fields, isMultipleChoice])

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <ExerciseTextEditor />
          <Box>
            <Chip label={t('common.settings')} size="small" sx={{ marginBottom: '8px' }} />
            <Box>
              <Controller name={'multiple'}
                control={methods.control}
                render={({ field: { value, onChange } }) =>
                  <FormControlLabel
                    control={<Checkbox
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />}
                    label={t('edit.content.mupltiple')}
                  />
            }
              />
            </Box>
          </Box>
          <Box>
            <Chip label={t('edit.content.choices')} size="small" sx={{ marginBottom: '8px' }} />

            <Stack spacing={2}>
              {textFields}

              <Button
                variant="outlined"
                sx={{ alignSelf: 'flex-start' }}
                onClick={appendItem}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Stack>
          </Box>
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading || isUploading}
          uploadError={error || uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
