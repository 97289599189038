export const imageConfigSchema = {
  type: 'object',
  properties: {
    layout: { type: 'string', enum: ['default', 'imageLeft', 'imageRight'] },
    fullsize: { type: 'boolean' },
    fullscreen: { type: 'boolean' },
    aspectRatio: { type: 'number' },
    bgColor: { type: 'string' },
    textColor: { type: 'string' },
    hideHint: { type: 'boolean' }
  }
}
