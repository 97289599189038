export const convertBytesToHumanReadable = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())
  return (Math.round(bytes / Math.pow(1024, i) * 100) / 100) + ' ' + sizes[i]
}

export const parseVoiceoverMarkerTimestamp = (timestamp: string) => {
  const [minutes, seconds] = timestamp.split(':').map(Number)
  return Math.round((minutes * 60 + seconds) * 1000)
}
