import { PaletteOptions, SimplePaletteColorOptions } from '@mui/material'

export type ThemePaletteOptions = PaletteOptions & {
  error: SimplePaletteColorOptions
}

export const palette: ThemePaletteOptions = {
  primary: {
    light: '#10CF72',
    main: '#10CF72',
    dark: '#10CF72',
    contrastText: '#ffffff'
  },
  secondary: {
    light: '#8159F3',
    main: '#8159F3',
    dark: '#8159F3'
  },
  error: {
    light: '#F84242',
    main: '#F84242',
    dark: '#F84242'
  },
  warning: {
    light: '#FF9500',
    main: '#FF9500',
    dark: '#FF9500'
  },
  info: {
    light: '#5856D6',
    main: '#5856D6',
    dark: '#5856D6'
  },
  success: {
    light: '#64D36F',
    main: '#64D36F',
    dark: '#64D36F'
  },
  grey: {
    50: '#f2f2f2',
    100: '#e5e5e5',
    200: '#d9d9d9',
    300: '#cccccc',
    400: '#b3b3b3',
    500: '#999999',
    600: '#808080',
    700: '#666666',
    800: '#4c4c4c',
    900: '#333333',
    A100: '#1a1a1a'
  },
  text: {
    primary: '#000000',
    secondary: '#7A7A7A'
  }
}
