import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RefreshTokenMutation, LoginMutation } from '@typings/graphql'

import type { RootState } from '../index'

type AuthUser = LoginMutation['login']['user']

type AuthState = {
  user?: AuthUser

  accessToken: string
  refreshToken: string
}

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: undefined,

    accessToken: '',
    refreshToken: ''
  } as AuthState,
  reducers: {
    setAuthData: (state, { payload }: PayloadAction<LoginMutation['login']>) => {
      state.accessToken = payload.accessToken
      state.refreshToken = payload.refreshToken

      state.user = { ...payload.user }
    },
    resetAuthData: (state) => {
      state.accessToken = ''
      state.refreshToken = ''

      state.user = undefined
    },
    setAuthToken: (state, { payload }: PayloadAction<RefreshTokenMutation['refreshToken']>) => {
      state.accessToken = payload.accessToken
      state.refreshToken = payload.refreshToken
    }
  }
})

export const {
  setAuthData, resetAuthData, setAuthToken
} = slice.actions

export default slice.reducer

export const isLoggedIn = (state: RootState): boolean => !!state.auth.user
export const getAuthUser = (state: RootState): AuthUser | undefined => state.auth.user
