import { MuiIconNames } from '@features/cms/typings/material-icon-font'

import { interactionCardConfigSchemaProperties } from './interactionCard'

export const swipeCardsTestConfigSchema = {
  type: 'object',
  properties: {
    correct: {
      type: 'array',
      items: {
        type: 'integer'
      },
      minItems: 1
    },
    iconYes: { type: 'string', enum: MuiIconNames },
    iconNo: { type: 'string', enum: MuiIconNames },
    ...interactionCardConfigSchemaProperties
  },
  required: ['correct']
}
