import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BGImage from '@assets/images/dotted-background.png'
import Logo from '@assets/images/logo.png'
import LoginForm from '@features/cms/components/LoginForm'

const LoginView: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box height="100%" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#10CF72' }}>
      <Box
        position="fixed"
        height="100%"
        width="100%"
        sx={{
          backgroundImage: `url(${BGImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'repeat',
          opacity: 0.1
        }}
      />
      <Box p="32px"
        height="70vh"
        width="50vw"
        borderRadius="25px"
        sx={{
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <img src={Logo} width="100px" />
        <Typography my="48px">{t('login.instruction')}</Typography>
        <LoginForm />
      </Box>
    </ Box>
  )
}

export default LoginView
