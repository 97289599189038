import { JsonEditor } from '@features/cms/components/JsonEditor'
import { Box, Chip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  name?: string
  schema?: Record<string, any>
}

export const ConfigEditor: React.FC<Props> = ({ name = 'config', schema }) => {
  const { t } = useTranslation()

  return <Box>
    <Chip
      label={t(`edit.content.${name}`, { defaultValue: t('edit.content.config') })}
      size="small"
      sx={{ marginBottom: '12px' }}
    />

    <JsonEditor
      name={name}
      schema={schema}
      style={{ boxShadow: '0 0 0 1px #CFD7DD, 0 0 0 #CFD7DD, 0 1px 1px #CFD7DD' }}
    />
  </Box>
}
