import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, Snackbar, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { convertToTextObject } from '@utils/texts'
import { useCreateTourPoiMutation } from '@typings/graphql'

type Props = {
  open: boolean,
  lngLat?: {
    lng: number,
    lat: number
  } | null,
  onPoiCreated: () => void,
  onClosed: () => void
}

type CreatePoiFormInput = {
  texts: {
    title: string
  },
  lat: number,
  lng: number
}

export const PoiCreateDialog: React.FC<Props> = ({ open, lngLat, onPoiCreated, onClosed }) => {
  const { t } = useTranslation()
  const { modeId } = useParams()
  const [show, setShow] = useState(false)
  const [uploadError, setUploadError] = useState<any>()
  const [showSnackbar, setShowSnackbar] = useState(false)

  const [createTourPoi, { loading }] = useCreateTourPoiMutation()

  const methods = useForm<CreatePoiFormInput>({
    defaultValues: {
      texts: {
        title: ''
      },
      lat: lngLat?.lat || 0,
      lng: lngLat?.lng || 0
    }
  })

  const createPoi = async (submittedData: CreatePoiFormInput) => {
    try {
      await createTourPoi({
        variables: {
          data: {
            texts: Object.keys(submittedData.texts)
              .map((key) => convertToTextObject(key, submittedData.texts[key as keyof typeof submittedData.texts])),
            lat: submittedData.lat,
            lng: submittedData.lng,
            tourModeId: modeId!
          }
        }
      })
    } catch (e) {
      setUploadError(e)
    }

    setShowSnackbar(true)
    setShow(false)
    onPoiCreated()
  }

  const handleClose = () => {
    setShow(false)
    onClosed()
  }

  useEffect(() => {
    setShow(open)
  }, [open])

  useEffect(() => {
    methods.reset({
      texts: {
        title: ''
      },
      lat: lngLat?.lat || 0,
      lng: lngLat?.lng || 0
    })
  }, [lngLat])

  return (<>
    <FormProvider {...methods} >
      <form>
        <Dialog open={show} onClose={handleClose} sx={{ width: '100%', maxHeight: '90vh' }}>
          <DialogContent>
            <Box sx={{ padding: '20px' }}>
              <TextField
                error={!!methods.formState.errors.texts?.title}
                sx={{ marginBottom: '40px', width: '500px' }}
                label={t('common.title')}
                helperText={!!methods.formState.errors.texts?.title && t('common.required')}
                InputLabelProps={{ shrink: true }}
                {...methods.register('texts.title', { required: true })}
              />
              <Controller
                control={methods.control}
                name="lat"
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) =>
                  <TextField
                    value={value}
                    onChange={(v) => onChange(!v.target.value ? '' : parseFloat(v.target.value))}
                    type="number"
                    error={!!error}
                    sx={{ marginBottom: '40px', width: '500px' }}
                    label={t('edit.poi.latitude')}
                    helperText={!!error && t('common.required')}
                    InputLabelProps={{ shrink: true }}
                  />
              }
              />
              <Controller
                control={methods.control}
                name="lng"
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) =>
                  <TextField
                    value={value}
                    onChange={(v) => onChange(!v.target.value ? '' : parseFloat(v.target.value))}
                    type="number"
                    error={!!error}
                    sx={{ marginBottom: '40px', width: '500px' }}
                    label={t('edit.poi.longitude')}
                    helperText={!!error && t('common.required')}
                    InputLabelProps={{ shrink: true }}
                  />
              }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color='secondary' onClick={handleClose}>
              {t('common.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              disabled={!methods.formState.isDirty}
              loading={loading}
              sx={{
                alignSelf: 'right',
                backgroundColor: '#10CF72'
              }}
              color="secondary"
              onClick={methods.handleSubmit(createPoi)}
            >
              {t('edit.poi.create')}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showSnackbar}
        >
          <Alert
            onClose={() => setShowSnackbar(false)}
            severity={uploadError ? 'error' : 'success'}
            sx={{ width: '100%' }}
          >
            {uploadError?.message || uploadError || t('edit.poi.updateSuccess')}
          </Alert>
        </Snackbar>
      </form>
    </FormProvider>
  </>)
}
