import { JsonEditor } from '@features/cms/components/JsonEditor'
import { Box, Chip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  content: any
}

export const StyleEditor: React.FC<Props> = () => {
  const { t } = useTranslation()

  return <Box>
    <Chip
      label={t('edit.content.style')}
      size="small"
      sx={{ marginBottom: '12px' }}
    />

    <JsonEditor
      name="style"
      style={{ boxShadow: '0 0 0 1px #CFD7DD, 0 0 0 #CFD7DD, 0 1px 1px #CFD7DD' }}
    />
  </Box>
}
