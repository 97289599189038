import { MuiIconNames } from '@features/cms/typings/material-icon-font'

import { interactionCardConfigSchemaProperties } from './interactionCard'

export const swipeCardsSurveyConfigSchema = {
  type: 'object',
  properties: {
    iconYes: { type: 'string', enum: MuiIconNames },
    iconNo: { type: 'string', enum: MuiIconNames },
    ...interactionCardConfigSchemaProperties
  }
}
