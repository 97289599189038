import { Box, LinearProgress, Typography } from '@mui/material'
import { useCustomerGroupsQuery } from '@typings/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { VoucherTourListAccordion } from './VoucherTourListAccordion'
import { VoucherTourListAccordionItem } from './VoucherTourListAccordionItem'

export const VoucherTourList: React.FC = () => {
  const { t } = useTranslation()

  const { data: customerData, loading } = useCustomerGroupsQuery({
    fetchPolicy: 'network-only'
  })

  return (
    <Box sx={{
      height: '100%',
      overflow: 'hidden'
    }}
    >
      {loading || !customerData
        ? loading ? <LinearProgress /> : <Typography>{t('errors.noToursFound')}</Typography>
        : (
          <Box sx={{
            height: '100%',
            overflow: 'auto'
          }}
          >
            {customerData.customerGroups.map(({ id, name, tours }) => (
              <VoucherTourListAccordion
                key={id}
                id={id}
                title={name}
              >
                {tours.length === 0
                  ? <Typography sx={{ px: 2, py: 1 }}>{t('errors.noToursFound')}</Typography>
                  : tours.map((tour, index) => (
                    <VoucherTourListAccordionItem
                      key={tour.id}
                      tour={tour}
                      groupId={id}
                      isLast={tours.length - 1 === index}
                    />
                  ))}
              </VoucherTourListAccordion>
            ))}
          </Box>
          )}
    </Box>
  )
}
