import { Box } from '@mui/material'
import {
  GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector,
  GridToolbarExport, GridToolbarFilterButton
} from '@mui/x-data-grid-pro'
import React from 'react'

export const DataGridToolbar: React.FC = () => {
  return (
    <GridToolbarContainer sx={{
      display: 'flex',
      justifyContent: 'space-between'
    }}
    >
      <Box>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </Box>
      <Box>
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </Box>
    </GridToolbarContainer>
  )
}
