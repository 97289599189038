import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean;
  text: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

export const ConfirmDialog: React.FC<Props> = ({ open, text, onConfirm, onCancel }) => {
  const { t } = useTranslation()

  return <Dialog open={open} sx={{ width: '100%', maxHeight: '90vh' }}>
    <DialogContent>
      <Box sx={{ p: 1, pb: 0 }}>
        <Typography dangerouslySetInnerHTML={{ __html: text }} />
      </Box>
    </DialogContent>
    <DialogActions>
      {onCancel && <Button onClick={onCancel}>{t('common.cancel')}</Button>}
      <Button onClick={onConfirm}>{onCancel ? t('common.delete') : t('common.close')}</Button>
    </DialogActions>
  </Dialog>
}
