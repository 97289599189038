import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import {
  Box,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FileDataFragment, FileDataFragmentDoc, useUploadFileMutation } from '@typings/graphql'
import { Reference } from '@apollo/client'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentFile, isGraphQlFile } from '../mutation-helper/file'

import { ExerciseTextEditor } from './components/ExerciseTextEditor'

import type { ContentEditorComponentProps } from '.'

type UpdateEstimationContentFormInput = {
  image?: ContentFile | File | null
  headerImage?: ContentFile | File | null
  texts: {
    exercise: string
    solution?: string
    unit: string
  }
  config: {
    rows: number
    columns: number
    multiplier: number
    step: number
    solution: number
    allowedOffset: number
  }
}

export const EstimationContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile] = useUploadFileMutation()

  const defaultValues = useMemo<UpdateEstimationContentFormInput>(() => {
    return {
      image: content.files.find(file => file.key === 'image'),
      headerImage: content.files.find(file => file.key === 'headerImage'),
      texts: content.texts,
      config: content.config
    }
  }, [content])

  const methods = useForm<UpdateEstimationContentFormInput>({
    defaultValues,
    mode: 'onChange'
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const canSave = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  const columns = useWatch({
    control: methods.control,
    name: 'config.columns',
    defaultValue: 10
  })

  const rows = useWatch({
    control: methods.control,
    name: 'config.rows',
    defaultValue: 10
  })

  const multiplier = useWatch({
    control: methods.control,
    name: 'config.multiplier',
    defaultValue: 1
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = async (submittedData: UpdateEstimationContentFormInput) => {
    setLoading(true)

    const texts = await syncContentTexts(content, submittedData.texts)

    for (const key of ['image', 'headerImage']) {
      const file = submittedData[key as keyof typeof submittedData] as ContentFile | File | null

      if (!isGraphQlFile(file)) {
        try {
          await uploadFile({
            variables: {
              file,
              data: {
                key,
                modelId: content.id,
                model: 'Content',
                replace: true
              }
            },
            update: (cache, { data }) => {
              cache.modify({
                id: cache.identify(content),
                fields: {
                  files (existingFiles: Reference[] = []) {
                    const newFileRef = cache.writeFragment({
                      data: data?.uploadFile,
                      fragment: FileDataFragmentDoc,
                      fragmentName: 'FileData'
                    })

                    const filteredFiles = existingFiles.filter((fileRef: Reference) => {
                      const _file = cache.readFragment<FileDataFragment>({
                        id: fileRef.__ref,
                        fragment: FileDataFragmentDoc,
                        fragmentName: 'FileData'
                      })

                      return _file?.key !== key
                    })

                    return [...filteredFiles, newFileRef]
                  }
                }
              })
            }
          })
        } catch (e) {
          setUploadError(e)
        }
      }
    }

    try {
      await updateContent(content.id, {
        nuggetId,
        texts,
        order: content.order,
        config: submittedData.config
      })
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <ExerciseTextEditor />

          <Stack direction="row" alignItems="center" gap={2}>
            <TextField {...methods.register('texts.unit')}
              sx={{ width: '100%' }}
              label={t('common.unit')}
            />
            <Controller
              name="headerImage"
              control={methods.control}
              render={({ field: { value, onChange } }) => {
                return <FileDragDrop
                  accept={{ 'image/*': ['.png', '.webp', '.jpg', '.jpeg'] }}
                  alignItems="stretch"
                  alignSelf="unset"
                  width={120}
                  maxHeight={60}
                  preview
                  initialFile={isGraphQlFile(value) ? value : undefined}
                  onFileChanged={onChange}
                >
                  <Typography>{ !value ? t('edit.poi.dragImage') : (value as any).fileName }</Typography>
                </FileDragDrop>
              }}
            />
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <Box>
              <TextField {...methods.register('config.rows', { valueAsNumber: true, min: 1, max: 20, required: true })}
                variant="standard"
                type="number"
                inputProps={{
                  min: 1,
                  max: 20
                }}
                sx={{ width: 60 }}
                label={t('common.rows')}
                defaultValue={10}
              />
            </Box>
            <Typography>x</Typography>
            <Box>
              <TextField {...methods.register('config.columns', { valueAsNumber: true, min: 1, max: 20, required: true })}
                variant="standard"
                type="number"
                inputProps={{
                  min: 1,
                  max: 20
                }}
                sx={{ width: 60 }}
                label={t('common.columns')}
                defaultValue={10}
              />
            </Box>

            <Box>
              <TextField {...methods.register('config.multiplier', { valueAsNumber: true, required: true })}
                variant="standard"
                type="number"
                sx={{ width: 120 }}
                label={t('common.multiplier')}
                defaultValue={1}
              />
            </Box>

            <Box>
              <TextField {...methods.register('config.step', { valueAsNumber: true, required: true })}
                variant="standard"
                type="number"
                sx={{ width: 120 }}
                label={t('common.step')}
                defaultValue={1}
              />
            </Box>

            <Box>
              <TextField {...methods.register('config.allowedOffset', { valueAsNumber: true, required: true })}
                variant="standard"
                type="number"
                sx={{ width: 120 }}
                label={t('edit.content.allowedOffset')}
                defaultValue={0}
              />
            </Box>

            <Controller
              name="image"
              control={methods.control}
              render={({ field: { value, onChange } }) => {
                return <FileDragDrop
                  accept={{ 'image/*': ['.png', '.webp', '.jpg', '.jpeg'] }}
                  alignItems="stretch"
                  alignSelf="unset"
                  width={120}
                  maxHeight={60}
                  preview
                  initialFile={isGraphQlFile(value) ? value : undefined}
                  onFileChanged={onChange}
                >
                  <Typography>{ !value ? t('edit.poi.dragImage') : (value as any).fileName }</Typography>
                </FileDragDrop>
              }}
            />
          </Stack>

          <Box>
            <TextField {...methods.register('config.solution', { valueAsNumber: true, min: 0, max: rows * columns * multiplier, required: true })}
              variant="standard"
              type="number"
              inputProps={{
                min: 0,
                max: rows * columns * multiplier,
                step: multiplier
              }}
              sx={{ width: 60 }}
              label={t('common.solution')}
              defaultValue={0}
            />
          </Box>
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading}
          uploadError={uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
