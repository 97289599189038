import { apolloClient } from '@services/apollo'
import {
  UpdateContentDocument,
  UpdateContentInput,
  UpdateContentMutation,
  UpdateContentMutationVariables
} from '@typings/graphql'
import { useState } from 'react'

export const updateContent = async (id: string, data: UpdateContentInput) => {
  return await apolloClient.mutate<UpdateContentMutation, UpdateContentMutationVariables>({
    mutation: UpdateContentDocument,
    variables: {
      id,
      data
    }
  })
}

export const useUpdateContent = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>()

  const update = async (id: string, data: UpdateContentInput) => {
    setLoading(true)

    try {
      return await updateContent(id, data)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  return { update, loading, error }
}
