import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, TextField, Typography } from '@mui/material'
import {
  FileDataFragment, TourFullQuery,
  TranslatableTextInput, UploadFileMutationVariables, useUpdateTourModeMutation
} from '@typings/graphql'
import EditIcon from '@mui/icons-material/Edit'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ReactAudioPlayer from 'react-audio-player'
import { convertToTextObject } from '@utils/texts'

import { EditableFile } from '../content-type-editors/components/InteractionCardEditor'
import FileDragDrop from '../FileDragDrop'
import { ContentFile, deleteContentFiles, uploadContentFiles, uploadFile } from '../content-type-editors/mutation-helper/file'
import { MediaInfos } from '../content-type-editors/components/MediaInfos'
import { MarkdownEditor } from '../MarkdownEditor'
import { deleteTexts } from '../content-type-editors/mutation-helper/text'

type Props = {
  mode: TourFullQuery['tour']['mode']
}

export type ModeIntroDialogFormInput = {
  introDialogHeaderImage: FileDataFragment | File | null | undefined,
  introDialogSteps: {
    image: EditableFile,
    voiceover: EditableFile,
    headline: {
      key: string,
      value: string
    },
    text: {
      key: string,
      value: string
    }
  }[]
}

type FileIdAndKey = {
  id: string,
  key: string
}

export const EditIntroDialog: React.FC<Props> = ({ mode }) => {
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updateTourMode, { loading: isUpdating }] = useUpdateTourModeMutation()
  const [deletedFileIdsAndKeys, setDeletedFileIdsAndKeys] = useState<FileIdAndKey[]>([])
  const [deletedTextKeys, setDeletedTextKeys] = useState<string[]>([])

  const defaultValues = useMemo(() => ({
    introDialogHeaderImage: mode?.files.find(({ key }) => key === 'introDialogHeaderImage') || undefined,
    introDialogSteps: mode
      ? Object.keys(mode?.texts).filter((key) => key.includes('intro_step') && !key.includes('headline')).map((key) => ({
        image: {
          file: mode?.files.find(({ key: fileKey }) => fileKey === `${key}_image`),
          key: `${key}_image`,
          replaceId: null
        },
        voiceover: {
          file: mode?.files.find(({ key: fileKey }) => fileKey === `${key}_voiceover`),
          key: `${key}_voiceover`,
          replaceId: null
        },
        headline: {
          key: `${key}_headline`,
          value: mode?.texts[`${key}_headline`]
        },
        text: {
          key,
          value: mode?.texts[key]
        }
      }))
      : []
  }), [mode])

  const methods = useForm<ModeIntroDialogFormInput>({
    defaultValues
  })

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: 'introDialogSteps'
  })

  const addStep = (index: number) => {
    append({
      image: {
        key: `intro_step_${index + 1}_image`,
        file: null,
        replaceId: null
      },
      voiceover: {
        key: `intro_step_${index + 1}_voiceover`,
        file: null,
        replaceId: null
      },
      headline: {
        key: `intro_step_${index + 1}_headline`,
        value: ''
      },
      text: {
        key: `intro_step_${index + 1}`,
        value: ''
      }
    })
  }

  /* useEffect(() => {
    if (!fields.length) {
      addStep(0)
    }
  }, []) */

  const replaceHeaderImage = (newImage: File) => {
    methods.setValue('introDialogHeaderImage', newImage)
  }

  const replaceImage = (newImage: File, oldImage: ContentFile, index: number) => {
    methods.setValue(`introDialogSteps.${index}.image.file`, newImage)

    if (oldImage) methods.setValue(`introDialogSteps.${index}.image.replaceId`, oldImage.id)
  }

  const replaceVoiceover = (newVoiceover: File, oldVoiceover: ContentFile, index: number) => {
    methods.setValue(`introDialogSteps.${index}.voiceover.file`, newVoiceover)

    if (oldVoiceover) methods.setValue(`introDialogSteps.${index}.voiceover.replaceId`, oldVoiceover.id)
  }

  const deleteStep = (index: number) => {
    const step = methods.getValues(`introDialogSteps.${index}`)

    if ((step.image.file as any)?.id) {
      setDeletedFileIdsAndKeys((prev) => [...prev, {
        id: (step.image.file as ContentFile)?.id,
        key: step.image.key
      }])
    }

    if ((step.voiceover.file as any)?.id) {
      setDeletedFileIdsAndKeys((prev) => [...prev, {
        id: (step.voiceover.file as ContentFile)?.id,
        key: step.voiceover.key
      }])
    }

    if (step.text.value) {
      setDeletedTextKeys((prev) => [...prev, step.text.key])
    }

    if (step.headline.value) {
      setDeletedTextKeys((prev) => [...prev, step.headline.key])
    }

    remove(index)
  }

  const onSubmit = async (submittedData: ModeIntroDialogFormInput) => {
    setLoading(true)

    if (submittedData.introDialogHeaderImage && !(submittedData.introDialogHeaderImage as any)?.id) {
      try {
        await uploadFile({
          file: submittedData.introDialogHeaderImage,
          data: {
            key: 'introDialogHeaderImage',
            replace: true,
            model: 'TourMode',
            modelId: mode.id
          }
        })
      } catch (e) { }
    }

    const stepFiles = submittedData.introDialogSteps.reduce((acc, curr) => {
      if (curr.image.file) {
        acc.push({
          key: curr.image.key,
          file: curr.image.file,
          replaceId: curr.image.replaceId || null
        })
      }

      if (curr.voiceover.file) {
        acc.push({
          key: curr.voiceover.key,
          file: curr.voiceover.file,
          replaceId: curr.voiceover.replaceId || null
        })
      }

      return acc
    }, [] as Record<string, any>[])

    const newFilesUploadVariables = stepFiles.reduce((acc: UploadFileMutationVariables[], curr, index) => {
      if (!(curr.file as any)?.id && !curr.replaceId) {
        acc.push({
          file: curr.file,
          data: {
            key: curr.key,
            order: index,
            model: 'TourMode',
            modelId: mode.id
          }
        })
      }

      if (!(curr.file as any)?.id && curr.replaceId) {
        acc.push({
          file: curr.file,
          data: {
            key: curr.key,
            order: index,
            model: 'TourMode',
            modelId: mode.id,
            replaceId: curr.replaceId
          }
        })
      }

      return acc
    }, [])

    if (newFilesUploadVariables.length) {
      try {
        await uploadContentFiles(newFilesUploadVariables)
      } catch (e) {
        // setError(e)
      }
    }

    if (deletedFileIdsAndKeys.length) {
      const deletedFileIds = deletedFileIdsAndKeys.map((item) => ({ id: item.id }))

      try {
        await deleteContentFiles(deletedFileIds)
      } catch (e) {
        // setError(e)
      }

      setLoading(false)
    }

    const textUpdateVariables: TranslatableTextInput[] = []

    console.log('SUBMITTED DATA', submittedData)

    submittedData.introDialogSteps.forEach((step) => {
      if (step.text.value && step.text.value !== mode.texts[step.text.key]) {
        textUpdateVariables.push(convertToTextObject(step.text.key, step.text.value))
      }

      if (step.headline.value && step.headline.value !== mode.texts[step.headline.key]) {
        textUpdateVariables.push(convertToTextObject(step.headline.key, step.headline.value))
      }
    })

    if (deletedTextKeys.length) {
      const deletedTextsVariables = deletedTextKeys.map(key => ({
        key,
        model: 'TourMode',
        modelId: mode.id
      }))

      try {
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        // setError(e)
      }
    }

    try {
      await updateTourMode({
        variables: {
          data: {
            texts: textUpdateVariables
          },
          id: mode.id
        }
      })
    } catch (e) {
      // setError(e)
    }

    setLoading(false)
  }

  const introDialogHeaderImage = useMemo(() => {
    const file = mode?.files.find(({ key }) => key === 'introDialogHeaderImage')

    return file?.mimeType.includes('image') ? file : undefined
  }, [mode])

  const stepEditItems = useMemo(() => (
    fields.map((field: any, index) => {
      return (
        <Box
          key={field.id}
          width="100%"
          display="flex"
          alignItems="flex-start"
          p={2}
          pr={1}
          border="1px solid #CFD7DD"
          borderTop={index === 0 ? undefined : 'none'}
        >
          <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
            height="100%"
            minHeight={150}
            width="30%"
            flex="0 0 30%"
            preview
            onFileChanged={(value) => replaceImage(value, field.image.file as ContentFile, index)}
          >
            {(field.image.file as any)?.url && <><Box width="100%"
              height="100%"
              sx={{
                backgroundImage: `url(${(field.image.file as ContentFile).url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center center'
              }}
            />
              <MediaInfos file={field.image.file as ContentFile}></MediaInfos>
            </>}
          </FileDragDrop>
          <Box flex={1} px={2} display="flex" height="100%" flexDirection="column" justifyContent="space-between">
            <TextField sx={{ marginBottom: '12px', widht: '500px' }}
              label="headline"
              {...methods.register(`introDialogSteps.${index}.headline.value`)}
            />
            <MarkdownEditor
              name={`introDialogSteps.${index}.text.value`}
              style={{ marginBottom: '60px' }}
            />

            <FileDragDrop
              accept={{ 'audio/*': ['.mp3', '.mp4', '.acc'] }}
              height="100%"
              preview
              onFileChanged={(value) => replaceVoiceover(value, field.voiceover.file as ContentFile, index)}
            >
              {!field.voiceover.file
                ? <Typography>{t('edit.poi.dragAudio')}</Typography>
                : <>
                  <Typography>{field.voiceover.fileName}</Typography>
                  <MediaInfos file={field.voiceover.file}></MediaInfos>
                </>
      }
            </FileDragDrop>
            {field.voiceover.file &&
            <ReactAudioPlayer
              src={!field.voiceover.file.id ? URL.createObjectURL(field.voiceover.file) : field.voiceover.file.url}
              controls
              style={{ marginBottom: '60px', marginTop: '12px' }}
            />
    }
          </Box>
          <Box padding="10px" height="56px">
            <IconButton onClick={() => deleteStep(index)}>
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </Box>
        </Box>
      )
    })
  ), [fields])

  useEffect(() => {
    methods.reset(defaultValues)
  }, [mode])

  return <>
    <Button variant='outlined'
      color="secondary"
      disabled={!mode.config.showIntroDialog}
      onClick={() => setShowDialog(true)}
      startIcon={
        <EditIcon />
          }
    >{t('edit.mode.introDialog')}</Button>
    <Dialog open={showDialog} PaperProps={{ sx: { maxWidth: '800px', width: '60vw' } }}>
      <FormProvider {...methods} >
        <form
          style={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', flex: 1 }}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <DialogContent sx={{ width: '100%' }}>
            <Box flex={1}>
              <Typography>{t('edit.mode.introDialogHeaderImage')}</Typography>
              <Box p={2}>
                <FileDragDrop
                  accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
                  height="100%"
                  minHeight={150}
                  width="50%"
                  flex="0 0 30%"
                  preview
                  onFileChanged={(value) => replaceHeaderImage(value)}
                >
                  {introDialogHeaderImage?.url && <><Box width="100%"
                    height="100%"
                    sx={{
                      backgroundImage: `url(${introDialogHeaderImage.url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center center'
                    }}
                  />
                    <MediaInfos file={introDialogHeaderImage as ContentFile}></MediaInfos>
                  </>}
                </FileDragDrop>
              </Box>
              <Typography mb={2}>{t('edit.mode.steps')}</Typography>
              {stepEditItems}
              <Box width="100%" mt={2}>
                <Button variant="outlined" sx={{ width: '100%' }} onClick={() => addStep(stepEditItems.length)}>
                  <Typography sx={{ marginRight: 1 }}>{t('edit.content.addFile')}</Typography>
                  <AddCircleOutlineIcon></AddCircleOutlineIcon>
                </Button>
              </Box>
            </Box>
          </DialogContent>

          <DialogActions sx={{ p: 4, pt: 0 }}>
            <Button
              variant="outlined"
              type="button"
              onClick={() => setShowDialog(false)}
            >
              {t('common.close')}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!methods.formState.isDirty || loading || isUpdating}
            >
              {t('common.save')}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  </>
}
