const lngLat = {
  type: 'array',
  items: [
    {
      type: 'number'
    },
    {
      type: 'number'
    }
  ]
}

export const lgsModeConfigSchema = {
  type: 'object',
  properties: {
    tourRoute: {
      type: 'array',
      description: '[[lng, lat], [lng, lat], ...]',
      items: {
        ...lngLat
      }
    },
    mapAreaOverlays: {
      type: 'array',
      items: {
        type: 'object',
        description: 'coordinates: [[lng, lat], [lng, lat], ...]',
        properties: {
          color: {
            type: 'string'
          },
          coordinates: {
            type: 'array',
            items: {
              ...lngLat
            }
          }
        }
      }
    },
    mapMaxZoom: {
      type: 'number'
    },
    mapMinZoom: {
      type: 'number'
    },
    routeColor: {
      type: 'string'
    },
    backgroundType: {
      type: 'string',
      enum: ['default', 'video', 'image']
    },
    mapAnimationEndHeading: {
      type: 'number'
    },
    sectionOrderMarkerType: {
      type: 'string',
      enum: ['default', 'custom']
    },
    mapAnimationStartHeading: {
      type: 'number'
    }
  }
}
