import { getCurrency } from '@utils/currency'
import { t } from 'i18next'

import { PaginatedBookingColumnType } from '../PaymentHistoryDataGrid'

export const amountColumnModel: PaginatedBookingColumnType = {
  field: 'amount',
  width: 75,
  filterable: false,
  valueGetter: (params) => {
    return t('payment.amountCurrency', {
      amount: params.row.amount ? (params.row.amount.toFixed(2)).toString().replace('.', ',') : 0,
      currency: getCurrency(params.row.currency)
    })
  }
}
