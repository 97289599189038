import React from 'react'
import { Button, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router'

type LinkTabProps = {
  label?: string;
  to?: string;
  replace?: boolean;
}

export const LinkTab: React.FC<LinkTabProps> = ({ label, to, replace = true }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Button
      variant="text"
      sx={{
        padding: '12px',
        color: to === location.pathname ? 'secondary.main' : '#000'
      }}
      onClick={() => to && navigate(to, { replace })}
    >
      <Typography>{label}</Typography>
    </Button>
  )
}
