import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { UpdateContentInput } from '@typings/graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Stack } from '@mui/system'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { ConfigEditor } from '../components/ConfigEditor'
import { sequenceConfigSchema } from '../config-schemas/lgs-kids/sequence'

import type { ContentEditorComponentProps } from '.'

type UpdateAnimationBackgroundContentFormInput = {
  text: string,
  blocked: string,
  blockedById: string | null,
  config: string,
}

const DEFAULT_CONFIG = {
  frames: [0, 1],
  backgroundPosition: 0
}

export const SequenceContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const defaultValues = useMemo(() => {
    return {
      blockedById: content.blockedById || null,
      config: JSON.stringify(content.config, null, '\t'),
      texts: content.texts
    }
  }, [content])

  const methods = useForm<UpdateAnimationBackgroundContentFormInput>({
    defaultValues,
    mode: 'onChange'
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  const config = useWatch({
    control: methods.control,
    name: 'config'
  })

  const canSave = useMemo(() => {
    const conditions = [
      methods.formState.isDirty,
      methods.formState.isValid
    ]

    return conditions.every(Boolean)
  }, [content, methods.formState.isDirty, methods.formState.isValid])

  const onSubmit = async (submittedData: UpdateAnimationBackgroundContentFormInput) => {
    setUploadError(null)

    if (methods.formState.isDirty) {
      setLoading(true)

      const data: UpdateContentInput = {
        nuggetId,
        texts: [],
        order: content.order,
        type: content.type,
        config: JSON.parse(submittedData.config)
      }

      try {
        await updateContent(content.id, data)
      } catch (e) {
        setUploadError(e)
      }
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <ConfigEditor schema={sequenceConfigSchema} />
          {(!config || config === '{}') && <Box>
            <Button
              variant="outlined"
              onClick={() => methods.setValue('config', JSON.stringify(DEFAULT_CONFIG, null, '\t'))}
            >{t('common.addDefaultValue')}</Button>
          </Box>}
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading}
          uploadError={uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
