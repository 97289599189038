import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@mui/material'
import { themeIcons } from '@theme/index'
import React, { PropsWithChildren } from 'react'

import { useVoucherContext } from '../../provider/VoucherProvider'

type Props = PropsWithChildren & {
  id: string
  title: string
}

export const VoucherTourListAccordion: React.FC<Props> = ({ id, title, children }) => {
  const { selectedCustomerGroup } = useVoucherContext()
  const { palette } = useTheme()

  return (
    <Accordion
      sx={{
        '&.MuiPaper-root': {
          marginBottom: 2,
          borderRadius: 0,
          '.MuiAccordionSummary-root': {
            borderBottom: '1px solid',
            borderColor: 'grey.200',
            ...(selectedCustomerGroup === id && {
              boxShadow: `inset 4px 0px 0px 0px ${palette.primary.main}}`
            }),
            '.MuiAccordionSummary-content': {
              margin: '16px 0',
              '&.Mui-expanded': {
                margin: '16px 0'
              }
            }
          },
          '.MuiAccordionDetails-root': {
            p: 0
          }
        }
      }}
    >
      <AccordionSummary expandIcon={<themeIcons.ExpandMore />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
