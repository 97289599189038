import { useEffect } from 'react'
// eslint-disable-next-line camelcase
import { unstable_useBlocker } from 'react-router-dom'

export const useUnsavedChangesAlert = (hasUnsavedChanges: boolean) => {
  const blocker = unstable_useBlocker(hasUnsavedChanges)

  useEffect(() => {
    if (!hasUnsavedChanges) {
      blocker.reset?.()
      return
    }

    if (blocker.state === 'blocked') {
      if (window.confirm('Möchtest du wirklich die Seite wechseln? Deine Änderungen gehen dadurch verloren.')) {
        blocker.proceed?.()
      } else {
        blocker.reset?.()
      }
    }
  }, [blocker, hasUnsavedChanges])
}
