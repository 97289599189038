import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import {
  useTourPoiQuery,
  useUpdateContentNuggetMutation,
  useUploadFileMutation,
  File as GraphQLFile,
  useCreateContentNuggetMutation,
  useDeleteContentNuggetMutation
}
  from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { ConfirmDialog } from '@features/cms/components/dialogs/ConfirmDialog'
import { FileList } from '@features/cms/components/FileList'
import { ConfigEditor } from '@features/cms/components/content-type-editors/components/ConfigEditor'
import { MediaInfos } from '@features/cms/components/content-type-editors/components/MediaInfos'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

type UpdateNuggetFormInput = {
  title: string,
  subtitle: string,
  keyvisual: File | GraphQLFile | undefined | null,
  config: string,
}

const PoiEditNugget: React.FC = () => {
  const { id, modeId, poiId, sectionId, nuggetId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const [createNugget, { loading: isCreating, error: errorInCreate }] = useCreateContentNuggetMutation()
  const [updateNugget, { loading: isUpdating, error: errorInUpdate }] = useUpdateContentNuggetMutation()
  const [uploadKeyvisual, { loading: isUploading, error: uploadError }] = useUploadFileMutation()
  const [deleteNugget] = useDeleteContentNuggetMutation()

  const { data } = useTourPoiQuery({ variables: { id: poiId as string } })
  const poi = data?.tourPoi

  const nugget = poi?.sections.find((s) => s.id === sectionId)?.nuggets.find((n) => n.id === nuggetId)

  const methods = useForm<UpdateNuggetFormInput>({
    defaultValues: {
      title: t('edit.poi.newNugget'),
      subtitle: 'Kurze Beschreibung',
      keyvisual: undefined,
      config: '{}'
      // order: 1
    }
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    if (!nugget) return

    methods.reset({
      title: nugget.texts.title,
      subtitle: nugget.texts.subtitle,
      keyvisual: undefined,
      config: JSON.stringify(nugget.config ?? {}, null, '\t')
      // order: 1
    })
  }, [nugget])

  const addImage = (file: File) => {
    methods.setValue('keyvisual', file, { shouldDirty: true })
  }

  const onSubmit = async (submittedData: UpdateNuggetFormInput) => {
    const translatableTexts = ['title', 'subtitle'].map((key) =>
      convertToTextObject(key, submittedData[key as 'title' | 'subtitle']))

    let modelId = nugget?.id

    if (nugget) {
      try {
        await updateNugget({
          variables: {
            id: nugget.id,
            data: {
              texts: translatableTexts,
              sectionId: sectionId!,
              order: nugget.order,
              config: JSON.parse(submittedData.config)
            }
          }
        })
      } catch (e) {
        console.error(e)
      }
    } else {
      try {
        const result = await createNugget({
          variables: {
            data: {
              texts: translatableTexts,
              sectionId: sectionId!
            }
          }
        })

        modelId = result.data?.createContentNugget?.id
        navigate(`/tour/${id}/mode/${modeId}/poi/${poiId}/section/${sectionId}/nugget/${modelId}`)
      } catch (e) {
        console.error(e)
      }
    }

    if (submittedData.keyvisual && modelId) {
      try {
        await uploadKeyvisual({
          variables: {
            file: submittedData.keyvisual,
            data: {
              key: 'keyvisual',
              replace: true,
              model: 'ContentNugget',
              modelId
            }
          }
        })
      } catch (e) {
        console.error(e)
      }
    }

    setShowSnackbar(true)
  }

  const onConfirmDelete = async () => {
    try {
      await deleteNugget({
        variables: {
          id: nugget?.id!
        }
      })
      navigate(`/tour/${id}/mode/${modeId}/poi/${poiId}/section/${sectionId}/nuggets`)
    } catch (e) {
      console.error(e)
    }
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
    methods.reset()
  }

  return <FormProvider {...methods} >
    <form
      style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack
        spacing={2}
        p={4}
        flex={1}
        display="flex"
        sx={{
          overflowY: 'auto'
        }}
      >
        <Box display="flex">
          <Box display="flex" flexDirection="column" flex="0 0 50%" paddingRight="32px">
            <TextField
              sx={{ marginBottom: '32px' }}
              label={t('common.title')}
              id="title"
              {...methods.register('title')}
            />
            <TextField
              sx={{ marginBottom: '32px' }}
              label={t('common.subtitle')}
              {...methods.register('subtitle')}
            />
          </Box>
          <Box flex="0 0 50%" height="100%">
            <FileDragDrop
              accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
              height="100%"
              preview
              onFileChanged={addImage}
            >
              {!nugget?.keyvisual
                ? <Typography>{t('edit.poi.dragImage')}</Typography>
                : <><img src={nugget.keyvisual.url}
                    style={{
                      height: '100%',
                      maxWidth: '100%',
                      objectFit: 'contain'
                    }}
                />
                  <MediaInfos file={nugget?.files[0]}></MediaInfos>
                </>}
            </FileDragDrop>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {nugget && <Box>
            <FileList files={nugget.files} model="ContentNugget" modelId={nugget.id} />
          </Box>}
        </Box>

        <Box>
          <ConfigEditor />
        </Box>
      </Stack>

      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={isUpdating || isCreating || isUploading}
        uploadError={errorInCreate || errorInUpdate || uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      >
        {nugget && <Button
          color="warning"
          variant="outlined"
          endIcon={<DeleteIcon />}
          onClick={() => setShowConfirmDialog(true)}
        >
          {t('edit.poi.deleteNugget')}
        </Button>}
      </FormFooterBar>
      <ConfirmDialog
        open={showConfirmDialog}
        text={t('edit.poi.confirmDeleteNugget')}
        onConfirm={onConfirmDelete}
        onCancel={() => setShowConfirmDialog(false)}
      />
    </form>
  </FormProvider>
}

export default PoiEditNugget
