import { Box, Grid } from '@mui/material'
import { useAppSelector } from '@services/store'
import React from 'react'
import { AppRouteObject, routes } from '@services/router'
import { useTranslation } from 'react-i18next'
import { themeIcons } from '@theme/index'
import { useNavigate } from 'react-router'

import { RoleBasedFeatureCard } from './RoleBasedFeatureCard'

type PermissionFeatureType = {
  name: string
  icon?: keyof typeof themeIcons
  order?: number
  path: string
}

export type FlattenedAppRoutes = AppRouteObject & {
  combinedPath: string;
};

export const RoleBasedFeatureList: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { user } = useAppSelector(({ auth }) => auth)

  const flattenWithPath = React.useCallback((input: AppRouteObject[], path?: string): FlattenedAppRoutes[] => {
    return input.reduce<FlattenedAppRoutes[]>((acc, cur) => {
      const combinedPath = path ? `${path}/${cur.path}` : cur.path
      const flattened = flattenWithPath(cur.children || [], combinedPath)

      return [...acc, { ...cur, combinedPath }, flattened] as FlattenedAppRoutes[]
    }, [])
  }, [])

  const allowedFeatures = React.useMemo<PermissionFeatureType[]>(() => {
    const possibleFeatures = flattenWithPath(routes).filter((route) => route.meta?.dashboard)

    return possibleFeatures.filter((feature) => {
      if (feature.permission) {
        const [resource, ability] = feature.permission.split(':')
        return user?.role.permissions.some((p) => p.resource === resource && p.ability === ability)
      }

      return true
    }).map((feature) => ({
      name: feature.meta?.name || '',
      icon: feature.meta?.icon,
      order: feature.meta?.order,
      path: feature.combinedPath
    })).sort((a, b) => (a.order || 0) - (b.order || 0))
  }, [])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row'
    }}
    >
      <Grid container spacing={2}>
        {allowedFeatures.map((feature) => (
          <Grid item
            key={feature.path}
            xs={3}
          >
            <RoleBasedFeatureCard
              title={t(`features.${feature.name}`)}
              onFeatureClick={() => navigate(feature.path, { replace: true })}
              icon={feature.icon}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
