import { timestampToDate } from '@utils/date'

import { PaginatedBookingColumnType } from '../VoucherDataGrid'

export const createdAtColumnModel: PaginatedBookingColumnType = {
  field: 'createdAt',
  width: 140,
  type: 'date',
  flex: 1,
  filterable: false,
  renderCell: (params) => {
    return params.value ? timestampToDate(params.value) : ''
  }
}
