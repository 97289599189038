const hexToRgb = (hex: string) => {
  const rgbHex = hex.replace('#', '').match(/.{1,2}/g)
  return rgbHex
    ? [
        parseInt(rgbHex[0], 16),
        parseInt(rgbHex[1], 16),
        parseInt(rgbHex[2], 16)
      ]
    : null
}

const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }).join('')
}

export const adjust = (color: string, amount: number) => {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, c =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(c, 16) + amount)).toString(16)
        ).substr(-2)
      )
  )
}

export const lighter = (color: string, factor: number) => {
  const rgb = hexToRgb(color)

  const newRgb = rgb?.map((value) => Math.round((value + ((255 - value) * factor))))

  return newRgb?.length ? rgbToHex(newRgb[0], newRgb[1], newRgb[2]) : null
}

export const darker = (color: string, factor: number) => {
  const rgb = hexToRgb(color)

  const newRgb = rgb?.map((value) => Math.round((value * (1 - factor))))

  return newRgb?.length ? rgbToHex(newRgb[0], newRgb[1], newRgb[2]) : null
}

export const createThemeColors = (primary: string, secondary: string) => {
  return {
    primary: {
      50: lighter(primary, 0.9),
      100: lighter(primary, 0.8),
      200: lighter(primary, 0.6),
      300: lighter(primary, 0.4),
      400: lighter(primary, 0.1),
      500: primary,
      600: darker(primary, 0.1),
      700: darker(primary, 0.4),
      800: darker(primary, 0.6),
      900: darker(primary, 0.8)
    },
    secondary: {
      50: lighter(secondary, 0.9),
      100: lighter(secondary, 0.8),
      200: lighter(secondary, 0.6),
      300: lighter(secondary, 0.4),
      400: lighter(secondary, 0.1),
      500: secondary,
      600: darker(secondary, 0.1),
      700: darker(secondary, 0.4),
      800: darker(secondary, 0.6),
      900: darker(secondary, 0.8)
    }
  }
}
