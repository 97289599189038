import DeleteIcon from '@mui/icons-material/DeleteOutline'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, TextField, Typography } from '@mui/material'
import { FileCopyright } from '@typings/graphql'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (data: FileCopyright | null) => void
  copyright?: FileCopyright | null
}

type FileCopyrightFormData = Omit<FileCopyright, '__typename'>

export const CopyrightEditDialog: React.FC<Props> = ({ copyright, open, onClose, onSubmit: _onSubmit }) => {
  const { t } = useTranslation()

  const { handleSubmit, register, formState, reset } = useForm<FileCopyrightFormData>({
    defaultValues: copyright ?? undefined
  })

  const onSubmit = handleSubmit((data) => {
    _onSubmit(data)
  })

  useEffect(() => {
    if (open) {
      reset({
        author: copyright?.author ?? '',
        year: copyright?.year ?? undefined,
        url: copyright?.url ?? '',
        license: copyright?.license ?? ''
      })
    }
  }, [open, copyright])

  return <Dialog open={open} onClose={onClose}>
    <DialogContent sx={{ width: '400px' }}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Typography variant='h6'>{t('common.copyright')}</Typography>

        <Box>
          <TextField
            sx={{ width: '100%' }}
            required
            label={t('edit.content.copyright.author')}
            {...register('author', { required: true, minLength: 3 })}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box>
          <TextField
            sx={{ width: '100%' }}
            label={t('edit.content.copyright.year')}
            type="number"
            {...register('year', { valueAsNumber: true })}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box>
          <TextField
            sx={{ width: '100%' }}
            label={t('edit.content.copyright.url')}
            {...register('url')}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box>
          <TextField
            sx={{ width: '100%' }}
            label={t('edit.content.copyright.license')}
            {...register('license')}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>
    </DialogContent>
    <DialogActions>
      {!!copyright && <IconButton color='error' onClick={() => _onSubmit(null)}>
        <DeleteIcon />
        </IconButton>}
      <Box sx={{ flexGrow: 1 }} />
      <Button color='secondary' onClick={onClose}>
        {t('common.close')}
      </Button>
      <LoadingButton
        disabled={!formState.isValid}
        color="primary"
        onClick={onSubmit}
      >
        {t('common.save')}
      </LoadingButton>
    </DialogActions>
  </Dialog>
}
