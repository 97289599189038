import { Box, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren & {
  propKey: string;
  hideBorder?: boolean;
};

export const PaymentHistoryDetailsRow: React.FC<Props> = ({ propKey, hideBorder, children }) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
    ...(!hideBorder && { borderBottom: '1px solid black' }),
    py: 1
  }}
  >
    <Typography sx={{
      flex: 0,
      whiteSpace: 'nowrap'
    }}
    >{propKey}</Typography>
    <Box sx={{
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'right',
      ml: 2,
      flex: 1
    }}
    >{children}</Box>
  </Box>
)
